const CryptoJS = require('crypto-js');

function gerarSenhaDiaria() {
    var dataAtual = new Date();
    var ano = dataAtual.getFullYear();
    var mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    var dia = String(dataAtual.getDate()).padStart(2, '0');
    return ano + mes + dia;
}

function gerarHashSHA256(valor) {
    var hash = CryptoJS.SHA1(valor);
    const newHash = hash.toString(CryptoJS.enc.Hex).replace(/\D/g, '');
    return newHash.substring(0, 6);
}

function generate() {
    var senhaDiaria = gerarSenhaDiaria();
    var hash = gerarHashSHA256(senhaDiaria);
    return hash;
}

const DailyPassword = () => generate();

export default DailyPassword;
