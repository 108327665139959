import CodeRoundedIcon from '@mui/icons-material/DataObjectOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import PublicIcon from '@mui/icons-material/Public';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Badge } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Can } from "../components/Can";
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../translate/i18n";

// icones
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BarChartIcon from '@mui/icons-material/BarChart';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import FolderIcon from '@mui/icons-material/Folder';
import ForumIcon from '@mui/icons-material/Forum';
import KeyIcon from '@mui/icons-material/Key';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import StorageIcon from '@mui/icons-material/Storage';
import TuneIcon from '@mui/icons-material/Tune';

import socket from '../hooks/useSocket';
import "./styleMenu.css";
// import { socket } from "../services/socket";


function ListItemLink(props) {
  const { icon, primary, to, className, id } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem id={id} style={{ listStyle: "none", padding: '5px 17px' }}>
      <ListItem color="primary"
        sx={{

          borderRadius: '5px',
          color: (theme) => window.location.pathname === id ?
            theme.palette.primary.main
            : '',

        }}
        button component={renderLink}
        className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </ListItem>
  );
}

const CustomListItem = ({ pathnames, children, theme, ...props }) => {
  const { primary } = theme.palette;
  const isHighlightedPath = pathnames.includes(window.location.pathname);
  return (
    <ListItem
      sx={{
        color: isHighlightedPath ? primary.main : 'inherit',
        listStyle: "none",
        padding: '5px 17px'
      }}
      {...props}
    >
      {children}
    </ListItem>
  );
}

const MainListItems = (isCollapse) => {
  const theme = useTheme();
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [menuKeywords, setMenuKeywords] = useState(false);
  const [open, setOpen] = useState(true);

  const handleClick = (menuToOpen) => {
    setOpen(menuToOpen == open ? null : menuToOpen);
  };

  useEffect(() => {
    if (!user) return
    const { company, companyId } = user;

    if (company?.useKeywords !== undefined) {
      setMenuKeywords(company.useKeywords);
    }

    

    
socket.on(`changeKeywords-${companyId}`, ({ useKeywords }) => {
      setMenuKeywords(useKeywords);
    });

    return () => {
      socket.off(`changeKeywords-${companyId}`)
    };
    
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0 && user.id) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ) && whats.companyId == user.companyId);
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 5000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const element = document.querySelector(".pageMenu")
  if (element) { element.style.display = window.location.pathname.includes('monitor') ? 'none' : 'block' }

  return (
    <List>
      <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
        {"Menu"}
      </ListSubheader>
      <Can
        role={user.profile}
        perform="drawer-admin-items:company"
        yes={() => (
          <>
            <ListItemLink
              to="/companies/system-overview"
              id="/companies/system-overview"
              primary={!isCollapse.collapse && i18n.t("companies.list.overview")}
              icon={<GridViewIcon />}
            />
          </>
        )}
      />
      {user.profile != 'adminSuper' && <>
        <ListItemLink
          to="/"
          id="/"
          primary={!isCollapse.collapse && 'Dashboard'}
          icon={<DashboardCustomizeIcon />}
        />
        <ListItemLink
          to="/tickets"
          id="/tickets"
          primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.tickets")}
          icon={<ForumIcon />}
        />

        <ListItemLink
          to="/contacts"
          id="/contacts"
          primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.contacts")}
          icon={<ContactPhoneIcon />}
        />
        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() => (
            <>
              <ListSubheader style={{ borderRadius: '8px', margin: '8px' }}>
                {isCollapse.collapse ? i18n.t("mainDrawer.listItems.admin") : i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>

              <ListItemButton onClick={() => handleClick('management')}>
                <CustomListItem theme={theme} pathnames={['/users', '/connections']}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary={!isCollapse.collapse && 'Gerenciamento'} />
                </CustomListItem>
                {!isCollapse.collapse && <>{open == 'management' ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</>}
              </ListItemButton>
              <Collapse in={open == 'management' && !isCollapse.collapse} timeout="auto" unmountOnExit>
                <List sx={{ paddingLeft: '20px' }} component="div" disablePadding>
                  <ListItemLink
                    to="/users"
                    id="/users"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.users")}
                    icon={<PeopleAltIcon />}
                  />
                  <ListItemLink
                    to="/connections"
                    id="/connections"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.connections")}
                    icon={
                      <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                        <CellWifiIcon />
                      </Badge>
                    }
                  />
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick('organization')}>
                <CustomListItem theme={theme} pathnames={['/queues', '/folder']}>
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={!isCollapse.collapse && 'Organização'} />
                </CustomListItem>
                {!isCollapse.collapse && <>{open == 'organization' ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</>}
              </ListItemButton>
              <Collapse in={open == 'organization' && !isCollapse.collapse} timeout="auto" unmountOnExit>
                <List sx={{ paddingLeft: '20px' }} component="div" disablePadding>
                  <ListItemLink
                    to="/queues"
                    id="/queues"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.queues")}
                    icon={<AccountTreeIcon />}
                  />
                  <ListItemLink
                    to="/folder"
                    id="/folder"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.folder")}
                    icon={<StorageIcon />}
                  />
                  <ListItemLink
                    to="/tags"
                    id="/tags"
                    primary={!isCollapse.collapse && "Tags"}
                    icon={<LocalOfferIcon />}
                  />
                </List>
              </Collapse>
              <ListItemButton onClick={() => handleClick('automationService')}>
                <CustomListItem theme={theme} pathnames={['/chatbot', '/quick-answers', '/keywords']}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={!isCollapse.collapse && 'Automação'} />
                </CustomListItem>
                {!isCollapse.collapse && <>{open == 'automationService' ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</>}
              </ListItemButton>
              <Collapse in={open == 'automationService' && !isCollapse.collapse} timeout="auto" unmountOnExit>
                <List sx={{ paddingLeft: '20px' }} component="div" disablePadding>
                  <ListItemLink
                    to="/chatbot"
                    id="/chatbot"
                    primary={!isCollapse.collapse && "ChatBot"}
                    icon={<SmartToyIcon />}
                  />
                  <ListItemLink
                    to="/quick-answers"
                    id="/quick-answers"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.quickAnswers")}
                    icon={<QuickreplyIcon />}
                  />
                  {menuKeywords &&
                    <ListItemLink
                      to="/keywords"
                      id="/keywords"
                      primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.keywords")}
                      icon={<KeyIcon />}
                    />
                  }
                </List>
              </Collapse>


              <ListItemButton onClick={() => handleClick('monitoring')}>
                <CustomListItem theme={theme} pathnames={['/monitor', '/report-tickets']}>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={!isCollapse.collapse && 'Monitoramento'} />
                </CustomListItem>
                {!isCollapse.collapse && <>{open == 'monitoring' ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</>}
              </ListItemButton>
              <Collapse in={open == 'monitoring' && !isCollapse.collapse} timeout="auto" unmountOnExit>
                <List sx={{ paddingLeft: '20px' }} component="div" disablePadding>
                  <ListItemLink
                    to="/monitor"
                    id="/monitor"
                    primary={!isCollapse.collapse && 'Monitor'}
                    icon={<QueryStatsIcon />}
                  />
                  <ListItemLink
                    to="/report-tickets"
                    id="/report-tickets"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.report")}
                    icon={<BarChartIcon />}
                  />
                </List>
              </Collapse>

              {process.env.REACT_APP_MODE == "development" && (
                <ListItemLink
                  to="/mass-messaging"
                  id="/mass-messaging"
                  primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.massMessaging")}
                  icon={<PublicIcon />}
                />
              )}

              <ListItemButton onClick={() => handleClick('advanced')}>
                <CustomListItem theme={theme} pathnames={['/settings', '/messages-api']}>
                  <ListItemIcon>
                    <TuneIcon />
                  </ListItemIcon>
                  <ListItemText primary={!isCollapse.collapse && 'Avançado'} />
                </CustomListItem>
                {!isCollapse.collapse && <>{open == 'advanced' ? <ArrowRightIcon /> : <ArrowDropDownIcon />}</>}
              </ListItemButton>
              <Collapse in={open == 'advanced' && !isCollapse.collapse} timeout="auto" unmountOnExit>
                <List sx={{ paddingLeft: '20px' }} component="div" disablePadding>
                  <ListItemLink
                    to="/settings"
                    id="/settings"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.settings")}
                    icon={<SettingsOutlinedIcon />}
                  />
                  <ListItemLink
                    to="/messages-api"
                    id="/messages-api"
                    primary={!isCollapse.collapse && i18n.t("mainDrawer.listItems.messagesAPI")}
                    icon={<CodeRoundedIcon />}
                  />
                </List>
              </Collapse>

            </>
          )}
        />
      </>}
    </List>
  );
};

export default MainListItems;
