import React, { useState, useEffect, useReducer, useContext } from "react";

import { makeStyles } from 'tss-react/mui';
import {
  Card,
  Table,
  Avatar,
  InputAdornment,
  Paper,
  IconButton,
  Button,
  Checkbox,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Chip,
  MenuItem,
  CircularProgress,
  TextField,
  FormControl,
} from '@mui/material';

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import InputTags from "../../components/inputTags";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import QuickAnswersModal from "../../components/QuickAnswersModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useTheme } from '@mui/material/styles';

// routes

// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
import { _contacts } from '../../_mock';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { contactsHead, contactsToolbar, UserMoreMenu } from '../../sections/@dashboard/user/list';

import { styled } from '@mui/material/styles';
import ToastError from "../../toast/error/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_QUICK_ANSWERS") {
    const quickAnswers = action.payload;
    const newQuickAnswers = [];

    quickAnswers.forEach((quickAnswer) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      if (quickAnswerIndex !== -1) {
        state[quickAnswerIndex] = quickAnswer;
      } else {
        newQuickAnswers.push(quickAnswer);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_QUICK_ANSWERS") {
    const quickAnswer = action.payload;
    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    if (quickAnswerIndex !== -1) {
      state[quickAnswerIndex] = quickAnswer;
      return [...state];
    } else {
      return [quickAnswer, ...state];
    }
  }

  if (action.type === "DELETE_QUICK_ANSWERS") {
    const quickAnswerId = action.payload;

    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(4),
      width: '100%'
    },
    paper: {
      margin: 5,
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    settingOption: {
      marginLeft: "auto",
    },
    margin: {
      margin: theme.spacing(1),
    },
    progress: {
      marginLeft: '40px',
      padding: '1px',
      marginTop: '7px'
    },
    box: {
      padding: '0px 15%',
      display: 'flex',
      flexDirection: 'column'
    },
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const Keywords = () => {

  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const { themeStretch } = useSettings();
  const { user } = useContext(AuthContext);

  const [chipData, setChipData] = useState(["obrigado", "obrigada", "valeu", "ate mais"]);
  const [newKeyword, setNewKeyword] = useState("");
  const [chipDataResponse, setChipDataResponse] = useState([]);
  const [newResponse, setNewResponse] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/keywords");

        const { keywords } = data;
        const { recentlyClosedKeywords, recentlyClosedMessage } = keywords;

        setChipData(JSON.parse(recentlyClosedKeywords))
        setChipDataResponse(JSON.parse(recentlyClosedMessage))

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  const companyId = user.companyId;

  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const handleChange = (e) => {
    setNewKeyword(e.target.value);
  };
  const handleKeyDownKeyword = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipData[chipToDelete]));
  };

  const handleButtonClick = () => {

    if (newKeyword.length === 0) return;
    let index = chipData.findIndex(chip => chip === newKeyword.trim().toLowerCase());
    if (index >= 0) {
      ToastError('Esta palavra já foi adicionada');
      setNewKeyword("");
    } else {
      setChipData([...chipData, newKeyword.trim().toLowerCase()]);
      setNewKeyword("");
    }
  }




  const handleChangeResponse = (e) => {
    setNewResponse(e.target.value);
  };
  const handleKeyDownResponse = (event) => {
    if (event.key === 'Enter') {
      handleButtonResponseClick();
    }
  };

  const handleDeleteResponse = (index) => () => {
    setChipDataResponse((chips) => chips.filter((chip) => chip !== chipDataResponse[index]));
  };

  const handleButtonResponseClick = () => {

    if (newResponse.length === 0) return;
    let index = chipDataResponse.findIndex(resp => resp === newResponse.trim());
    if (index >= 0) {
      ToastError('Esta resposta já foi adicionada');
      setNewResponse("");
    } else {
      setChipDataResponse([...chipDataResponse, newResponse.trim()]);
      setNewResponse("");
    }
  }





  const saveAll = () => {
    (async () => {
      const keywordsData = {
        recentlyClosedKeywords: JSON.stringify(chipData),
        recentlyClosedMessage: JSON.stringify(chipDataResponse)
      }
      setLoading(true);
      try {
        const { data } = await api.put(`/keywords/${companyId}`, keywordsData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }


  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <Page title={i18n.t("mainDrawer.listItems.keywords")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs heading={i18n.t("mainDrawer.listItems.keywords")} />
            <div className={classes.box}>
              <Paper className={classes.paper}>
                <div id="keywords" style={{ width: '100%' }}>
                  <div style={{ margin: '25px 5px' }}>
                    <Typography variant="body1">
                      Adicionar nova palavra chave
                    </Typography>
                    <Box sx={{ minWidth: 130 }}>
                      <FormControl fullWidth >
                        {loading ?
                          <div className={classes.progress} >
                            <CircularProgress />
                          </div> : <>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <TextField
                                size="small"
                                fullWidth
                                required
                                onKeyDown={handleKeyDownKeyword}
                                onChange={e => handleChange(e)}
                                value={newKeyword}
                                id="newKeywords"
                              />
                              <div style={{ padding: '2px 20px 2px 20px' }}>
                                <Box sx={{ position: 'relative' }}>
                                  <Button
                                    variant="outlined"
                                    disabled={loading}
                                    onClick={handleButtonClick}
                                  >
                                    Adicionar
                                  </Button>
                                  {loading && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                      }}
                                    />
                                  )}
                                </Box>
                              </div>
                            </div>
                          </>}
                      </FormControl>
                    </Box>
                  </div>
                  <div style={{ margin: '25px 5px' }}>
                    <Typography variant="body1">
                      Palavras chaves salvas
                    </Typography>
                    <Box sx={{ minWidth: 130 }}>
                      <FormControl fullWidth >
                        {loading ?
                          <div className={classes.progress} >
                            <CircularProgress />
                          </div> : <>
                            <Paper
                              id="keywords"
                              sx={{
                                boxShadow: '0 0px 1px 1px #00000021',
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                m: 0,
                              }}
                              component="ul"
                            >
                              {chipData.length === 0 ?
                                <>
                                  <p style={{ padding: '5px' }}>Adicione palavras chaves para respostas</p>
                                </>
                                :
                                <>
                                  {chipData.map((data, i) => {
                                    let icon;
                                    return (
                                      <ListItem key={i}>
                                        <Chip
                                          icon={icon}
                                          label={data}
                                          onDelete={handleDelete(i)}
                                        />
                                      </ListItem>
                                    );
                                  })}
                                </>}

                            </Paper>
                          </>}
                      </FormControl>
                    </Box>
                  </div>
                  <div style={{ margin: '25px 5px' }}>
                    <Typography variant="body1">
                      Adicionar nova resposta
                    </Typography>
                    <Box sx={{ minWidth: 130 }}>
                      <FormControl fullWidth >
                        {loading ?
                          <div className={classes.progress} >
                            <CircularProgress />
                          </div> : <>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <TextField
                                size="small"
                                fullWidth
                                required
                                onKeyDown={handleKeyDownResponse}
                                onChange={e => handleChangeResponse(e)}
                                value={newResponse}
                                id="newResponse"
                              />
                              <div style={{ padding: '2px 20px 2px 20px' }}>
                                <Box sx={{ position: 'relative' }}>
                                  <Button
                                    variant="outlined"
                                    disabled={loading}
                                    onClick={handleButtonResponseClick}
                                  >
                                    Adicionar
                                  </Button>
                                  {loading && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                      }}
                                    />
                                  )}
                                </Box>
                              </div>
                            </div>
                          </>}
                      </FormControl>
                    </Box>
                  </div>
                  <div style={{ margin: '25px 5px' }}>
                    <Typography variant="body1">
                      Respostas salvas
                    </Typography>
                    <Box sx={{ minWidth: 130 }}>
                      <FormControl fullWidth >
                        {loading ?
                          <div className={classes.progress} >
                            <CircularProgress />
                          </div> : <>
                            <Paper
                              id="keywords"
                              sx={{
                                boxShadow: '0 0px 1px 1px #00000021',
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                m: 0,
                              }}
                              component="ul"
                            >
                              {chipDataResponse.length === 0 ?
                                <>
                                  <p style={{ padding: '5px' }}>Adicione respostas</p>
                                </>
                                :
                                <>
                                  {chipDataResponse.map((data, i) => {
                                    let icon;
                                    return (
                                      <ListItem key={i}>
                                        <Chip
                                          icon={icon}
                                          label={<Typography style={{ whiteSpace: 'normal' }}>{data}</Typography>}
                                          style={{ height: "100%" }}
                                          onDelete={handleDeleteResponse(i)}
                                        />

                                      </ListItem>
                                    );
                                  })}
                                </>}

                            </Paper>
                          </>}
                      </FormControl>
                    </Box>
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                    <Button variant="contained" onClick={e => saveAll(e)}>Salvar</Button>
                  </div>
                </div>
              </Paper>
            </div>
          </Container>
        </Page >
      </div >
    </>
  );
};

export default Keywords;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

