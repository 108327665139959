import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import { Typography, Grid, Button, Divider, Avatar, Box } from '@mui/material';
import { AuthContext } from "../../context/Auth/AuthContext";
import ToastError from '../../toast/error/toastError';

const VcardPreview = ({ contact, numbers }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });

    const getCleanedNumber = (num) => num.replace(/\D/g, "");

    const fetchContacts = async () => {
        const numberWithoutPrefixes = getCleanedNumber(numbers).slice(-8);
        try {
            const { data } = await api.get(`/contactsVcard`, {
                params: {
                    searchParam: numberWithoutPrefixes,
                    pageNumber: 1
                }
            });

            if (data?.contacts?.length === 1) {
                setContact(data.contacts[0]);
            } else {
                createContact();
            }
        } catch (err) {
            console.error(err);
            ToastError(err);
        }
    };

    const createContact = async () => {
        let cleanedNumber = getCleanedNumber(numbers);
        let numberWithoutNumber9 = cleanedNumber.slice(0, 4) + cleanedNumber.slice(-8);
        try {
            let contactObj = {
                name: contact,
                number: numberWithoutNumber9,
                email: ""
            }
            const { data } = await api.post("/contacts", contactObj);
            setContact(data);
        } catch (err) {
            console.error(err);
            ToastError(err);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, numbers]);

    const handleNewChat = async () => {
        try {
            const { data: ticket } = await api.post("/tickets", {
                contactId: selectedContact.id,
                userId: user.id,
                status: "open",
            });
            history.push(`/tickets/${ticket.uuid}`);
        } catch (err) {
            ToastError(err);
            console.error(err);
        }
    }

    const styles = {
        container: { minWidth: "250px" },
        typography: { marginTop: "12px", marginLeft: "10px" }
    };

    return (
        <div style={styles.container}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Avatar src={selectedContact.profilePicUrl} />
                </Grid>
                <Grid item xs={9}>
                    <Typography style={styles.typography} variant="subtitle1" gutterBottom>
                        <Box component="p">{selectedContact.name}</Box>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <Button
                        fullWidth
                        color="primary"
                        onClick={handleNewChat}
                        disabled={!selectedContact.number}
                    >
                        Conversar
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default VcardPreview;
