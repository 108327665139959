import React from "react";

import {
	Button,
	TextField,
	Dialog,
	Box,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Avatar,
	IconButton,
	ListItem,
	Tooltip,
	ListItemText,
	ListItemAvatar,
	Divider,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';

import FormatarTelefone from "../../helpers/FormatarTelefone"

import { i18n } from "../../translate/i18n";

const VcardModal = ({ open, onClose, contacts, handleNewChat }) => {

	return (
		<Dialog
			open={open}
			aria-labelledby="contact-card-dialog"
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "450px",
					},
				},
			}}
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle sx={{
				padding: '15px',
				display: 'flex',
				alignItems: 'center'
			}}>
				<IconButton
					edge="end"
					color="inherit"
					onClick={() => onClose(true)}
					aria-label="close"
				>
					<CloseIcon />
				</IconButton>
				<Box sx={{ marginLeft: '10px' }}>
					{
						(!contacts ? 'Nenhum contato' :
							contacts.length > 1 ?
								`${contacts.length} Contatos` :
								'Detalhes do contato')
					}
				</Box>
			</DialogTitle>
			<DialogContent>
				{contacts.map((contact, index) => (
					<>
						<Box key={index}
							sx={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '1rem',
								marginBottom: '1rem'
							}}>
							<Avatar src={contact?.imageUrl}
								sx={{
									width: 49,
									height: 49,
									marginRight: '1rem'
								}} />
							<Box sx={{
								display: 'flex',
								width: '100%',
								justifyContent: 'space-between'
							}}>
								<Box>
									<Box>{contact?.name || contact?.number || "Inválido"}</Box>
									<Box>{contact?.number ? FormatarTelefone(contact.number) : "Sem Telefone"}</Box>
								</Box>
								<Tooltip title="Iniciar Chat">
									<IconButton disabled={!contact?.number} onClick={() => handleNewChat({ name: (contact?.name || contact?.number), number: contact?.number })} color="primary">
										<ChatIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
						<Divider />
					</>
				))}
			</DialogContent>
		</Dialog>
	);
};

export default VcardModal;
