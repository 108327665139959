import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";

import {
    Badge,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Skeleton,
    Tooltip
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import Fade from '@mui/material/Fade';
import { makeStyles } from 'tss-react/mui';
import { i18n } from "../../../translate/i18n";
import AvatarStatus from "./AvatarStatus";

import { format, isSameDay, parseISO } from 'date-fns';
import { AuthContext } from "../../../context/Auth/AuthContext";
import socket from "../../../hooks/useSocket";
import api from "../../../services/api";
import ToastError from "../../../toast/error/toastError";
import ChatBodyComponent from '../ChatBodyComponent';

const useStyles = makeStyles()((theme) => {
    return {
        paper: {
            width: '100% !important',
            maxWidth: '700px !important',
            height: '100% !important',
            maxHeight: '550px !important',
            position: "absolute !important",
            right: 10,
            top: 40,
            overflow: 'hidden !important'
        },
        contactsDisplay: {
            width: '100%',
            height: '490px',
            overflow: 'auto'
        },
        chatDisplay: {
            width: '70%',
            height: '550px',
        },
        chatDisplayBody: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        textInChat: {
            fontSize: '80%',
            maxWidth: '15ch',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    }
});



const ChatModal = ({ open, onClose }) => {

    const { classes } = useStyles();

    const { user } = useContext(AuthContext);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [chatId, setChatId] = useState('')
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usersChat, setUsersChat] = useState([]); // Get all users to chat
    const [filteredUsers, setFilteredUsers] = useState([]); // Filter all users to chat ( filter by name or last message)

    useEffect(() => setFilteredUsers(usersChat), [usersChat])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const { data } = await api.get(`/chat-users`);
                setChatId('')
                setUsersChat(data.users);
                setTimeout(() => {
                    setLoading(false)
                }, 500);
            } catch (error) {
                setLoading(false)
                ToastError(error)
            }
        }
        fetchData();
    }, [open])


    function changePosition(arr, from, to, forMe, msg, id, date) {
        const array = arr.map(obj => {
            if (obj.id === id) {
                var newObj = { ...obj, sendDate: date, lastMessage: forMe ? `${i18n.t("userType.translate.you")}: ${msg}` : msg };
                return newObj;
            }
            return obj;
        });
        array.splice(to, 0, array.splice(from, 1)[0]);
        setLoading(true);
        setUsersChat(array)
        setLoading(false);

    };

    useEffect(() => {


        
        socket.on("internalChatCheck", (data) => {

            const { message, sendDate } = data.chat;

            const idFrom = +data?.chat?.idFrom;
            const idTo = +data?.chat?.idTo;

            if (idFrom === user.id || idTo === user.id) {
                if (idFrom === user.id) {
                    var ticketIndex = filteredUsers.findIndex(i => i.id === idTo);
                    changePosition(filteredUsers, ticketIndex, 0, true, message, idTo, sendDate);
                }
                if (idTo === user.id) {
                    var ticketIndex = filteredUsers.findIndex(i => i.id === idFrom);
                    changePosition(filteredUsers, ticketIndex, 0, false, message, idFrom, sendDate);
                }
            }
        })

        return () => {
            socket.off(internalChatCheck);
        };

    }, [open])

    const renderChatComponent = (id) => {
        setChatId(id)
        setSelectedIndex(id)
    }

    // Filter user by search 
    function findUser(nameUser) {
        function filterByValue(arrayOfObject, term) {
            var userToReturn = arrayOfObject.filter(users => users.name.toLowerCase().indexOf(term) >= 0 || users.lastMessage?.toLowerCase().indexOf(term) >= 0)
            setFilteredUsers(userToReturn);
        }
        if (usersChat.length === 0 || nameUser.length < 3) return setFilteredUsers(usersChat);
        filterByValue(usersChat, String(nameUser).toLowerCase());
    }

    const renderChatContacts = (contactsChat) => {
        var rows = [];
        contactsChat.forEach(contactChat => {
            if (!contactChat) return;
            rows.push(<>
                <Tooltip TransitionComponent={Fade}
                    TransitionProps={{ timeout: 1500 }}
                    title={contactChat.name} placement="left">
                    <ListItemButton selected={selectedIndex === contactChat?.id} alignItems="flex-start" onClick={e => renderChatComponent(contactChat.id)}>
                        <ListItemAvatar>
                            {/*<AvatarStatus user={contactChat} />*/}
                            {contactChat.notification === 0 ?
                                <>
                                    <AvatarStatus user={contactChat} />
                                </>
                                :
                                <>
                                    <Badge color="error" overlap="circular" badgeContent={contactChat.notification}>
                                        <AvatarStatus user={contactChat} />
                                    </Badge>
                                </>}
                        </ListItemAvatar>
                        <ListItemText
                            secondary={
                                <div id="ComponentName" style={{ marginTop: '5px' }}>
                                    <div style={{
                                        display: 'flex',
                                        position: 'absolute',
                                        right: '15px',
                                        top: '15px'
                                    }}>
                                        {contactChat.sendDate ? <small> {isSameDay(parseISO(contactChat.sendDate), new Date()) ? (
                                            <>{format(parseISO(contactChat.sendDate), "HH:mm")}</>
                                        ) : (
                                            <>{format(parseISO(contactChat.sendDate), "dd/MM/yyyy")}</>
                                        )}
                                        </small> : ''}
                                    </div>
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {contactChat.name.split(" ")[0]}
                                        </Typography>
                                    </React.Fragment>
                                    <React.Fragment>
                                        <p className={classes.textInChat} >{contactChat.lastMessage}</p>
                                    </React.Fragment>
                                </div>
                            }
                        />
                    </ListItemButton>
                </Tooltip>
                <Divider variant="inset" style={{ margin: 0, listStyle: 'none' }} component="li" />
            </>)
        });

        return rows;
    }

    return (
        <Dialog
            classes={{ paper: classes.paper }}
            open={open}
            onClose={() => onClose()}
            aria-labelledby="confirm-dialog"
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)',
                    backgroundColor: 'rgba(0, 0, 30, 0.4)',
                },
            }}
        >
            <div style={{ display: 'flex' }}>
                <div style={{ width: '30%' }}>
                    <Paper
                        component="form"
                        style={{ margin: '6px 0px 5px 0px' }}
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            onChange={e => findUser(e.target.value)}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={i18n.t("internalChat.searchUser")}
                            inputProps={{ 'aria-label': 'input message' }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    </Paper>
                    <Divider variant="inset" style={{ margin: 0, listStyle: 'none' }} component="li" />
                    <div className={classes.contactsDisplay}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {loading ?
                                <>
                                    <CardHeader
                                        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                                        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                                    />
                                </> :
                                <>
                                    {renderChatContacts(filteredUsers)}
                                </>
                            }
                        </List>
                    </div>
                </div>
                <div className={classes.chatDisplay}>
                    {!chatId ?
                        <div className={classes.chatDisplayBody}>
                            <p style={{ opacity: '0.7' }}> {i18n.t("internalChat.selectChat")}</p>
                        </div>
                        :
                        <ChatBodyComponent chatId={chatId} />
                    }
                </div>
            </div>
        </Dialog>
    );
};

export default ChatModal;
