import { Avatar } from "@mui/material";
import Button from '@mui/material/Button';
import React, { useEffect, useState } from "react";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import api from "../../services/api";
import './RenderAudioFile.css';

const RenderAudioFile = ({ themeMode, srcAudio, colors, idDiv, avatarUser }) => {

    const [speedAudio, setSpeedAudio] = useState(1)
    const [device, setDevice] = useState("Windows")
    const [audio, setAudio] = useState(srcAudio);

    useEffect(() => {

        const userAgent = navigator.userAgent;

        if (userAgent.match(/Windows/i)) {
            setDevice("Windows");
        } else if (userAgent.match(/Android/i)) {
            setDevice("Android");
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            setDevice("iOS");
        } else {
            setDevice("Windows");
            console.error("Não foi possível detectar o sistema operacional.");
        }

        let value = localStorage.getItem('speed');

        if (!value) {
            localStorage.setItem('speed', 1);
            value = 1;
        }
        setSpeedAudio(value);

    }, [])

    if (device == "iOS") {
        const teste = async () => {
            const { data } = await api.post(`/audioToBase64/`, { params: { audioUrl: srcAudio } });
            const { base64Data } = data;
            setAudio(base64Data)
        };
        teste();
    }
    const changeSpeedAudio = speed => {
        if (+speed === 1) {
            setSpeedAudio(1.5)
            localStorage.setItem('speed', +1.5)
        }
        if (+speed === 1.5) {
            setSpeedAudio(2)
            localStorage.setItem('speed', +2)
        }
        if (+speed === 2) {
            setSpeedAudio(1)
            localStorage.setItem('speed', +1)
        }
        setTimeout(() => changeSpeedInElement(), 50);
    }

    const changeSpeedInElement = e => {
        const audioTag = document.getElementsByTagName('AUDIO');
        for (let i = 0; i < audioTag.length; i++) {
            const element = audioTag[i];
            element.playbackRate = localStorage.getItem('speed')
        }
        const btnValue = document.querySelectorAll('#buttonIdAudio')
        for (let i = 0; i < btnValue.length; i++) {
            const element = btnValue[i];
            element.innerText = `${localStorage.getItem('speed')}X`
        }
    }

    return (<>
        {device == "iOS" ?
            <audio id="audio" controls="controls">
                <source id="wavSource" src={audio} type="audio/mp3"></source>
                Your browser does not support the audio format.
            </audio>
            :
            <>
                <style>
                    {`
                .rhap_container{
                    background-color: #fff0 !important; 
                    box-shadow: 0 0 3px 0 rgb(0 0 0 / 0%) !important; 
                   
                }
                .rhap_time {
                    color: ${themeMode === 'dark' ? '#fff' : '#212b36'}
                }
                .rhap_main-controls-button{
                    color: ${themeMode === 'dark' ? '#fff' : '#212b36'}
                }
                .rhap_progress-indicator{
                    width: 5px;
                    margin-left: -5px;
                    background: ${themeMode === 'dark' ? '#fff' : '#212b36'}
                }
                @media screen and (max-width: 1200px){
                    .render_audio {
                        width: 250px !important;
                    }
                    .rhap_container {
                        padding: 5px !important;
                    }
                }
                
                `}
                </style>
                <div className="render_audio" style={{ width: 300, display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                    <AudioPlayer
                        showSkipControls={false}
                        layout="horizontal-reverse"
                        autoPlay={false}
                        src={audio}
                        onPlay={e => changeSpeedInElement(e)}
                    />
                    <div className="playAudioTag">
                        <Button
                            id="buttonIdAudio"
                            size="small"
                            sx={{
                                padding: '0px 5px !important',
                                minWidth: '45px',
                                borderRadius: '15px'
                            }}
                            onClick={e => changeSpeedAudio(speedAudio)}
                            variant="outlined">{`${speedAudio}X`}</Button>
                    </div>
                    <div style={{ margin: '2.5px' }} className="avatarUser">
                        <Avatar src={avatarUser} />
                    </div>
                </div>
            </>
        }
    </>
    )


}

export default RenderAudioFile;