import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
// utils

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
}));

// ----------------------------------------------------------------------

BookingWidgetSummary.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  time: PropTypes.any,
};

export default function BookingWidgetSummary({ dateOfTickets, title, time, icon }) {
  return (
    <RootStyle>
      <div>
        <Typography variant="h4" sx={{ color: 'text.secondary' }}>
          {time}
        </Typography>
        <Typography variant="h3">{title}</Typography>
      </div>
    </RootStyle>
  );
}
