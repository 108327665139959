import React, { useContext, useEffect, useReducer, useState } from "react";

import {
    Avatar,
    Box,
    Button,
    Card,
    Chip,
    Collapse,
    Container,
    IconButton,
    InputAdornment,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Fade from '@mui/material/Fade';

import * as XLSX from 'xlsx';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import MessagesReportModal from "../../components/MessagesReportModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
// routes

// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
// sections
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import RatingStars from "../../components/RatingStars";
import socket from "../../hooks/useSocket";
import ToastError from "../../toast/error/toastError";


const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];


const reducer = (state, action) => {
  if (action.type === "LOAD_REPORT_TICKETS") {
    const reportTickets = action.payload;
    const newQuickAnswers = [];

    reportTickets.forEach((quickAnswer) => {
      const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);
      if (quickAnswerIndex !== -1) {
        state[quickAnswerIndex] = quickAnswer;
      } else {
        newQuickAnswers.push(quickAnswer);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_REPORT_TICKETS") {
    const quickAnswer = action.payload;
    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswer.id);

    if (quickAnswerIndex !== -1) {
      state[quickAnswerIndex] = quickAnswer;
      return [...state];
    } else {
      return [quickAnswer, ...state];
    }
  }

  if (action.type === "DELETE_REPORT_TICKETS") {
    const quickAnswerId = action.payload;

    const quickAnswerIndex = state.findIndex((q) => q.id === quickAnswerId);
    if (quickAnswerIndex !== -1) {
      state.splice(quickAnswerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    filterButton: {
      transition: "background-color 0.3s",
    },
    iconTransition: {
      transition: "transform 0.3s"
    }
  }
});

const MassMessaging = () => {

  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [reportTickets, dispatch] = useReducer(reducer, []);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [quickCloseReportTicketsModal, setReportTicketsModalOpen] = useState(false);
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersAttr, setUsersAttr] = useState([]);
  const { user } = useContext(AuthContext);
  const companyId = user.companyId;
  const [initialDate, setInitialDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(initialDate);
  const [status, setStatus] = useState('all');
  const [userAttr, setUserAttr] = useState('all');
  const [openMoreFilters, setOpenMoreFilters] = useState(false);
  const [filtroNotas, setFiltroNotas] = useState({ regra: "todos", valor: 0 });
  const notas = [0, 1, 2, 3, 4, 5];

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };



  const handleChangeUserAttr = (event) => {
    setUserAttr(event.target.value);
  };


  // ----------------------------------------------------------------------
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeStatusRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reportTickets.length) : 0;

  const filteredUsers = applySortFilter(reportTickets, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------



  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, status, userAttr, initialDate, endDate]);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const getAllUsers = true
          const { data } = await api.get("/users/", { getAllUsers });
          const { users } = data;
          setUsersAttr(users)
          setLoading(false);
        } catch (err) {
          ToastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);


  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchQuickAnswers = async () => {
        try {
          const { data } = await api.get("/report-tickets/", {
            params: {
              searchParam,
              pageNumber,
              status,
              userId: userAttr,
              initialDate: new Date(initialDate),
              endDate: new Date(endDate)
            },
          });
          var filteredData = [];
          data.forEach(contato => {
            if ((String(contato.contact.number).length <= 13)) {
              filteredData.push(contato)
            }
            else {
              contato.contact.number = 'Grupo'
              filteredData.push(contato)
            }
          });
          dispatch({ type: "LOAD_REPORT_TICKETS", payload: filteredData });
          setLoading(false);
        } catch (err) {
          ToastError(err);
        }
      };
      fetchQuickAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, status, userAttr, initialDate, endDate]);

  useEffect(() => {
    

    
socket.on("reportTickets", (data) => {
      if (data.action === "update" || data.action === "create") {
        return;
        dispatch({ type: "UPDATE_REPORT_TICKETS", payload: data.quickAnswer });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_REPORT_TICKETS",
          payload: +data.quickAnswerId,
        });
      }
    });

    return () => {
      socket.off('reportTickets');
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };


  const handleCloseReportMessageModal = () => {
    setSelectedTicketId(null);
    setReportTicketsModalOpen(false);
  };

  const handleModalMessage = (ticket) => {
    setSelectedTicketId(ticket);
    setReportTicketsModalOpen(true);
  }

  const renderStatus = (status) => {
    if (status === 'open') {
      return (<Chip style={{ backgroundColor: '#3366ff' }} label={i18n.t("reportTickets.status.open")} />)
    }
    if (status === 'closed') {
      return (<Chip style={{ backgroundColor: '#616161' }} label={i18n.t("reportTickets.status.closed")} />)
    }
    if (status === 'pending') {
      return (<Chip style={{ backgroundColor: '#ffbf0aed' }} label={i18n.t("reportTickets.status.pending")} />)
    }
  }

  const handleClickOpenMoreFilters = () => {
    setOpenMoreFilters(!openMoreFilters)
  }


  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFiltroNotas(prevState => ({ ...prevState, [name]: value }));
  };

  const ticketsFiltereds = reportTickets.filter(ticket => {
    switch (filtroNotas.regra) {
      case 'maior':
        return +ticket?.rating >= filtroNotas.valor;
      case 'menor':
        return +ticket?.rating <= filtroNotas.valor;
      case 'igual':
        return +ticket?.rating === filtroNotas.valor;
      default:
        return true;
    }
  });

  const exportReport = () => {

    var arquivoArray = [];

    if (reportTickets.length === 0) return ToastError(`${i18n.t("reportTickets.excel.error")}`);

    ticketsFiltereds.forEach(ticket => {
      arquivoArray.push({
        [`${i18n.t("reportTickets.excel.name")}`]: ticket?.contact?.name,
        [`${i18n.t("reportTickets.excel.number")}`]: ticket?.contact?.number,
        [`${i18n.t("reportTickets.excel.createdAt")}`]: format(parseISO(ticket.createdAt), "dd/MM/yyyy HH:mm"),
        [`${i18n.t("reportTickets.excel.updatedAt")}`]: format(parseISO(ticket.updatedAt), "dd/MM/yyyy HH:mm"),
        [`${i18n.t("reportTickets.excel.status")}`]: i18n.t(`reportTickets.status.${ticket.status}`),
        [`${i18n.t("reportTickets.excel.protocol")}`]: ticket?.uuid,
        [`${i18n.t("reportTickets.excel.assignedUser")}`]: ticket?.user?.name,
        [`${i18n.t("reportTickets.excel.queue")}`]: ticket?.queue?.name,
        [`${i18n.t("reportTickets.excel.rating")}`]: ticket?.rating || `${i18n.t("reportTickets.excel.notRating")}`,
      })
    });

    const ws = XLSX.utils.json_to_sheet(arquivoArray)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Responses')
    XLSX.writeFile(wb, `${i18n.t("reportTickets.excel.archive")}_${format((new Date()), "dd/MM/yyyy")}.xlsx`)

  }



  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <MessagesReportModal
          open={quickCloseReportTicketsModal}
          onClose={handleCloseReportMessageModal}
          ticket={selectedTicketId}
        />
        <Page title={i18n.t("reportTickets.title")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("reportTickets.title")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={e => exportReport()}
                  >
                    {i18n.t("reportTickets.buttons.export")}
                  </Button>
                </>
              }
            />
            <Card>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <TextField
                  style={{ padding: '20px' }}
                  placeholder={i18n.t("reportTickets.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{
                  marginRight: '20px',
                  minWidth: '125px',
                }}>
                  <FormControl fullWidth sx={{ minWidth: '90px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                      <DesktopDatePicker
                        label="Data inicial"
                        inputFormat="DD/MM/YYYY"
                        value={dayjs(initialDate)}
                        maxDate={dayjs(new Date())}
                        onChange={(newValue) => setInitialDate(newValue)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>
                <Box sx={{
                  marginRight: '20px',
                  minWidth: '125px',
                }}>
                  <FormControl fullWidth sx={{ minWidth: '90px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                      <DesktopDatePicker
                        label="Data final"
                        inputFormat="DD/MM/YYYY"
                        value={dayjs(endDate)}
                        minDate={dayjs(initialDate)}
                        maxDate={dayjs(new Date())}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    className={classes.filterButton}
                    onClick={handleClickOpenMoreFilters}
                  >
                    {openMoreFilters ? 'Ver menos filtros' : 'Ver mais filtros'}
                    {openMoreFilters
                      ? <ExpandLess className={classes.iconTransition} />
                      : <ExpandMore className={classes.iconTransition} />}
                  </Button>
                </Box>
              </div>
              <Collapse in={openMoreFilters} timeout="auto" unmountOnExit>
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Box sx={{
                    marginLeft: '10px',
                    padding: '10px',
                    minWidth: '100px',
                  }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label="Status"
                        onChange={handleChangeStatus}
                      >
                        <MenuItem value="all">{i18n.t("reportTickets.status.all")}</MenuItem>
                        <MenuItem value="open">{i18n.t("reportTickets.status.open")}</MenuItem>
                        <MenuItem value="pending">{i18n.t("reportTickets.status.pending")}</MenuItem>
                        <MenuItem value="closed">{i18n.t("reportTickets.status.closed")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    padding: '10px',
                    minWidth: '165px',
                  }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{i18n.t("reportTickets.table.userAtt")}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userAttr}
                        label={i18n.t("reportTickets.table.userAtt")}

                        onChange={handleChangeUserAttr}
                      >
                        <MenuItem value="all">{i18n.t("reportTickets.status.all")}</MenuItem>
                        {usersAttr?.length !== 0 && usersAttr.map((user, i) => <MenuItem key={i} value={user.id}>{user.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    padding: '10px',
                    minWidth: '165px',
                  }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="select-notas-atendimento-range">Filtrar por Nota</InputLabel>
                      <Select
                        labelId="select-notas-atendimento-range"
                        id="select-notas-range"
                        name="regra"
                        value={filtroNotas?.regra}
                        onChange={handleFilterChange}
                        label="Filtrar por Nota"
                      >
                        <MenuItem value="todos">
                          <em>Todos</em>
                        </MenuItem>
                        <MenuItem value="maior">Maior igual á</MenuItem>
                        <MenuItem value="menor">Menor igual á</MenuItem>
                        <MenuItem value="igual">Igual a</MenuItem>
                      </Select>
                    </FormControl>
                    {filtroNotas?.regra != "todos" && (
                      <FormControl sx={{ m: 1, minWidth: 120 }} >
                        <InputLabel id="select-notas-atendimento">Nota</InputLabel>
                        <Select
                          labelId="select-notas-atendimento"
                          id="select-notas"
                          label="Nota"
                          name="valor"
                          value={filtroNotas?.valor}
                          onChange={handleFilterChange}
                        >
                          {notas.map(nota => <MenuItem key={nota} value={nota}>{nota}</MenuItem>)}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </div>
              </Collapse>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.number")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.createdOn")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.updatedOn")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.status")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.userAtt")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("reportTickets.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ticketsFiltereds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, contact, createdAt, updatedAt, user, status, rating, useRating } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="center">
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar src={contact.profilePicUrl} />

                                {status === "closed" && (
                                  <Box>
                                    <p style={{ marginLeft: 10 }}>{contact.name}</p>

                                    {rating ? (
                                      <RatingStars value={rating} isText={true} />
                                    ) : (
                                      <Chip
                                        sx={{
                                          borderRadius: '5px',
                                          height: '20px'
                                        }}
                                        variant="outlined"
                                        label={i18n.t('userType.translate.closed')}
                                        color="primary"
                                      />
                                    )}
                                  </Box>
                                )}
                              </Box>

                            </TableCell>
                            <TableCell align="center">{contact.number}</TableCell>
                            <TableCell align="center">{format(parseISO(createdAt), "dd/MM/yyyy HH:mm")}</TableCell>
                            <TableCell align="center">{format(parseISO(updatedAt), "dd/MM/yyyy HH:mm")}</TableCell>
                            <TableCell align="center">{renderStatus(status)}</TableCell>
                            <TableCell align="center">{user?.name ? user.name : i18n.t("reportTickets.table.notUserAtt")}</TableCell>
                            <TableCell align="center">
                              <Tooltip
                                placement="left"
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={i18n.t("reportTickets.buttons.viewTicketMsg")}>
                                <IconButton

                                  onClick={() => handleModalMessage(row)}
                                >
                                  <SpeakerNotesIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      {loading && <TableRowSkeleton columns={6} />}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={reportTickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeStatusRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div >

    </>
  );
};

export default MassMessaging;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

