import React, { useState, useReducer, useEffect, useRef } from 'react';
import { differenceInHours, parseISO } from 'date-fns';
import {
  Button, IconButton, Paper, Grid, Box, Container, Typography, Card, CardContent,
  Badge, Skeleton, LinearProgress, CircularProgress, Checkbox
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  InsertDriveFile as InsertDriveFileIcon,
  Folder as FolderIcon,
  MusicNote as AudioIcon,
  BugReport as BugReportIcon,
  MoreVert as MoreVertIcon,
  Headset as HeadsetIcon,
  Info as InfoIcon,
  Image as ImageIcon,
  HelpOutline as UnknownIcon,
  Movie as VideoIcon,
  Description as DocumentIcon,
  FolderZip as ZipIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';
import { i18n } from '../../translate/i18n';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import RenderAudioFile from '../../components/MessagesList/RenderAudioFile';
import DriveOptionsMenu from '../../components/DriveOptionsMenu';
import ZipDownloader from '../../helpers/ZipDownloader';
import FileInfoModal from '../../components/FilesInfoModal';
import ToastError from '../../toast/error/toastError';


const STORAGE_CONVERSION = {
  BYTE: 1,
  KB: 1024,
  MB: 1024 ** 2,
  GB: 1024 ** 3,
  TB: 1024 ** 4
};

const GBparaBytes = (gigabytes) => gigabytes * STORAGE_CONVERSION.MB;

function hasSixHoursPassed(updatedAt) {
  const SIX_HOURS = 6;
  const currentTimestamp = new Date();
  const updatedAtTimestamp = parseISO(updatedAt);
  const difference = differenceInHours(currentTimestamp, updatedAtTimestamp);
  return difference >= SIX_HOURS;
}

const ConverterBytes = (bytes) => {
  if (bytes < STORAGE_CONVERSION.KB) return `${bytes}B`;
  if (bytes < STORAGE_CONVERSION.MB) return `${(bytes / STORAGE_CONVERSION.KB).toFixed(2)}KB`;
  if (bytes < STORAGE_CONVERSION.GB) return `${(bytes / STORAGE_CONVERSION.MB).toFixed(2)}MB`;
  if (bytes < STORAGE_CONVERSION.TB) return `${(bytes / STORAGE_CONVERSION.GB).toFixed(2)}GB`;
  return `${(bytes / STORAGE_CONVERSION.TB).toFixed(2)}TB`;
};

const getFileType = (mimeType) => mimeType.split('/')[0] === 'application'
  ? (mimeType.endsWith('/zip') ? 'zip' : 'documento')
  : mimeType.split('/')[0];

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_FOLDERS":
      console.info(action.payload)
      return [...state, ...action.payload.filter(file => !state.some(f => f.link === file.link))];
    case "UPDATE_FOLDERS":
      return state.some(u => u.link == action.payload.id)
        ? state.map(u => u.link === action.payload.link ? action.payload : u)
        : [action.payload, ...state];
    case "DELETE_FOLDER":
      return state.filter(q => q.link !== action.payload);
    case "RESET":
      return [];
    default:
      return state;
  }
};


function FileItemSkeleton() {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Paper
        sx={{
          position: 'relative',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
          borderRadius: 1,
          boxShadow: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconButton color="primary">
            <Skeleton variant="circle" width={40} height={40} />
          </IconButton>
          <Skeleton variant="text" width={100} />
          <IconButton size="small">
            <MoreVertIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Skeleton variant="rectangular" width="80%" height="80%" />
        </Box>
      </Paper>
    </Grid>
  );
}


const StorageInfoCard = ({ isLoading, status, isOldUpdate, fileTypes, syncDataLocal, total, used }) => {

  const isSync = status == 'syncing';
  const isNotSynced = status == 'notSync';

  const totalStorage = GBparaBytes(total);
  const usedStorage = used * 1024 * 1024;

  if (isLoading) {
    return (
      <Card sx={{ mb: 3, boxShadow: 3 }}>
        <CardContent>
          <Skeleton variant="text" width="120px" height="24px" />
          <Skeleton variant="text" width="240px" height="20px" style={{ marginTop: 6 }} />
          <Skeleton variant="rectangular" height="20px" style={{ marginTop: 16, marginBottom: 16 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {[...Array(fileTypes.length)].map((_, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Skeleton variant="circle" width={24} height={24} />
                <Skeleton variant="text" width="80px" height="16px" />
              </Box>
            ))}
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 3, boxShadow: 4, padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', marginBottom: 2 }}>
        <Typography variant="h6">
          Armazenamento
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 2, backgroundColor: (theme) => theme.palette.mode == 'dark?' ? isOldUpdate || isNotSynced ? '#191207' : "#0c130d" : isOldUpdate || isNotSynced ? '#fff4e5' : "#edf7ed", padding: 1, borderRadius: '8px' }}>
          {(isNotSynced || isOldUpdate || isSync) ? <WarningIcon color="error" /> : <CheckCircleIcon color="success" />}
          <Typography variant="body2" sx={{ color: (theme) => theme.palette.mode == 'dark?' ? isOldUpdate || isNotSynced ? '#e8cea6' : '#b9d3ba' : isOldUpdate || isNotSynced ? '#663c00' : '#466847' }}>
            {isSync ? "Esta ação não pode ser desfeita" : isNotSynced ? `Dados não sincronizados, Ultima atualização: 10/09/2023 às 10:50` : isOldUpdate ? 'Dados locais desatualizados' : "Seus dados estão atualizados."}
          </Typography>
          {(isNotSynced || isSync || isOldUpdate) && <>
            <LoadingButton
              onClick={() => syncDataLocal()}
              variant="contained"
              color="error"
              size="small"
              sx={{
                boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                textTransform: 'none'
              }}
              loading={isSync}
              loadingPosition="start"
              startIcon={<CloudSyncIcon />}
            >
              {isSync ? 'Atualizando dados da plataforma' : 'Sincronizar com a nuvem'}
            </LoadingButton>
          </>}
        </Box>
      </Box>

      <Typography variant="body2" gutterBottom>
        Utilizado: {ConverterBytes(usedStorage)} / {ConverterBytes(totalStorage)}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(usedStorage / totalStorage) * 100}
        sx={{ height: 20, borderRadius: 5, mt: 1, mb: 3 }}
      />
      {/*
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {fileTypes.map((fileType) => (
          <Box key={fileType.type} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Badge
              max={9999}
              badgeContent={fileType.count}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              color="primary"
              size="large"
            >
              {fileType.icon}
            </Badge>
            <Typography variant="caption">{fileType.type}</Typography>
          </Box>
        ))}
      </Box>
      */}
    </Card >
  );
};

function renderPreview(type, src) {
  const commonStyles = {
    width: '50%',
    height: '110px',
    padding: '10px',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  switch (type) {
    case 'audio':
      return <HeadsetIcon sx={{ backgroundColor: '#d93025' }} style={commonStyles} />;
    case 'video':
      return <VideoCameraBackIcon sx={{ backgroundColor: '#d93025' }} style={commonStyles} />;
    case 'image':
      return <img src={src} alt="File preview" style={{
        width: '100%',
        height: '200px',
        objectFit: 'cover'
      }} />;
    case 'application':
      return <DocumentIcon sx={{ backgroundColor: '#4b87e4' }} style={commonStyles} />;
    case 'zip':
      return <ZipIcon sx={{ backgroundColor: '#737373' }} style={commonStyles} />;
    case 'documento':
      return <DocumentIcon sx={{ backgroundColor: '#4b87e4' }} style={commonStyles} />;
    case 'outros':
      return <UnknownIcon style={commonStyles} />;
    default:
      return <UnknownIcon style={commonStyles} />;
  }
}

const renderIconToCard = (type) => {
  switch (type) {
    case 'audio':
      return <HeadsetIcon sx={{ color: '#d93025' }} />;
    case 'video':
      return <VideoCameraBackIcon sx={{ color: '#d93025' }} />;
    case 'image':
      return <ImageIcon sx={{ color: '#d93025' }} />;
    case 'application':
      return <DocumentIcon sx={{ color: '#4b87e4' }} />;
    case 'zip':
      return <ZipIcon sx={{ color: '#737373' }} />;
    case 'documento':
      return <DocumentIcon sx={{ color: '#4b87e4' }} />;
    default:
      return <UnknownIcon sx={{ color: '#d93025' }} />;
  }
}

function FileItem({ type, name, src, data, onDelete, onInfo, onSelect, selection, selecteds }) {

  const isSelected = selecteds?.indexOf(data.id) !== -1;

  const fileType = getFileType(type);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Paper
        sx={{
          position: 'relative',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: '100%',
          borderRadius: 1,
          boxShadow: 2,
          transition: 'transform .2s',
          '&:hover': {
            transform: 'scale(1.05)', // Efeito de zoom leve ao passar o mouse
            boxShadow: 4
          }
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {selection && <Checkbox
            size="small"
            checked={isSelected}
            onChange={() => onSelect(data.id)}
          />}
          <IconButton color="primary">
            {renderIconToCard(fileType)}
          </IconButton>
          <Typography variant="body2" align="center" noWrap>
            {name}
          </Typography>
          <DriveOptionsMenu
            onDownload={link => window.open(link, '_blank')}
            onDelete={id => onDelete(id)}
            onInfo={link => onInfo(link)}
            onSelect={id => onSelect(id)}
            data={data}
          />
        </Box>
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}>
          <Box
            sx={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}>
            {renderPreview(fileType, src)}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}

const fileSizesReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_COUNT":
      return state.map(item =>
        item.type === action.fileType
          ? { ...item, count: action.count }
          : item
      );
    default:
      return state;
  }
};

const initialValuesFilesSizes = [
  { type: 'audio', count: 0, icon: <HeadsetIcon sx={{ width: '40px', height: '40px' }} color="primary" /> },
  { type: 'image', count: 0, icon: <ImageIcon sx={{ width: '40px', height: '40px' }} color="error" /> },
  { type: 'video', count: 0, icon: <VideoIcon sx={{ width: '40px', height: '40px' }} color="error" /> },
  { type: 'doc', count: 0, icon: <DocumentIcon sx={{ width: '40px', height: '40px' }} color="info" /> },
  { type: 'zip', count: 0, icon: <ZipIcon sx={{ width: '40px', height: '40px' }} color="warning" /> },
  { type: 'others', count: 0, icon: <BugReportIcon sx={{ width: '40px', height: '40px' }} color="action" /> },
]

const GoogleDriveClone = () => {
  const { themeStretch } = useSettings();
  const gridRef = useRef(null);
  const [loadingStorage, setLoadingStorage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [usedSize, setUsedSize] = useState(0);
  const [maxSize, setMaxSize] = useState(0);
  const [selecteds, setSelecteds] = useState([]);
  const [status, setStatus] = useState('syncing');
  const [isOldUpdate, setIsOldUpdate] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filesSizes, dispatchFilesSizes] = useReducer(fileSizesReducer, initialValuesFilesSizes);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openFileInfoModal, setOpenFileInfoModal] = useState(false);
  const [files, dispatch] = useReducer(reducer, []);

  const updateCountsFromData = (data) => {
    const mappings = {
      audio: data.totalAudio,
      image: data.totalImage,
      video: data.totalVideo,
      doc: data.totalDoc,
      zip: data.totalZip,
      others: data.totalOthers
    };
    for (const fileType in mappings) {
      dispatchFilesSizes({ type: "UPDATE_COUNT", fileType, count: mappings[fileType] });
    }
  };

  useEffect(() => {
    (async () => {
      setLoadingStorage(true);
      try {
        const { data } = await api.get("/google-drive", { params: { typeList: 'storage' } });
        setIsOldUpdate(hasSixHoursPassed(data.updatedAt))
        setStatus(data.status)
        updateCountsFromData(data)
        setUsedSize(data.currentStorage)
        setMaxSize(data.maxStorage)
      } catch (err) {
        ToastError(err);
      }
      finally {
        setLoadingStorage(false);
      }
    })();
  }, []);

  const downloadSelecteds = async () => {
    setLoadingButton(true)
    try {
      const selectedLinks = files
        .filter(file => selecteds.includes(file.id))
        .map(file => file.webContentLink);

      console.info(selectedLinks);

      await ZipDownloader(selectedLinks)

    } catch (err) {
      console.error(err)
    } finally {
      setLoadingButton(false)
    }
  }

  const deleteSelectedsFiles = async (files) => {
    setLoadingButton(true)
    try {
      await api.put("/google-drive-delete", { files: JSON.stringify(files) });
      files.forEach(file => { dispatch({ type: "DELETE_FOLDER", payload: file }) });
      setSelecteds([])
    } catch (err) {
      ToastError(err);
    } finally {
      setLoadingButton(false)
    }
  }

  const selectIdFile = (id) => {
    setSelecteds(old => {
      if (old.includes(id)) {
        return old.filter(existingId => existingId !== id);
      } else {
        return [...old, id];
      }
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      return;
      const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
      const isNearBottom = scrollHeight - scrollTop <= clientHeight + 100;

      if (isNearBottom) {
        setPageNumber((prevPageNumber) => {
          const nextPage = prevPageNumber + 1;
          return nextPage !== pageNumber ? nextPage : prevPageNumber;
        });
      }
    };

    if (gridRef.current) {
      gridRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let requestor = { typeList: 'all', limit: 50, pageNumber }
        const { data } = await api.get("/google-drive", { params: requestor });
        console.info({ data })
        dispatch({ type: "LOAD_FOLDERS", payload: data });
      } catch (err) {
        ToastError(err);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [pageNumber]);


  useEffect(() => {
    const fetchData = async () => {
      if (status === 'synced') return;
      try {
        const { data } = await api.get("/google-drive", { params: { typeList: 'storage' } });
        const { updatedAt, status, currentStorage, maxStorage } = data;
        setIsOldUpdate(hasSixHoursPassed(updatedAt));
        setStatus(status);
        updateCountsFromData(data);
        setUsedSize(currentStorage);
        setMaxSize(maxStorage);
      } catch (err) {
        console.error(err);
      }
    };
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, [status]);


  const syncDataLocal = async () => {
    try {
      const { data } = await api.get("/google-drive", { params: { typeList: 'sync' } });
      const { updatedAt, status, currentStorage, maxStorage } = data;
      setIsOldUpdate(hasSixHoursPassed(updatedAt));
      setStatus(status);
      updateCountsFromData(data);
      setUsedSize(currentStorage);
      setMaxSize(maxStorage);
    } catch (err) {
      ToastError(err);
    } finally {
      console.info('atualizado');
    }
  }


  const handleOpenFileInfoModal = (data) => {
    setSelectedFile(data)
    setOpenFileInfoModal(true)
  }

  const handleCloseFileInfoModal = () => {
    setSelectedFile(null)
    setOpenFileInfoModal(false)
  }

  return (
    <div style={{ marginTop: '65px' }}>
      <FileInfoModal
        open={openFileInfoModal}
        data={selectedFile}
        onClose={handleCloseFileInfoModal}
      />
      <Page title={i18n.t("files.title")}>
        <Container maxWidth={false}>
          <StorageInfoCard
            syncDataLocal={syncDataLocal}
            total={maxSize}
            used={usedSize}
            isLoading={loadingStorage}
            fileTypes={filesSizes}
            status={status}
            isOldUpdate={isOldUpdate}
          />
          <Box>
            {selecteds.length > 0 && <Card sx={{ mb: 3, boxShadow: 3 }}>
              <CardContent sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  onClick={() => { deleteSelectedsFiles(selecteds) }}
                  variant="outlined"
                  color="error"
                  size="small"
                  loading={loadingButton}
                  loadingPosition="start"
                  startIcon={<DeleteIcon />}
                >
                  {`Excluir selecionados (${selecteds.length})`}
                </LoadingButton>
              </CardContent>
            </Card>}

            <Grid container spacing={3} ref={gridRef}
              style={{ padding: 5, overflowY: 'auto', maxHeight: 'calc(100vh - 385px)' }}>
              {files && files.map(file => (
                <FileItem
                  type={file.mimeType}
                  name={file.fileName}
                  src={file.link}
                  selection={selecteds.length > 0}
                  selecteds={selecteds}
                  onDelete={id => deleteSelectedsFiles([id])}
                  onInfo={data => handleOpenFileInfoModal(data)}
                  onSelect={id => selectIdFile(id)}
                  data={file} />
              ))}
              {loading && Array.from({ length: 10 }).map((_, idx) => <FileItemSkeleton key={idx} />)}
            </Grid>
          </Box>
        </Container>
      </Page>
    </div>
  );
};

export default GoogleDriveClone;
