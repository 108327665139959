import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	Divider,
	Avatar,
	Chip,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	Tooltip,
	Alert,
	CircularProgress, // Importar o CircularProgress
} from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';
import PixIcon from '@mui/icons-material/Pix';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import ConfirmationModal from "../ConfirmationModal";
import { format, parse, isBefore } from "date-fns";

const isVencido = (dataVencimento) => {
	const dataAtual = new Date();
	const dataFormatada = parse(dataVencimento, "dd/MM/yyyy HH:mm:ss", new Date());
	return isBefore(dataFormatada, dataAtual);
};

const UniGestorModal = ({ open, onClose, onConfirm, ticket, contact, gestor }) => {
	const [loading, setLoading] = useState(false);

	const handleSelectedBoleto = (type, boleto) => {
		if (!boleto) return ToastError("Impossível gerar dados, verifique no Gestor.");

		if (type === "boleto") {
			handleSendMessage(true, boleto.CelcoinLinkPDF);
		}
		if (type === "pix") {
			handleSendMessage(false, boleto.CelcoinPixCopiaCola);
		}
		if (type === "senha") {
			handleSendMessage(false, boleto.SenhaMensal);
		}
	};

	const handleSendMessage = async (isMedia, message) => {
		try {
			setLoading(true);
			await api.post(`/messages/gestor/${ticket.id}`, {
				whatsappId: ticket.whatsappId,
				number: ticket.contact.number,
				ticketId: ticket.id,
				mediaUrl: isMedia ? message : null,
				body: isMedia ? null : message,
			});
			onClose(false);
		} catch (err) {
			ToastError("Erro ao tentar enviar mensagem, verifique os dados no Gestor.");
		} finally {
			setLoading(false);
		}
	};

	// Ordenar boletos pela DataVencimento do mais velho para o mais novo
	const sortedBoletos = gestor?.Boletos?.slice().sort((a, b) => {
		const dateA = parse(a.DataVencimento, "dd/MM/yyyy HH:mm:ss", new Date());
		const dateB = parse(b.DataVencimento, "dd/MM/yyyy HH:mm:ss", new Date());
		return dateA - dateB;
	});

	return (
		<>
			<Dialog
				open={open}
				fullWidth
				maxWidth="sm"
				onClose={() => onClose(false)}
				aria-labelledby="gestor-dialog-title"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(5px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="gestor-dialog-title" sx={{ textAlign: 'center', padding: '15px' }}>
					<Typography variant="h6" sx={{ fontWeight: 'bold', color: '#03ab57' }}>
						Gestor Unitech
					</Typography>
				</DialogTitle>

				<DialogContent dividers sx={{ padding: '20px' }}>
					<Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
						<Avatar
							alt={contact?.name}
							src={contact?.profilePicUrl}
							sx={{ width: 80, height: 80, margin: '0 auto', border: '2px solid #03ab57' }}
						/>
						<Typography variant="h6" sx={{ marginTop: '10px', fontWeight: '500' }}>
							{contact?.name}
						</Typography>
						<Typography variant="body2" color="textSecondary">
							{gestor?.NomeFantasia || gestor?.NomeRazaoSocial} | {gestor?.DescricaoRota}
						</Typography>
					</Box>
					<Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
						{(!!gestor && gestor?.SenhaMensal && gestor?.SenhaMensal != null) ? (
							<Alert
								severity="success"
								action={
									<Button
										color="inherit"
										onClick={() => handleSelectedBoleto('senha', gestor)}
										size="small"
										disabled={loading} // Desabilitar o botão se estiver carregando
									>
										Enviar Código mensal
									</Button>
								}
							>
								{`A Senha mensal deste contrato está ativa!`}
							</Alert>
						) : (
							<Alert severity="warning">
								{`Este cliente não tem senha mensal gerada.`}
							</Alert>
						)}
					</Box>

					<Divider sx={{ marginBottom: '20px' }}>
						<Chip label="Boletos Disponíveis" sx={{ borderRadius: '5px' }} color="primary" size="small" />
					</Divider>

					{sortedBoletos?.length ? (
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ padding: '5px' }} align="center">Data de Vencimento</TableCell>
										<TableCell sx={{ padding: '5px' }} align="center">Valor</TableCell>
										<TableCell sx={{ padding: '5px' }} align="center">Ações</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sortedBoletos.map((boleto, index) => (
										<TableRow key={index}>
											<TableCell align="center">
												<Chip
													sx={{ borderRadius: '5px' }}
													size="small"
													color={isVencido(boleto.DataVencimento) ? "error" : "warning"}
													icon={isVencido(boleto.DataVencimento) ? <ErrorIcon /> : <AccessTimeIcon />}
													label={format(parse(boleto.DataVencimento, "dd/MM/yyyy HH:mm:ss", new Date()), 'dd/MM/yyyy')}
												/>
											</TableCell>
											<TableCell align="center">
												R${boleto.ValorTotal.toFixed(2)}
											</TableCell>
											<TableCell align="center">
												<Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
													<Tooltip title="Enviar PIX Copia e Cola">
														<span>
															<IconButton
																size="small"
																variant="contained"
																color="primary"
																onClick={() => handleSelectedBoleto('pix', boleto)}
																disabled={loading} // Desabilitar o botão se estiver carregando
															>
																{loading ? <CircularProgress size={24} /> : <PixIcon />}
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title="Enviar boleto PDF">
														<span>
															<IconButton
																size="small"
																variant="contained"
																color="error"
																onClick={() => handleSelectedBoleto('boleto', boleto)}
																disabled={loading} // Desabilitar o botão se estiver carregando
															>
																{loading ? <CircularProgress size={24} /> : <PictureAsPdfIcon />}
															</IconButton>
														</span>
													</Tooltip>
												</Box>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Box sx={{ textAlign: 'center', padding: '20px' }}>
							<WarningIcon sx={{ color: '#fffa00', mb: 1 }} />
							<Typography variant="body2" color="textSecondary">
								Não há boletos disponíveis
							</Typography>
						</Box>
					)}
				</DialogContent>

				<DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
					<Button
						onClick={() => onClose(false)}
						color="error"
						variant="contained"
						sx={{ width: '150px' }}
						disabled={loading} // Desabilitar o botão se estiver carregando
					>
						{i18n.t('confirmationModal.buttons.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UniGestorModal;
