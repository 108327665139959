import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useContext, useState } from "react";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../ConfirmationModal";
import EditMessageModal from "../EditMessageModal";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDeleteMessage = async () => {
    setLoading(true);
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      ToastError(err);
    } finally {
      setLoading(false);
    }
  };

  const SubmitEditMessage = async (body) => {
    setLoading(true);
    try {
      await api.post(`/messages/edit/${message.id}`, { body });
      setEditOpen(false);
    } catch (err) {
      ToastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleEditMessage = () => {
    setEditMessage(message);
    handleClose();
    setEditOpen(true);
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationOpen(true);
    handleClose();
  };

  function checkElapsedTime(creationDate) {
    const creationDateObj = new Date(creationDate);
    const now = new Date();
    const timeDifferenceInMilliseconds = now - creationDateObj;
    const elapsedTimeInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
    if (elapsedTimeInMinutes > 15) {
      return false;
    } else {
      return true;
    }
  }
  

  return (
    <>
      <EditMessageModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        message={editMessage}
        loading={loading}
        open={editOpen}
        onClose={setEditOpen}
        onSave={SubmitEditMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </EditMessageModal>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {/* Rendering the edit menu item only if the message is older than 15 minutes */}
        {message.fromMe && checkElapsedTime(message.createdAt) && (
          <MenuItem key="edit" onClick={handleEditMessage}>
            {i18n.t("messageOptionsMenu.edit")}
          </MenuItem>
        )}
        {/* Always rendering the delete menu item */}
        {message.fromMe && (
          <MenuItem key="delete" onClick={handleOpenConfirmationModal}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
        {/* Always rendering the reply menu item */}
        <MenuItem onClick={handleReplyMessage}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MessageOptionsMenu;
