import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, IconButton, TextField, Typography, Paper, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CloseIcon from '@mui/icons-material/Close';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SendIcon from '@mui/icons-material/Send';
import WebChatInput from '../../components/WebChatInput';

const useStyles = makeStyles()((theme) => {
    return {
        chatContainer: {
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 1000,
        },
        chatWindow: {
            position: 'relative',
            width: 350,
            height: 500,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
        },
        chatHeader: {
            backgroundColor: '#FAFAFA',
            padding: theme.spacing(1, 2),
            color: 'black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
        },
        chatBody: {
            padding: theme.spacing(2),
            overflowY: 'auto',
            flex: 1,
        },
        messageBox: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 2),
            borderRadius: 20,
        },
        inputBox: {
            borderTop: '1px solid #E0E0E0',
            padding: theme.spacing(1, 0),
            display: 'flex',
            alignItems: 'center',
        },
        userMessageBox: {
            marginLeft: 'auto', // Empurre para a direita
            backgroundColor: '#E8E8E8',
            color: 'black',
            borderRadius: 5,
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            maxWidth: '70%',
        },
        supportMessageBox: {
            display: 'flex',
            alignSelf: 'flex-start',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        supportMessage: {
            backgroundColor: '#1976D2',
            color: 'white',
            borderRadius: 5,
            padding: theme.spacing(1, 2),
            maxWidth: '70%',
        },
        supportAvatar: {
            marginRight: theme.spacing(1.5),
        },
        inputFile: {
            display: 'none',
        },
        emojiPicker: {
            position: 'absolute',
            bottom: '60px',
            right: '10px',
            zIndex: 1500,
            maxHeight: '250px',
            overflowY: 'auto',
        },
        iconButton: {
            padding: '0px',
            margin: theme.spacing(0, 1),
        }
    }
});

const avatarUrl = 'https://imgv3.fotor.com/images/gallery/watercolor-female-avatar.jpg';

const iMessages = [
    { text: 'Olá! Como posso ajudar você hoje?', sender: 'support' },
    { text: 'Estou tendo um problema com meu pedido.', sender: 'user' },
    { text: 'Sinto muito ouvir isso. Por favor, me dê um momento para verificar.', sender: 'support' },
];


const ChatSupport = () => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const messagesEndRef = useRef(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const [messages, setMessages] = useState(iMessages);

    const addEmoji = (emoji) => {
        setCurrentMessage(prev => prev + emoji.native);
        setShowEmojiPicker(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Aqui, você pode processar o arquivo conforme necessário.
            // Por exemplo, você pode enviá-lo para um servidor.
            console.log(file);
        }
    };
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [open]);

    useEffect(() => {
        document.body.style.backgroundColor = 'transparent';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);


    const sendMessage = () => {
        if (currentMessage.trim() !== '') {
            setMessages(pre => [...pre, { text: currentMessage, sender: 'user' }])
            setCurrentMessage('');
            scrollToBottom();
        }
    };

    return (
        <Box className={classes.chatContainer}>
            {!open ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    startIcon={<ChatBubbleIcon />}
                >
                    Precisa de ajuda?
                </Button>
            ) : (
                <Paper elevation={3} className={classes.chatWindow}>
                    <Box className={classes.chatHeader}>
                        <Box display="flex" alignItems="center">
                            <Avatar src={avatarUrl} alt="Suporte" className={classes.supportAvatar} />
                            <Box>
                                <Typography variant="subtitle1">Ana Silva</Typography>
                                <Typography variant="body2">Suporte Técnico</Typography>
                            </Box>
                        </Box>
                        <Button color="inherit" onClick={() => setOpen(false)} size="small">
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box className={classes.chatBody}>
                        {messages.map((message, index) => (
                            message.sender === 'user' ? (
                                <Box key={index} className={classes.userMessageBox}>
                                    <Typography variant="body2">{message.text}</Typography>
                                </Box>
                            ) : (
                                <Box key={index} className={classes.supportMessageBox}>
                                    <Avatar src={avatarUrl} alt="Suporte" className={classes.supportAvatar} />
                                    <Box className={classes.supportMessage}>
                                        <Typography variant="body2">{message.text}</Typography>
                                    </Box>
                                </Box>
                            )
                        ))}
                        <div ref={messagesEndRef} />
                    </Box>
                    <Box className={classes.inputBox}>
                        <WebChatInput
                            openEmoji={() => setShowEmojiPicker(!showEmojiPicker)}
                            onUpload={e => console.info(e)}
                            message={currentMessage}
                            updateMessage={e => setCurrentMessage(e.target.value)}
                            onSendMessage={() => sendMessage()}
                        />
                        {showEmojiPicker && (
                            <Picker
                                className={classes.emojiPicker}
                                onSelect={addEmoji}
                            />
                        )}
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default ChatSupport;
