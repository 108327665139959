import React, { useState, useEffect, useReducer, useRef } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	CardHeader,
	List,
	ListItem,
	ListItemText,
	Divider,
	IconButton,
	Avatar,
	Box,
	ListItemAvatar
} from "@mui/material";
import { i18n } from "../../translate/i18n";
import { isSameDay, parseISO, format } from "date-fns";
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import clsx from "clsx";
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTime from '@mui/icons-material/AccessTime';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Block from '@mui/icons-material/Block';
import Done from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import GetApp from '@mui/icons-material/GetApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import whatsBackgroundLight from "../../assets/wa-background.png";
import whatsBackgroundDark from "../../assets/wa-background-dark.png";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import RenderAudioFile from "./RenderAudioFile";
import VcardArrayPreview from "../VcardArrayPreview";
import VcardPreview from "../VcardPreview";
import GoogleDriveIcon from '../../assets/icons/google-drive.png';
import LightboxModal from "../LightboxModal";
import RenderIconFile from "../RenderIconFile";
import ToastError from "../../toast/error/toastError";


const useStyles = makeStyles()((theme) => {
	return {
		messagesListWrapper: {
			overflow: "hidden",
			position: "relative",
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
		},
		messagesList: {
			maxWidth: '540px',
			minWidth: '540px',
			maxHeight: '490px',
			minHeight: '490px',
			backgroundSize: '370px',
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			padding: "20px 20px 20px 20px",
			overflowY: "scroll",
			'@media (max-width: 600px)': {
				paddingBottom: "90px",
			},
			...theme.scrollbarStyles,
		},

		circleLoading: {

			position: "absolute",
			opacity: "70%",
			top: '80px',
			left: "47%",
			marginTop: 12,
		},

		messageLeftGroup: {
			marginLeft: 30,
			marginRight: 20,
			marginTop: 5,
			minWidth: 100,
			maxWidth: 360,
			height: "auto",
			display: "block",
			position: "relative",
			whiteSpace: "pre-wrap",
			alignSelf: "flex-start",
			borderTopLeftRadius: 0,
			borderTopRightRadius: 8,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,
			paddingLeft: 5,
			paddingRight: 5,
			paddingTop: 5,
			paddingBottom: 0,
		},
		messageLeft: {
			marginRight: 20,
			marginTop: 2,
			minWidth: 100,
			maxWidth: 360,
			height: "auto",
			display: "block",
			position: "relative",
			whiteSpace: "pre-wrap",
			alignSelf: "flex-start",
			borderTopLeftRadius: 0,
			borderTopRightRadius: 8,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,
			paddingLeft: 5,
			paddingRight: 5,
			paddingTop: 5,
			paddingBottom: 0,
		},

		quotedContainerLeft: {
			margin: "-3px -80px 6px -6px",
			overflow: "hidden",
			backgroundColor: "#f0f0f0",
			borderRadius: "7.5px",
			display: "flex",
			position: "relative",
		},

		quotedMsg: {
			padding: 10,
			maxWidth: 300,
			height: "auto",
			display: "block",
			whiteSpace: "pre-wrap",
			overflow: "hidden",
		},

		quotedSideColorLeft: {
			flex: "none",
			width: "4px",
			backgroundColor: "#6bcbef",
		},

		messageRight: {
			marginLeft: 20,
			marginTop: 2,
			minWidth: 100,
			maxWidth: 360,
			height: "auto",
			display: "block",
			position: "relative",
			whiteSpace: "pre-wrap",
			alignSelf: "flex-end",
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 0,
			paddingLeft: 5,
			paddingRight: 5,
			paddingTop: 5,
			paddingBottom: 0,
		},

		quotedContainerRight: {
			margin: "-3px -80px 6px -6px",
			overflowY: "hidden",
			backgroundColor: "#cfe9ba",
			borderRadius: "7.5px",
			display: "flex",
			position: "relative",
		},

		quotedMsgRight: {
			padding: 10,
			maxWidth: 300,
			height: "auto",
			whiteSpace: "pre-wrap",
		},

		quotedSideColorRight: {
			flex: "none",
			width: "4px",
			backgroundColor: "#35cd96",
		},
		messageContactName: {
			display: "flex",
			color: "#6bcbef",
			fontWeight: 500,
		},

		textContentItem: {
			overflowWrap: "break-word",
			padding: "3px 80px 6px 6px",
		},

		textContentItemDeleted: {
			fontStyle: "italic",
			color: "rgba(0, 0, 0, 0.36)",
			overflowWrap: "break-word",
			padding: "3px 80px 6px 6px",
		},

		messageMedia: {
			objectFit: "cover",
			width: 250,
			height: 200,
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,
		},

		timestamp: {
			fontSize: 11,
			position: "absolute",
			bottom: 0,
			right: 5,
			color: "#999",
		},
		textContentItemVcard: {
			overflowWrap: "break-word",
			padding: "3px 6px 6px 6px",
		},
		dailyTimestamp: {
			alignItems: "center",
			textAlign: "center",
			alignSelf: "center",
			width: "110px",
			backgroundColor: "#e1f3fb",
			margin: "10px",
			borderRadius: "10px",
			boxShadow: "0 1px 1px #b3b3b3",
		},

		dailyTimestampText: {
			color: "#808888",
			padding: 8,
			alignSelf: "center",
			marginLeft: "0px",
		},

		ackIcons: {
			fontSize: 18,
			verticalAlign: "middle",
			marginLeft: 4,
		},

		deletedIcon: {
			fontSize: 18,
			verticalAlign: "middle",
			marginRight: 4,
		},

		ackDoneAllIcon: {
			color: green[500],
			fontSize: 18,
			verticalAlign: "middle",
			marginLeft: 4,
		},

		downloadMedia: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: "inherit",
			padding: 10,
		},
		ticketNumber: {
			color: "#808888",
			padding: 8,
		},
	}
});

const reducer = (state, action) => {
	if (action.type === "LOAD_MESSAGES") {
		const messages = action.payload;
		const newMessages = [];

		messages.forEach((message) => {
			const messageIndex = state.findIndex((m) => m.id === message.id);
			if (messageIndex !== -1) {
				state[messageIndex] = message;
			} else {
				newMessages.push(message);
			}
		});

		return [...newMessages, ...state];
	}

	if (action.type === "ADD_MESSAGE") {
		const newMessage = action.payload;
		const messageIndex = state.findIndex((m) => m.id === newMessage.id);

		if (messageIndex !== -1) {
			state[messageIndex] = newMessage;
		} else {
			state.push(newMessage);
		}

		return [...state];
	}

	if (action.type === "UPDATE_MESSAGE") {
		const messageToUpdate = action.payload;
		const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

		if (messageIndex !== -1) {
			state[messageIndex] = messageToUpdate;
		}

		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};


const MessagesReportModal = ({ open, onClose, ticket }) => {

	const { classes } = useStyles();
	const { themeMode, themeDirection, themeColorPresets, themeStretch, themeLayout } = useSettings();
	const [messagesList, dispatch] = useReducer(reducer, []);
	const [pageNumber, setPageNumber] = useState(1);
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingUser, setLoadingUser] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedMessage, setSelectedMessage] = useState({});
	const [openLightbox, setOpenLightbox] = useState(false);
	const [messagesMedia, setMessagesMedia] = useState([]);
	const [selectedImage, setSelectedImage] = useState(0);

	useEffect(() => {
		messagesList.forEach(msg => {
			const exist = messagesMedia.map(e => e?.id).indexOf(msg?.id);
			if (msg?.mediaType == 'imageMessage' || msg?.mediaType == 'image' && exist === -1) {
				setMessagesMedia(oldArray => [...oldArray, msg]);
			}
		});
	}, [messagesList]);

	const checkTheme = () => {
		var retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
		switch (themeMode) {
			case 'dark':
				retorno = { backgroundImage: `url("${whatsBackgroundDark}")` };
				break;
			case 'light':
				retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
				break;
		}
		return retorno
	}

	useEffect(() => {
		setLoadingUser(true)
		dispatch({ type: "RESET" });
		setPageNumber(1);
		setTimeout(() => {
			setLoadingUser(false)
		}, 400);
	}, [ticket]);

	const getColorToChatFile = (fromMe, themeColor) => {
		let color = '';
		if (fromMe) {
			if (themeColor === 'dark') { color = '#fff' }
			else { color = '#212b36' }
		} else {
			if (themeColor === 'dark') { color = '#fff' }
			else { color = '#212b36' }
		}
		return `${color} !important`;
	}


	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchMessages = async () => {
				try {
					if (ticket?.id === undefined) return;
					const { data } = await api.get("/messages/" + ticket.id, {
						params: { pageNumber },
					});
					dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
					setHasMore(data.hasMore);
					setLoading(false);

					if (pageNumber === 1) {
						scrollToBottom();
					}
				} catch (err) {
					setLoading(false);
					ToastError(err);
				}
			};
			fetchMessages();
		}, 500);
		return () => {
			clearTimeout(delayDebounceFn);
		};
	}, [pageNumber, ticket]);


	const loadMore = () => {
		setPageNumber((prevPageNumber) => prevPageNumber + 1);
	};

	const scrollToBottom = () => {
		setTimeout(() => {
			var element = document.getElementById("messagesList");
			if (element) {
				element.scrollTop = element.scrollHeight;
			}
		}, 500);
		// if (lastMessageRef.current) {
		//   lastMessageRef.current.scrollIntoView({});
		// }
	};

	const handleScroll = async (e) => {

		const { scrollTop } = e.currentTarget;

		if (scrollTop === 0) {
			document.getElementById("messagesList").scrollTop = 1;
		}

		if (!hasMore) return;
		if (loading) return;

		if (scrollTop < 200) {
			if (loading) return;
			await new Promise(r => setTimeout(r, 250));
			loadMore();
		}

	};

	const renderFile = (message) => {
		const fileName = message.mediaUrl.split("/").pop();
		const fileType = (fileName).split(".").pop();

		return (

			<Box component="div"
				sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode) }}
				className={clsx(classes.fileContainerLeft, {
					[classes.fileContainerRight]: message.fromMe,
				})}
			>
				<span
					className={clsx(classes.fileSideColorLeft, {
						[classes.fileSideColorRight]: message?.fromMe,
					})}
				></span>
				<Box component="div" className={classes.fileMsg} >
					<Box component="span">
						<div style={{ display: 'flex', width: '255px', padding: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div>{
									<RenderIconFile type={fileType}
										style={{
											width: '45px',
											height: '40px'
										}}
									/>
								}</div>
								<div>
									<p
										style={{
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											width: '140px'
										}}
									>{message.body}</p>
									<small>{fileType}</small>
								</div>
								<div>
								</div>
							</div>
							<div>
								<IconButton
									sx={{ color: (theme) => getColorToChatFile(message.fromMe, theme.palette.mode) }}
									variant="text"
									target="_blank"
									href={message?.isDrive ? message.driveUrl : message.mediaUrl}
								>
									<DownloadForOfflineIcon />
								</IconButton >
							</div>
						</div>
					</Box>
				</Box>
			</Box >
		);
	};

	const handleOpenMessageOptionsMenu = (e, message) => {
		setAnchorEl(e.currentTarget);
		setSelectedMessage(message);
	};

	const handleCloseMessageOptionsMenu = (e) => {
		setAnchorEl(null);
	};

	const handleOpenLightbox = (blob, url, isDrive) => {
		if (isDrive) {
			const findUrl = messagesMedia.findIndex((message) => message.driveUrl === (url));
			setOpenLightbox(true);
			setSelectedImage(findUrl);
		} else {
			const findUrl = messagesMedia.findIndex((message) => message.mediaUrl.includes(url));
			setOpenLightbox(true);
			setSelectedImage(findUrl);
		}

	};

	const checkMessageMedia = (message) => {
		if (message.mediaType === "locationMessage" || message.mediaType === 'liveLocationMessage') {
			const data = JSON.parse(message.mediaUrl.split("public/")[1])
			return <div>
				<iframe
					width="500"
					height="200"
					style={{ borderColor: '#ff000000' }}
					src={`https://maps.google.com/maps?q=${data.degreesLatitude},${data.degreesLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed `} />
			</div>;
		}
		if (message.mediaType == "image" || message.mediaType == "imageMessage") {
			return <div>
				<ModalImageCors dataMessage={message} isDrive={message?.isDrive} onOpenLightbox={handleOpenLightbox} />
				{message?.isDrive && <img className={classes.driveIcon} src={GoogleDriveIcon} />}
			</div>;
		}
		if (message.mediaType == "audio" || message.mediaType == "audioMessage") {
			return (
				<>
					<RenderAudioFile
						themeMode={themeMode}
						srcAudio={message?.isDrive ? message.driveUrl : message.mediaUrl}
						colors={themeMode === 'dark' ? '#202c33' : '#ffffff'}
						idDiv={Math.floor(Math.random() * 9999999999) + 1}
						avatarUser={message?.contact?.profilePicUrl}
					/>
					{message?.isDrive && <img className={classes.driveIcon} src={GoogleDriveIcon} />}
				</>
			);
		}
		if (message.mediaType === "video" || message.mediaType === "videoMessage") {
			return (<>
				<video
					className={classes.messageMedia}
					src={message?.isDrive ? message.driveUrl : message.mediaUrl}
					controls
				/>
				{message?.isDrive && <img className={classes.driveIcon} src={GoogleDriveIcon} />}
			</>
			);
		} else {
			return (
				<>
					<div className={classes.downloadMedia}>
						<div>{renderFile(message)}</div>
					</div>
				</>
			);
		}
	};

	const checkMessageMedias = (message) => {
		if (message.mediaType === "image") {
			return <ModalImageCors imageUrl={message.mediaUrl} />;
		}
		if (message.mediaType === "audio") {
			return (
				<RenderAudioFile
					themeMode={themeMode}
					srcAudio={message.mediaUrl}
					colors={themeMode === 'dark' ? '#202c33' : '#ffffff'}
					idDiv={Math.floor(Math.random() * 9999999999) + 1}
				/>
			);
		}

		if (message.mediaType === "video") {
			return (
				<video
					className={classes.messageMedia}
					src={message.mediaUrl}
					controls
				/>
			);
		} else {
			return (
				<>
					<div className={classes.downloadMedia}>
						<Button
							startIcon={<GetApp />}
							color="primary"
							variant="outlined"
							target="_blank"
							href={message.mediaUrl}
						>
							Download
						</Button>
					</div>
					<Divider />
				</>
			);
		}
	};

	const renderMessageAck = (message) => {
		if (message.ack === 0) {
			return <AccessTime fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack === 1) {
			return <Done fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack === 2) {
			return <DoneAll fontSize="small" className={classes.ackIcons} />;
		}
		if (message.ack === 3 || message.ack === 4) {
			return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
		}
	};

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 350,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	const renderDailyTimestamps = (message, index) => {
		if (index === 0) {
			return (
				<span
					className={classes.dailyTimestamp}
					key={`timestamp-${message.id}`}
				>
					<div className={classes.dailyTimestampText}>
						{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
					</div>
				</span>
			);
		}
		if (index < messagesList.length - 1) {
			let messageDay = parseISO(messagesList[index].createdAt || new Date());
			let previousMessageDay = parseISO(messagesList[index - 1]?.createdAt || new Date());

			if (!isSameDay(messageDay, previousMessageDay)) {
				return (
					<span
						className={classes.dailyTimestamp}
						key={`timestamp-${message.id}`}
					>
						<div className={classes.dailyTimestampText}>
							{format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
						</div>
					</span>
				);
			}
		}
		if (index === messagesList.length - 1) {
			return (
				<div
					key={`ref-${message.createdAt}`}
					style={{ float: "left", clear: "both" }}
				/>
			);
		}
	};


	const renderNumberTicket = (message, index) => {
		if (index < messagesList.length && index > 0) {
			let messageTicket = message.ticketId;
			let previousMessageTicket = messagesList[index - 1].ticketId;

			if (messageTicket !== previousMessageTicket) {
				return (
					<div key={`ticket-${message.id}`} className={classes.ticketNumber}>
						#ticket: {messageTicket}
						<hr />
					</div>
				);
			}
		}
	};

	const renderMessageDivider = (message, index) => {
		if (index < messagesList.length && index > 0) {
			let messageUser = messagesList[index].fromMe;
			let previousMessageUser = messagesList[index - 1].fromMe;

			if (messageUser !== previousMessageUser) {
				return (
					<span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
				);
			}
		}
	};

	const getColorToChatQuoted = (fromMe, themeColor) => {
		let color = '';
		if (fromMe) {
			if (themeColor === 'dark') { color = '#025144' }
			else { color = '#cfe9ba' }
		} else {
			if (themeColor === 'dark') { color = '#1d282f' }
			else { color = '#f0f0f0' }
		}
		return `${color} !important`;
	}

	const renderQuotedMessage = (message) => {
		return (
			<Box component="div"
				sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode) }}
				className={clsx(classes.quotedContainerLeft, {
					[classes.quotedContainerRight]: message.fromMe,
				})}
			>
				<span
					className={clsx(classes.quotedSideColorLeft, {
						[classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
					})}
				></span>
				<Box component="div" className={classes.quotedMsg} >
					{!message.quotedMsg?.fromMe && (
						<Box component="span" className={classes.messageContactName}>
							{message.quotedMsg?.contact?.name}
						</Box>
					)}
					<Box component="span">{message.quotedMsg?.body}</Box>
				</Box>
			</Box>
		);
	};

	const extractContact = (array) => {

		let contactNumber = "";
		let contactName = "";

		array.forEach(item => {
			const [key, value] = item.split(":");
			if (key.includes('TEL')) {
				contactNumber = value;
			}
			if (key === "FN") {
				contactName = value;
			}
		});

		return { contactName, contactNumber };
	}

	const renderContact = (message) => {
		let array = [];

		if (message.body.includes('VCARD')) {
			array = message.body.split("\n");
		} else if (message.dataJson) {
			const dataJson = JSON.parse(message.dataJson);
			array = dataJson?.message?.extendedTextMessage?.text.split("\n") || [];
		}

		let contactMessage;
		if (message.mediaType == "contactsArrayMessage") {
			const contactsArray = JSON.parse(message.body);
			contactMessage = <VcardArrayPreview contacts={contactsArray} />;
		} else {
			const { contactName, contactNumber } = extractContact(array);
			contactMessage = <VcardPreview contact={contactName} numbers={contactNumber} />;
		}
		return contactMessage;

	};

	const renderMessages = () => {
		if (messagesList.length > 0) {
			const viewMessagesList = messagesList.map((message, index) => {
				if (!message) return;
				let isContact = message?.mediaType == 'contactMessage' || message?.mediaType == 'contactsArrayMessage';
				if (!message?.fromMe) {
					return (
						<React.Fragment key={message.id} >
							{renderMessageDivider(message, index)}
							{renderNumberTicket(message, index)}
							{renderDailyTimestamps(message, index)}
							<>
								{ticket?.isGroup ? (
									<>
										<Box component="div" id={message.id} className={`${classes.messageLeftGroup} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
											<div style={{ position: 'absolute', marginLeft: '-40px' }}>
												<HtmlTooltip
													title={
														<React.Fragment>
															<div>
																<div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
																	<Avatar sx={{ width: 80, height: 80 }} src={message.contact?.profilePicUrl} />
																</div>
																<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
																	<ListItem>
																		<div>
																			<ListItemText
																				primary={message.contact?.name}
																				secondary={
																					<React.Fragment>
																						<Typography
																							sx={{ display: 'inline' }}
																							component="span"
																							variant="body2"
																							color="text.primary"
																						>
																							Criado em: &nbsp;
																						</Typography>
																						{format(parseISO(message.contact?.createdAt), "dd/MM/yyyy HH:mm")}
																					</React.Fragment>
																				}
																			/>
																		</div>
																	</ListItem>
																	<Divider variant="inset" component="li" />
																	<ListItem>
																		<ListItemAvatar>
																			<Avatar>
																				<LocalPhoneIcon />
																			</Avatar>
																		</ListItemAvatar>
																		<ListItemText primary="Número" secondary={message.contact?.number} />
																	</ListItem>
																	{message.contact?.email && <>
																		<Divider variant="inset" component="li" />
																		<ListItem>
																			<ListItemAvatar>
																				<Avatar>
																					<EmailIcon />
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary="E-Mail" secondary={message.contact?.email} />
																		</ListItem>
																	</>}
																</List>
															</div>
														</React.Fragment>
													}
												>
													<Avatar
														alt={message.contact?.name}
														src={message.contact?.profilePicUrl}
														sx={{ width: 30, height: 30 }}
													/>
												</HtmlTooltip>
											</div>
											<div>
												<IconButton
													variant="contained"
													size="small"
													id="messageActionsButton"
													disabled={message.isDeleted}
													style={{
														display: "flex",
														position: "absolute",
														top: -4,
														right: 0,
														color: "#999",
														zIndex: 1,
														opacity: "90%",
													}}
													onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
												>
													<ArrowDropDownIcon />
												</IconButton>
												<div>
													<span className={classes.messageContactName}>
														{message.contact?.name}
													</span>
												</div>
												{message.mediaUrl && checkMessageMedia(message)}
												<div className={!isContact ? classes.textContentItem : classes.textContentItemVcard}>
													{message.quotedMsg && renderQuotedMessage(message)}
													{message.body.includes("VCARD") && renderContact(message)}
													{!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" &&
														<MarkdownWrapper>{message.body}</MarkdownWrapper>}
													<span className={classes.timestamp}>
														{format(parseISO(message.createdAt), "HH:mm")}
													</span>
												</div>
											</div>
										</Box>
										{/*message?.mediaType === "buttonsMessage" &&
						  <Box component="div" className={`${classes.messageLeftGroup} componentChat`} sx={{ backgroundColor: '#ffffff00', width: 490 }}>
							{renderButtons(message?.dataJson, message.id)}
						  </Box>*/}
									</>
								) : (
									<>
										<Box component="div" id={message.id} className={`${classes.messageLeft} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
											<IconButton
												variant="contained"
												size="small"
												id="messageActionsButton"
												disabled={message.isDeleted}
												style={{
													display: "flex",
													position: "absolute",
													top: -4,
													right: 0,
													color: "#999",
													zIndex: 1,
													opacity: "90%",
												}}
												onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
											>
												<ArrowDropDownIcon />
											</IconButton>
											{message.mediaUrl && checkMessageMedia(message)}
											<div className={!isContact ? classes.textContentItem : classes.textContentItemVcard}>
												{message.quotedMsg && renderQuotedMessage(message)}
												{message.body.includes("VCARD") && renderContact(message)}
												{!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" && <MarkdownWrapper>{message.body}</MarkdownWrapper>}

												<span className={classes.timestamp}>
													{format(parseISO(message.createdAt), "HH:mm")}
												</span>
											</div>
										</Box>
										{/*message?.mediaType === "buttonsMessage" &&
						  <Box component="div" className={`${classes.messageLeft} componentChat`} sx={{ backgroundColor: '#ffffff00', width: 490 }}>
							{renderButtons(message?.dataJson, message.id)}
						  </Box>*/}
									</>)
								}
							</>

						</React.Fragment >
					);
				} else {
					return (
						<React.Fragment key={message.id} >
							{renderMessageDivider(message, index)}
							{renderDailyTimestamps(message, index)}
							{message.mediaType === "notepad" ?
								<>
									<Box component="div" id={message.id} className={`${classes.messageRight} componentChat`} sx={{ fontStyle: 'italic', backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#e5e5e5' : '#feffd3' }}>
										<div
											className={!isContact ? classes.textContentItem : classes.textContentItemVcard}
										>
											<Box component="div" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#3c3c3c' : '#212b36' }}>
												<MarkdownWrapper>{message.body}</MarkdownWrapper>
											</Box>
											<span className={classes.timestamp}>
												{format(parseISO(message.createdAt), "HH:mm")}
												<NoteAltIcon style={{ margin: '-5px 5px', width: 20 }} />
											</span>
										</div>
									</Box>
								</>
								:
								<>
									<Box component="div" id={message.id} className={`${classes.messageRight} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3' }}>
										<IconButton
											variant="contained"
											size="small"
											id="messageActionsButton"
											disabled={message.isDeleted}
											style={{
												display: "flex",
												position: "absolute",
												top: -4,
												right: 0,
												color: "#999",
												zIndex: 1,
												opacity: "90%",
											}}
											onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
										>
											<ArrowDropDownIcon />
										</IconButton>
										{message.mediaUrl && checkMessageMedia(message)}
										<div
											className={!isContact ? classes.textContentItem : classes.textContentItemVcard}
										>
											{message.isDeleted && (
												<Block
													color="disabled"
													fontSize="small"
													className={classes.deletedIcon}
												/>
											)}
											{message.quotedMsg && renderQuotedMessage(message)}
											{
												(message?.body?.includes("VCARD") || Array.isArray(message?.dataJson) && message?.dataJson?.includes('VCARD')) && renderContact(message)
											}
											{!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" && !message.mediaUrl && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
											<span className={classes.timestamp}>
												{format(parseISO(message.createdAt), "HH:mm")}
												{renderMessageAck(message)}
											</span>
										</div>
									</Box>
								</>
							}
						</React.Fragment >
					);
				}
			});
			return viewMessagesList;
		} else {
			return <div>Say hello to your new contact!</div>;
		}
	};

	const renderMessagess = () => {
		if (messagesList.length > 0) {
			const viewMessagesList = messagesList.map((message, index) => {
				if (!message.fromMe) {
					return (
						<React.Fragment key={message.id} >
							{renderDailyTimestamps(message, index)}
							{renderMessageDivider(message, index)}
							{renderNumberTicket(message, index)}

							<>
								<Box component="div" className={classes.messageLeft} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
									{message.mediaUrl && checkMessageMedia(message)}
									<div className={classes.textContentItem}>
										{!message.mediaUrl && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
										<span className={classes.timestamp}>
											{format(parseISO(message.createdAt), "HH:mm")}
										</span>
									</div>
								</Box>
							</>

						</React.Fragment>
					);
				} else {
					return (
						<React.Fragment key={message.id} >
							{renderDailyTimestamps(message, index)}
							{renderMessageDivider(message, index)}
							<Box component="div" className={classes.messageRight} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3' }}>
								{message.mediaUrl && checkMessageMedia(message)}
								<div
									className={clsx(classes.textContentItem, {
										[classes.textContentItemDeleted]: message.isDeleted,
									})}
								>
									{message.isDeleted && (
										<Block
											color="disabled"
											fontSize="small"
											className={classes.deletedIcon}
										/>
									)}
									{message.quotedMsg && renderQuotedMessage(message)}
									{!message.mediaUrl && <MarkdownWrapper>{message.body}</MarkdownWrapper>}
									<span className={classes.timestamp}>
										{format(parseISO(message.createdAt), "HH:mm")}
										{renderMessageAck(message)}
									</span>
								</div>
							</Box>
						</React.Fragment>
					);
				}
			});
			return viewMessagesList;
		} else {
			return <div>Say hello to your new contact!</div>;
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
			BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)',
                    backgroundColor: 'rgba(0, 0, 30, 0.4)',
                },
            }}
		>
			<DialogTitle id="confirm-dialog">
				<LightboxModal
					id="messageMediaModal"
					images={messagesMedia}
					mainSrc={messagesMedia[selectedImage]}
					photoIndex={selectedImage}
					setPhotoIndex={setSelectedImage}
					isOpen={openLightbox}
					onCloseRequest={() => setOpenLightbox(false)}
				/>
				<CardHeader
					style={{ padding: '0px' }}
					avatar={loadingUser ? (
						<Skeleton animation="wave" variant="circular" width={40} height={40} />
					) : (
						<Avatar alt={ticket?.contact?.name} src={ticket?.contact?.profilePicUrl} />
					)}
					title={loadingUser ? (
						<Skeleton
							animation="wave"
							height={10}
							width="80%"
							sx={{ marginBottom: 6 }}
						/>
					) : (
						<>
							{ticket?.contact?.name}
						</>
					)}
					subheader={loadingUser ? (
						<Skeleton animation="wave" height={10} width="40%" />
					) : (
						<>
							{ticket?.createdAt && <>{`${i18n.t("reportTickets.table.createdOn")}: ${format(parseISO(ticket.createdAt), "dd/MM/yyyy HH:mm")}`}</>}
						</>
					)}
				/>
			</DialogTitle>
			<DialogContent dividers style={{
				minHeight: '540px',
				height: '540px',
				maxHeight: '540px'
			}}>
				<div
					id="messagesList"
					className={classes.messagesList}
					style={checkTheme()}
					onScroll={handleScroll}
				>
					{messagesList.length > 0 ? renderMessages() : []}
				</div>
				{loading && (
					<div>
						<CircularProgress className={classes.circleLoading} />
					</div>
				)}
			</DialogContent>
		</Dialog >
	);
};


export default MessagesReportModal;
