import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';

const UserCardRating = ({ user }) => {
    const {
        name,
        media: photo,
        rating_1,
        rating_2,
        rating_3,
        rating_4,
        rating_5
    } = user;

    const ratings = [rating_1, rating_2, rating_3, rating_4, rating_5];

    return (
        <Card sx={{ maxWidth: 400, boxShadow: '0px 10px 30px rgba(185, 75, 35, 0.3)', margin: 'auto' }}>
            <Avatar
                alt={name}
                src={photo || 'https://via.placeholder.com/150'}
                sx={{ width: 100, height: 100, margin: 'auto', marginTop: 1, marginBottom: 1 }}
            />
            <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: 1 }}>
                    {name}
                </Typography>
                {renderRating(5, ratings[4])}
                {renderRating(4, ratings[3])}
                {renderRating(3, ratings[2])}
                {renderRating(2, ratings[1])}
                {renderRating(1, ratings[0])}
            </CardContent>
        </Card>
    );
};

const renderRating = (value, count) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '8px 0' }}>
        <Typography variant="body2" color="text.secondary" sx={{ marginRight: '8px' }}>{count}</Typography>
        <Rating name="custom-rating" value={value} size="small" readOnly sx={{ color: '#FFA500' }} />
    </div>
);

export default UserCardRating;
