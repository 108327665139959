import toast from 'react-hot-toast';

const ToastSuccess = (text) => {
	const theme = localStorage.getItem('settings');
	const getProps = JSON.parse(theme);
	let themeMode = getProps?.themeMode ? getProps.themeMode : "light";
	const toastOptions = {
		success: {
			style: themeMode === 'dark' ? { border: '1px solid #2d3137', color: '#fff', background: '#312d46' } : {},
		},
	};

	toast.success(`${text}`, toastOptions.success);
};

export default ToastSuccess;
