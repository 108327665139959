import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
    return {
        welcomeMsg: {
            flexDirection: 'column',
            display: "flex",
            width: '100%',
            justifyContent: "center",
            alignItems: "center",
            height: 'calc(100vh - 80px)',
            textAlign: "center",
            borderRadius: 0,
        },
    }
});
const DragAndDropFiles = ({ children, ...other }) => {

    const { classes } = useStyles();
    const [dragging, setDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);

        const files = Array.from(e.dataTransfer.files);
        // Agora você pode lidar com os arquivos aqui
        console.log('Arquivos selecionados:', files);

        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'mp4', '3gp', 'mov', 'ogg', 'wav', 'mp3', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'zip', 'rar'];

        files.forEach((file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(fileExtension)) {
                // O arquivo possui uma extensão permitida
                console.log(`Arquivo de ${fileExtension}:`, file);
                // Faça algo com o arquivo, dependendo da extensão
            } else {
                // O arquivo não é suportado
                console.log('Arquivo não suportado:', file);
                // Lidar com outros tipos de arquivos, se necessário
            }
        });

    };

    return (
        <Grid
            onDragEnter={handleDragEnter}
            onDragOver={(e) => e.preventDefault()}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            {...other}
        >
            {/*
     
            {dragging ? (
                <div className={classes.welcomeMsg}>
                    <p>Arraste e Solte Arquivos Aqui</p>
                </div>
            ) : (
                children
            )}

            */}
            {children}
        </Grid>
    );
};

export default DragAndDropFiles;
