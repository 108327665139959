import React from 'react';
import { FileEarmarkZip, FiletypePdf, FiletypeTxt, FiletypeDocx, FiletypeXlsx, FiletypeSql, FileBinary } from "react-bootstrap-icons";

const RenderIconFile = ({ type, ...other }) => {

    const iconMappings = {
        pdf: <FiletypePdf {...other} color="#f34646" />,
        txt: <FiletypeTxt {...other} color="#65b9ff" />,
        docx: <FiletypeDocx {...other} color="#097640" />,
        xlsx: <FiletypeXlsx {...other} color="#097640" />,
        sql: <FiletypeSql {...other} color="#dc7939" />,
        zip: <FileEarmarkZip {...other} color="#e39517" />,
        rar: <FileEarmarkZip {...other} color="#e39517" />,
    };

    return iconMappings[type] || <FileBinary {...other} />;
};

export default RenderIconFile;
