import axios from "axios";
import ToastError from "../toast/error/toastError";

const getUserIp = async () => {
	try {
		const response = await axios.get('https://ipinfo.io');
		return response.data;
	} catch (error) {
		console.error(error)
		return { city: "Sem Cidade", region: "Sem Região", ip: "0.0.0.0" }
	}
}

export default getUserIp;