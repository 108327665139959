import React, { useState, useRef, useEffect } from "react";
import { Avatar, Typography, Box } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { i18n } from "../../translate/i18n";
import useResponsive from '../../hooks/useResponsive';
import ContactCard from "../ContactCard";
import ContactModal from "../ContactModal";
import TicketContactInfo from "../TicketContactInfo";
import { identifyLocationNumber, formatBrazilianNumber } from "../../utils/IdentifyLocationNumber";

const TicketInfo = ({ contact, ticket, gestor }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    // Verifica se a tela é maior que 1000px
    const isLargeScreen = useResponsive('up', 'md'); // Assumindo que 'md' representa 1000px no tema

    const titleStyle = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: isLargeScreen ? "80%" : "60%",
    };

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff0',
            maxWidth: 'none',
        },
    }));

    const location = identifyLocationNumber(contact?.number);

    // Função para abrir/fechar o tooltip em telas menores que 1000px
    const handleTooltipToggle = () => {
        if (!isLargeScreen) {
            setTooltipOpen(!tooltipOpen);
        }
    };

    // Fechar o tooltip ao clicar fora em telas pequenas
    useEffect(() => {
        function handleClickOutside(event) {
            if (!isLargeScreen && tooltipOpen && !event.target.closest('.tooltip-content')) {
                setTooltipOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [tooltipOpen, isLargeScreen]);

    return (
        <>
            <HtmlTooltip
                open={isLargeScreen ? undefined : tooltipOpen}  // Comportamento padrão (hover) em telas grandes, controle manual em telas pequenas
                onClose={() => setTooltipOpen(false)}  // Fecha o tooltip quando necessário
                title={
                    <div className="tooltip-content">
                        <ContactCard
                            contact={{
                                id: contact?.id,
                                profilePicUrl: contact?.profilePicUrl,
                                name: contact?.name,
                                extraInfo: contact?.extraInfo,
                                isBlocked: contact?.isBlocked,
                                number: formatBrazilianNumber(contact?.number || null),
                                isGroup: contact?.isGroup,
                                email: contact?.email,
                                contract: contact?.contract,
                                description: contact.description,
                                location: location && `${location?.country}, ${location?.state} - ${location?.city}`
                            }}
                            gestor={gestor}
                            setModalOpen={setModalOpen}
                            modalOpen={modalOpen}
                        />
                    </div>
                }
            >
                <div onClick={handleTooltipToggle} style={{ cursor: !isLargeScreen ? 'pointer' : 'default' }}>
                    <TicketContactInfo
                        profilePicUrl={contact.profilePicUrl}
                        name={contact?.name ? contact.name.split(' ').slice(0, 2).join(' ') : ''}
                        ContatoTelefone={gestor?.ContatoTelefone[0]?.Numero || null}
                        DescricaoRota={gestor?.DescricaoRota || null}
                        assigned={ticket.user ? `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}` : null}
                        contract={contact?.contract ? `Contrato: ${contact.contract}` : null}
                    />
                </div>
            </HtmlTooltip>

            <ContactModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                contactId={contact.id}
            />
        </>
    );
};

export default TicketInfo;
