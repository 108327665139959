import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MicIcon from '@mui/icons-material/Mic';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PersonIcon from '@mui/icons-material/Person';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SendIcon from '@mui/icons-material/Send';
import { Box, FormControlLabel, Hidden, List, Switch, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import MicRecorder from "mic-recorder-to-mp3";
import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import EmojiPicker from '../../components/EmojiPicker';
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import useResponsive from '../../hooks/useResponsive';
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import RenderQuickAnswer from "../RenderQuickAnswer";
import RenderReplyingMessage from "../RenderReplyingMessage";
import SendLocationModal from '../SendLocationModal';
import ShareVcardModal from "../ShareVcardModal";
import StyledMenu from "../StyledMenu";
import RecordingTimer from "./RecordingTimer";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles()((theme) => {
  return {
    mainWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    newMessageBox: {
      width: "100%",
      display: "flex",
      padding: "7px",
      alignItems: "center",
    },

    messageInputWrapper: {
      padding: 6,
      marginRight: 7,

      display: "flex",
      borderRadius: 20,
      flex: 1,
      position: "relative",
    },

    messageInput: {
      paddingLeft: 10,
      flex: 1,
      border: "none",
    },

    sendMessageIcons: {
      color: "grey",
    },

    uploadInput: {
      display: "none",
    },

    viewMediaInputWrapper: {
      display: "flex",
      padding: "10px 13px",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",

      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },

    emojiBox: {
      position: "absolute",
      bottom: 63,
      width: 40,
      borderTop: "1px solid #e8e8e8",
    },

    circleLoading: {
      opacity: "70%",
      position: "absolute",
      top: "20%",
      left: "50%",
      marginLeft: -12,
    },

    audioLoading: {
      opacity: "70%",
    },

    recorderWrapper: {
      display: "flex",
      alignItems: "center",
      alignContent: "middle",
    },

    speedDial: {
      position: 'absolute',
      bottom: 2,
      left: '70px',
      '@media(max-width: 650px)': {
        left: '0px',
      },
      '& .MuiFab-primary':
      {
        boxShadow: 'none'
      }
    },

    cancelAudioIcon: {
      color: "red",
    },

    sendAudioIcon: {
      color: "green",
    },

    replyginMsgWrapper: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 8,
      paddingLeft: 73,
      paddingRight: 7,
    },

    replyginMsgContainer: {
      flex: 1,
      marginRight: 5,
      overflowY: "hidden",
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },

    replyginMsgBody: {
      padding: 10,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },

    replyginContactMsgSideColor: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },

    replyginSelfMsgSideColor: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },

    messageContactName: {
      display: "flex",
      fontWeight: 500,
    },

    messageQuickAnswersWrapper: {
      margin: 0,
      position: "absolute",
      bottom: "50px",
      padding: "2px",
      border: "1px solid #CCC",
      left: 0,
      width: "100%",
      "& li": {
        listStyle: "none",
        "& a": {
          display: "block",
          padding: "8px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxHeight: "32px",
          "&:hover": {
            background: "#F1F1F1",
            cursor: "pointer",
          },
        },
      },
    },
  }
});

const actions = [
  { icon: <DescriptionIcon sx={{ color: '#7f65fe', marginRight: '10px' }} />, name: 'Documento', action: 'uploadDoc', color: '#5157ae', hover: '#373b75' },
  { icon: <PhotoLibraryIcon sx={{ color: '#037cff', marginRight: '10px' }} />, name: 'Fotos e vídeos', action: 'uploadMedia', color: '#0795dc', hover: '#016394' },
  { icon: <LocalSeeIcon sx={{ color: '#ff3076', marginRight: '10px' }} />, name: 'Camera', action: 'upload', color: '#0795dc', hover: '#016394' },
  { icon: <PersonIcon sx={{ color: '#009fde', marginRight: '10px' }} />, name: 'Contato', action: 'vcard', color: '#0795dc', hover: '#016394' },
  { icon: <LocationOnIcon sx={{ color: '#1ea757', marginRight: '10px' }} />, name: 'Localização', action: 'location', color: '#1fa755', hover: '#158343' },
];

const MessageInput = ({ ticketStatus, ticketId }) => {
  const { classes } = useStyles();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  const smUp = useResponsive('up', 'sm');
  const { user } = useContext(AuthContext);
  const [createNote, setCreateNote] = useState(false);
  const [signMessage, setSignMessage] = useState(true);
  const [useSignature, setUseSignature] = useState(signMessage);
  const [viewSignature, setViewSignature] = useState(false);
  const [usePosition, setUsePosition] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [company, setCompany] = useState([]);
  const [openRenderQuickAnswer, setOpenRenderQuickAnswer] = useState(false);


  const handleSing = (value) => {
    setSignMessage(value)
    setUseSignature(value)
  }

  const createNoteUser = () => { setCreateNote(!createNote) }

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleChangeInput = (e, content) => {
    if (String(e.target.value) === "") {
      setOpenRenderQuickAnswer(false)
    }
    if (String(e.target.value) === "/") {
      setOpenRenderQuickAnswer(true)
    }
    let str = e.target.value;
    if (content === 'notbreak') {
      str = str.replace(/\n$/, '');
      setInputMessage(str);
      handleLoadQuickAnswer(str);
    }
    if (content === 'break') {
      setInputMessage(`${str}`);
      handleLoadQuickAnswer(`${str}`);
    }
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }
    let selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const loadCompany = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/company/${user.companyId}`);
        if (isMounted) {
          setUseSignature(data?.useSignature || false);
          setUsePosition(data?.usePosition || false);
          setViewSignature(data?.viewSignature || false);
          setCompany(data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted) setLoading(false);
      }
    }

    loadCompany();

    return () => {
      isMounted = false;
    };

  }, []);

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      ToastError(err);
    }

    setLoading(false);
    setMedias([]);
  };

  const sendLocationMessage = async (coordinates) => {
    setLoading(true);
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: "",
      coordinates: coordinates,
      cleanMsg: inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      ToastError(err);
    }
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
    setInputMessage("");
  }

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setInputMessage("");
    setShowEmoji(false);
    setReplyingMessage(null);
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: useSignature
        ? `*${user?.name}*${usePosition && user?.position ? `\n*_${user.position}_*` : ''}:\n${inputMessage.trim()}`
        : inputMessage.trim(),
      cleanMsg: inputMessage.trim(),
      quotedMsg: replyingMessage,
      isText: true
    };

    if (createNote) {
      try {
        await api.post(`/messagesNotepad/${ticketId}`, message);
      } catch (err) {
        ToastError(err);
      }
      setCreateNote(false)
    } else {
      try {
        await api.post(`/messages/${ticketId}`, message);
      } catch (err) {
        ToastError(err);
      }
    }
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      ToastError(err);
      setLoading(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1) },
        });
        setQuickAnswer(data.quickAnswers);
      } catch (err) {
        console.error(err)
      }
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      ToastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      ToastError(err);
    }
  };

  function setInputValue(value) {
    setInputMessage(inputMessage ? inputMessage + value : value);
  }

  const changeButtonAndClick = (accept) => {
    let button = document.getElementById('uploadFilesInput');
    let upload = document.getElementById('upload-button');
    upload.accept = accept
    button.click()
  }

  const handleActionMenu = (action) => {

    switch (action) {
      case 'uploadDoc':
        changeButtonAndClick("")
        break;
      case 'uploadMedia':
        changeButtonAndClick("image/*,video/*")
        break;
      case 'vcard':
        handleOpenConfirmationModal()
        break;
      case 'location':
        setLocationOpen(true);
        break;
      default:
        changeButtonAndClick("")
        break;
    }

  }

  const handleSendVcard = async (ids) => {
    setLoading(true);
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: 'Contato',
      isVcard: true,
      contactInfo: ids
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      ToastError(err);
    }
    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
  };

  const closeRenderQuickAnswers = () => {
    setOpenRenderQuickAnswer(false)
  }

  const selectQuickAnswerMessage = (text) => {
    closeRenderQuickAnswers()
    setInputMessage(text)
  };

  const renderFileName = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    const newName = `arquivo.${fileExtension}`;
    return newName;
  };

  if (medias.length > 0)
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>
        {loading ? (
          <Box>
            <CircularProgress className={classes.circleLoading} />
          </Box>
        ) : (
          <span>
            {renderFileName(medias[0]?.name)}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  else {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>
        <SendLocationModal
          title="Buscar endereço"
          open={locationOpen}
          onClose={setLocationOpen}
          onConfirm={e => sendLocationMessage(e)}
        />
        <ShareVcardModal
          title={i18n.t("messageOptionsMenu.confirmationModal.title")}
          open={confirmationOpen}
          onClose={setConfirmationOpen}
          onConfirm={e => handleSendVcard(e)}
        >
          {i18n.t("messageOptionsMenu.confirmationModal.message")}
        </ShareVcardModal>
        {openRenderQuickAnswer && quickAnswers?.length > 0 && <>
          <List
            sx={{
              width: '100%',
              maxWidth: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 300,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            {quickAnswers.map((quickAnswer, i) => {
              return (<>
                <RenderQuickAnswer
                  quickAnswer={quickAnswer}
                  onSelect={text => selectQuickAnswerMessage(text)}
                  key={i}
                />
              </>)
            })
            }
          </List>
        </>}
        {replyingMessage && <RenderReplyingMessage message={replyingMessage} setReplyingMessage={setReplyingMessage} loading={loading} ticketStatus={ticketStatus} />}
        <Box component="div" sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? createNote ? '#e5e5e5' : '#212b36' : createNote ? '#feffd3' : '#f3f3f3' }} className={classes.newMessageBox}>
          <Box id="notebad" style={{ display: 'flex', alignItems: 'center', margin: smUp ? '0px' : '0px 5px' }}>
            <IconButton
              aria-label="upload"
              component="span"
              disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "archived")}
              onClick={e => createNoteUser(e)}
            >
              <NoteAltIcon style={{ padding: '0px' }} className={classes.sendMessageIcons} />
            </IconButton>
            {!createNote && <Hidden only={["sm", "xs"]}>
              <EmojiPicker id="emojiPicker" disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "archived")} value={''} setValue={setInputValue} />
            </Hidden>}
          </Box>
          {!createNote && <>
            <input
              multiple
              type="file"
              id="upload-button"
              disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "archived")}
              className={classes.uploadInput}
              onChange={handleChangeMedias}
            />
            <label htmlFor="upload-button">
              <IconButton
                id="uploadFilesInput"
                style={{ display: 'none' }}
                aria-label="upload"
                component="span"
                disabled={loading || recording || (ticketStatus !== "open" && ticketStatus !== "archived")}
              >
                <AttachFileIcon className={classes.sendMessageIcons} />
              </IconButton>
            </label>
          </>}
          {!createNote && <>
            <Box >
              <StyledMenu
                actions={actions}
                onClick={action => handleActionMenu(action)}
              />
            </Box>
            {viewSignature && <>
              {smUp && <FormControlLabel
                style={{ marginLeft: 0, marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    checked={useSignature}
                    onChange={(e) => {
                      handleSing(e.target.checked);
                    }}
                    name="showAllTickets"
                    color="primary"
                  />
                }
              />}
            </>}
          </>}
          <Box component="div" className={classes.messageInputWrapper}>
            <InputBase
              inputRef={(input) => {
                input && input.focus();
                input && (inputRef.current = input);
              }}
              sx={{ color: (theme) => theme.palette.mode === 'dark' ? createNote ? '#3c3c3c' : '#ffffff' : createNote ? '#3c3c3c' : '#3c3c3c' }}
              multiline
              maxRows={3}
              webkitdirectory="true"
              className={classes.messageInput}
              placeholder={
                ticketStatus === "open" || ticketStatus === "archived"
                  ? createNote
                    ? i18n.t("messagesInput.placeholderNotepad")
                    : smUp ? i18n.t("messagesInput.placeholderOpen") : i18n.t("messagesInput.placeholderOpenModile")
                  : i18n.t("messagesInput.placeholderClosed")
              }
              value={inputMessage}
              onChange={e => handleChangeInput(e, 'notbreak')}
              disabled={recording || (ticketStatus !== "open" && ticketStatus !== "archived")}
              onPaste={(e) => {
                if (ticketStatus == "open" || ticketStatus == "archived") {
                  handleInputPaste(e);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter" && !e.shiftKey) { handleSendMessage(); }
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                  // Evita a quebra de linha padrão quando Shift+Enter é pressionado
                  e.preventDefault();
                  const updatedValue = `${inputMessage}\n`;
                  handleChangeInput({ target: { value: updatedValue } }, 'break');
                }
              }}
            />
          </Box>
          {!createNote ? <>
            {inputMessage ? (
              <IconButton
                aria-label="sendMessage"
                component="span"
                onClick={handleSendMessage}
                disabled={loading}
              >
                <SendIcon className={classes.sendMessageIcons} />
              </IconButton>
            ) : recording ? (
              <Box className={classes.recorderWrapper}>
                <IconButton
                  aria-label="cancelRecording"
                  component="span"
                  fontSize="large"
                  disabled={loading}
                  onClick={handleCancelAudio}
                >
                  <HighlightOffIcon className={classes.cancelAudioIcon} />
                </IconButton>
                {loading ? (
                  <Box>
                    <CircularProgress className={classes.audioLoading} />
                  </Box>
                ) : (
                  <RecordingTimer />
                )}
                <IconButton
                  aria-label="sendRecordedAudio"
                  component="span"
                  onClick={handleUploadAudio}
                  disabled={loading}
                >
                  <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                </IconButton>
              </Box>
            ) : (
              <Tooltip title="Gravar áudio">
                <IconButton
                  aria-label="showRecorder"
                  component="span"
                  disabled={loading || (ticketStatus !== "open" && ticketStatus !== "archived")}
                  onClick={handleStartRecording}
                >
                  <MicIcon className={classes.sendMessageIcons} />
                </IconButton>
              </Tooltip>
            )}
            {/*<Tooltip title="Agendar mensagem">
              <IconButton
                aria-label="showRecorder"
                component="span"
                disabled={loading || (ticketStatus !== "open" && ticketStatus !== "archived")}
                onClick={handleStartRecording}
              >
                <MoreTimeIcon className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>*/}
          </>
            :
            <>
              <IconButton
                aria-label="sendMessage"
                component="span"
                onClick={handleSendMessage}
                disabled={loading}
              >
                <SendIcon className={classes.sendMessageIcons} />
              </IconButton>
            </>
          }
        </Box>
      </Paper >
    );
  }
};


export default MessageInput;

