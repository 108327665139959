import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { makeStyles } from 'tss-react/mui';
import { Typography, Grid, Divider, Avatar, AvatarGroup, Box, Button, Badge } from '@mui/material';
import { AuthContext } from "../../context/Auth/AuthContext";
import VcardModal from '../VcardModal';
import ToastError from '../../toast/error/toastError';

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: "250px",
        padding: theme.spacing(1),
    },
    avatar: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey[200],
        marginRight: '20px'
    },
    typography: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(1.25),
    },
    badge: {
        backgroundColor: theme.palette.secondary.main
    }
}));

function extractVcardInfo(vcard) {
    const nameMatch = vcard.match(/FN:(\w+)/);
    const numberMatch = vcard.match(/waid=(\d+):/);
    if (!nameMatch || !numberMatch) {
        throw new Error("Não foi possível extrair as informações do contato.");
    }
    return {
        nome: nameMatch[1],
        numero: numberMatch[1]
    };
}

const VcardArrayPreview = ({ contacts }) => {
    const classes = useStyles();
    const [contactsVcard, setContactsVcard] = useState([]);
    const [openVcardModal, setOpenVcardModal] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const { user } = useContext(AuthContext);
    const history = useHistory();

    const getCleanedNumber = (num) => num.replace(/\D/g, "");

    const extractedContacts = contacts.map(contact => {
        const nameMatch = contact.vcard.match(/FN:(.+)/);
        const name = nameMatch ? nameMatch[1].trim() : contact.displayName;

        const waidMatch = contact.vcard.match(/waid=(\d+)/);
        const telMatch = contact.vcard.match(/TEL.*:(\+[\d\s\-]+)/);
        const rawNumber = waidMatch ? waidMatch[1] : (telMatch ? telMatch[1].trim() : null);
        const number = rawNumber ? rawNumber.replace(/[^\d]/g, '') : null;

        return {
            name: name,
            number: number
        };
    });


    useEffect(() => {
        processContacts();
    }, [contacts]);

    const processContacts = async () => {
        const results = extractedContacts;
        setSelectedContacts(results);
    };

    const handleNewChat = async ({ name, number }) => {
        try {
            const { data: ticket } = await api.post("/tickets", {
                contact: {
                    name,
                    number
                },
                createImmediate: true,
                userId: user.id,
                status: "open",
            });
            handleCloseModal()
            history.push(`/tickets/${ticket.uuid}`);
        } catch (err) {
            ToastError(err);
            console.error(err);
        }
    };

    if (selectedContacts.length === 0) return null;

    const handleCloseModal = () => setOpenVcardModal(false);
    const handleOpenModal = () => setOpenVcardModal(true);

    return (
        <div className={classes.container}>
            <VcardModal
                open={openVcardModal}
                onClose={handleCloseModal}
                contacts={selectedContacts}
                handleNewChat={handleNewChat}
            />
            <Grid container spacing={1}>
                <Grid item sx={{ marginLeft: '10px' }} xs={2}>
                    <AvatarGroup max={3}
                        sx={{
                            '& .MuiAvatar-root': {
                                marginLeft: '-35px'
                            },
                            marginLeft: '10px'
                        }}
                    >
                        {selectedContacts.map(contact => (
                            <Avatar alt={contact?.name || contact?.number} src={contact?.profilePicUrl} />
                        ))}
                    </AvatarGroup>
                </Grid>
                <Grid item xs={9}>
                    <Typography className={classes.typography} variant="subtitle1" gutterBottom>
                        {`${selectedContacts[0].name} e outros ${selectedContacts.length - 1}`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <Button
                        fullWidth
                        color="primary"
                        onClick={handleOpenModal}
                        disabled={!selectedContacts[0].number}
                    >
                        Ver Todos
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default VcardArrayPreview;
