import React, { useState, useEffect } from "react";
import { makeStyles } from 'tss-react/mui';
import ModalImage from "react-modal-image";
import Image from '../../components/Image';
import api from "../../services/api";

import notImage from './notImage.png';


const useStyles = makeStyles()((theme) => {
	return {
		messageMedia: {
			objectFit: "cover",
			width: 250,
			height: 250,
			borderRadius: 8,
		},
	}
});


const ModalImageCors = ({ dataMessage, isDrive, onOpenLightbox }) => {
	const { classes } = useStyles();
	const [blobUrl, setBlobUrl] = useState("");
	const [imageFound, setImageFound] = useState(true);

	useEffect(() => {
		const fetchImage = async () => {
			const { mediaUrl, driveUrl } = dataMessage;
			if (isDrive && driveUrl) {
				setBlobUrl(driveUrl);
				setImageFound(true);
			} else {
				try {
					const { data, headers } = await api.get(mediaUrl, {
						responseType: "blob",
					});
					const url = window.URL.createObjectURL(
						new Blob([data], { type: headers["content-type"] })
					);
					setBlobUrl(url);
					setImageFound(true);
				} catch (error) {
					console.error(error);
					setImageFound(false);
				}
			}
		};

		fetchImage();
	}, [dataMessage, isDrive]);

	if (!imageFound) {
		return (
			<div style={{ paddingBottom: '13px' }}>
				<ModalImage
					className={classes.messageMedia}
					smallSrcSet={notImage}
					medium={notImage}
					large={notImage}
					alt="image"
				/>
			</div>
		);
	}
	const urlImage = isDrive ? dataMessage.driveUrl : dataMessage.mediaUrl
	return (
		<div style={{ paddingBottom: '13px' }}>
			<Image
				className={classes.messageMedia}
				alt="image"
				src={blobUrl}
				onClick={() => onOpenLightbox(blobUrl, urlImage, isDrive)}
				sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
			/>
		</div>
	);
};

export default ModalImageCors;
