import React, { useContext, useEffect, useReducer, useState } from "react";

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import {
    Button,
    Card,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import ConfirmationModal from "../../components/ConfirmationModal";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagsModal from "../../components/TagsModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from '../../hooks/useSettings';
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";

import socket from "../../hooks/useSocket";


const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newQueues = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((q) => q.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newQueues.push(tag);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((u) => u.id == tag.id);
    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;
    const tagIndex = state.findIndex((q) => q.id == tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Tags = () => {

  const { classes } = useStyles();

  const [tags, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const { themeStretch } = useSettings();
  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { companyId } = user;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/tags");
        dispatch({ type: "LOAD_TAGS", payload: data });
        setLoading(false);
      } catch (err) {
        ToastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    

    
socket.on(`tags-${companyId}`, (data) => {
      if ((data.action === "update" || data.action === "create")) {
        dispatch({ type: "UPDATE_TAGS", payload: data.tag });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TAG", payload: data.tagId });
      }
    });

    return () => {
      socket.off(`tags-${companyId}`);
    };
  }, []);


  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (tag) => {
    setSelectedQueue(tag);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      ToastSuccess(i18n.t("Tag deleted successfully!"));
    } catch (err) {
      ToastError(err);
    }
    setSelectedQueue(null);
  };

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            selectedQueue &&
            `${i18n.t("tags.confirmationModal.deleteTitle")} ${selectedQueue.name
            }?`
          }
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => handleDeleteQueue(selectedQueue.id)}
        >
          {i18n.t("tags.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <TagsModal
          open={queueModalOpen}
          onClose={handleCloseQueueModal}
          tagId={selectedQueue?.id}
          scheduleId={selectedQueue?.scheduleId}
        />
        <Page title={i18n.t("tags.title")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("tags.title")}
              action={
                <>
                  <Button
                    style={{ margin: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={handleOpenQueueModal}
                  >
                    {i18n.t("tags.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 500 }} style={{ marginTop: '40px' }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {i18n.t("tags.table.name")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("tags.table.color")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("tags.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <>
                        {tags.map((tag) => (
                          <TableRow key={tag.id}>
                            <TableCell align="left">{tag.name}</TableCell>
                            <TableCell align="center">
                              <div className={classes.customTableCell}>
                                <span
                                  style={{
                                    backgroundColor: tag.color,
                                    width: 20,
                                    height: 20,
                                    borderRadius: '10px',
                                    alignSelf: "center",
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditQueue(tag)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={() => {
                                  setSelectedQueue(tag);
                                  setConfirmModalOpen(true);
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={4} />}
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default Tags;
