import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";;
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Box,
  Card,
  Input,
  Stack,
  Link,
  Avatar,
  Alert,
  Container,
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel
} from '@mui/material';

import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import useResponsive from '../../hooks/useResponsive';
import { i18n } from "../../translate/i18n";
import Page from '../../components/Page';
import iconPage from '../../assets/icons/Messages-amico.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Image from '../../components/Image';
import OutlinedInput from '@mui/material/OutlinedInput';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor: "transparent",
      width: "150px", // Fix IE 11 issue.
      height: "150px", // Fix IE 11 issue.
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logo: {
      width: "100%", // Fix IE 11 issue.
    },
  }
});

const RecoveryUser = () => {

  const { classes } = useStyles();

  let { recovery, code } = useParams();
  const navigate = useHistory();

  const [user, setUser] = useState({ email: recovery, password: "", rePassword: "", code: code });
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => setChecked(event.target.checked);
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.post("recovery-user/", { email: recovery, code });
          setLoading(false);
        } catch (err) {
          ToastError(err);
          setTimeout(() => {
            window.location = '/recovery';
            setLoading(false);
          }, 1000);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [recovery, code]);

  const handleLogin = async (values) => {

    if (user?.password !== user?.rePassword) return ToastError('As senhas não coincidem.');

    if (user?.password.length < 6) return ToastError('A senha é muito curta');

    if (!user.email || !user?.password || !user?.rePassword || !user?.code) return ToastError('Ocorreu um erro, entre em contato com o suporte.');

    try {
      await api.post(`/recovery-password/`, values);
      window.location = `/login`;
    } catch (err) {
      ToastError(err);
    }
  };

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };


  return (
    <Page title={i18n.t("recovery.title")}>
      <RootStyle>
        <Backdrop
          sx={{ backgroundColor: '#fff0', color: '#fff', zIndex: (theme) => theme.zIndex.drawer }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {i18n.t("recovery.formTitle")}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}> {i18n.t("recovery.details2")}</Typography>
              </Box>
            </Stack>
            <form className={classes.form} autoComplete="off" noValidate onSubmit={handlSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={checked ? 'text' : 'password'}
                id="password"
                label={i18n.t("recovery.form.password")}
                name="password"
                value={user.password}
                onChange={handleChangeInput}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type={checked ? 'text' : 'password'}
                id="rePassword"
                label={i18n.t("recovery.form.rePassword")}
                name="rePassword"
                value={user.rePassword}
                onChange={handleChangeInput}
              />
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  defaultChecked />} label="Ver senha" />
              </FormGroup>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: 40 }}
              >
                {i18n.t("recovery.buttons.change")}
              </Button>
            </form>

          </ContentStyle>
        </Container>
      </RootStyle>
    </Page >
  );
};

export default RecoveryUser;
