import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { Box, IconButton, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Iconify from './Iconify';
import { useTheme } from '@mui/material/styles';
import { i18n } from '../translate/i18n';

// ----------------------------------------------------------------------

EmojiPicker.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  alignRight: PropTypes.bool,
};

export default function NewEmojiPicker({ disabled, value, setValue, alignRight = false, ...other }) {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const handleEmojiClick = (event) => {
    setValue(value + event.emoji);
    setOpenDialog(false);
  };

  const handleClickAway = () => {
    setOpenDialog(false);
  };

  const triggerPicker = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <Box position="relative" {...other}>
        <IconButton disabled={disabled} onClick={triggerPicker}>
          <Iconify icon={'eva:smiling-face-fill'} width={25} height={25} />
        </IconButton>
      </Box>
      <Dialog open={openDialog} onClose={handleClickAway} 
      BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}>
        <DialogContent sx={{ padding: '0px' }}>
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            disableSearchBar
            theme={theme.palette.mode}
            disableSkinTonePicker
            groupVisibility={{
              recently_used: false,
              smileys_people: true,
              animals_nature: true,
              food_drink: true,
              travel_places: true,
              activities: true,
              objects: true,
              symbols: true,
              flags: true,
              custom: false,
            }}
            native
            autoFocus
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
