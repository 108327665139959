import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useIsTabFocused = () => {
    const [isTabFocused, setIsTabFocused] = useState(true);
    const location = useLocation();

    useEffect(() => {
        
        const handleFocus = () => {
            setIsTabFocused(true);
        };

        const handleBlur = () => {
            setIsTabFocused(false);
        };

        // Verificar se a rota atual inclui "/tickets" antes de adicionar os ouvintes de foco
        if (location.pathname.includes('/tickets')) {
            window.addEventListener('focus', handleFocus);
            window.addEventListener('blur', handleBlur);

            // Remover os ouvintes de foco quando o componente é desmontado ou a rota muda
            return () => {
                window.removeEventListener('focus', handleFocus);
                window.removeEventListener('blur', handleBlur);
            };
        }

        // Se a rota não incluir "/tickets", retornar uma função vazia
        return () => { };

    }, [location.pathname]);

    return isTabFocused;
};

export default useIsTabFocused;
