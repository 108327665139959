import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import { green } from "@mui/material/colors";
import { makeStyles } from 'tss-react/mui';

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@mui/material";

import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import QueueSelect from "../QueueSelect";

import { AuthContext } from "../../context/Auth/AuthContext";

import FacebookIcon from '@mui/icons-material/Facebook';
import ForumIcon from '@mui/icons-material/Forum';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},

		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {
			color: green[500],
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
	}
});

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
});

const channels = [
	{ value: "whatsapp", disabled: false, color: "#0dc242", Icon: WhatsAppIcon, labelKey: "whatsappModal.channel.whatsapp" },
	{ value: "telegram", disabled: false, color: "#3390ec", Icon: TelegramIcon, labelKey: "whatsappModal.channel.telegram" },
	{ value: "webchat", disabled: true, color: "#0573e7", Icon: ForumIcon, labelKey: "whatsappModal.channel.webchat" },
	{ value: "instagram", disabled: true, color: "#fe0079", Icon: InstagramIcon, labelKey: "whatsappModal.channel.instagram" },
	{ value: "messenger", disabled: true, color: "#0573e7", Icon: FacebookIcon, labelKey: "whatsappModal.channel.messenger" },
];

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const { user: loggedInUser } = useContext(AuthContext);

	const { classes } = useStyles();
	const initialState = {
		companyId: loggedInUser.companyId,
		name: "",
		greetingMessage: "",
		isDefault: false,
		autoCloseMessage: "",
		closedMessage: "",
		farewellMessage: "",
		token: "",
	};

	function msToTime(duration) {
		var milliseconds = Math.floor((duration % 1000) / 100),
			seconds = Math.floor((duration / 1000) % 60),
			minutes = Math.floor((duration / (1000 * 60)) % 60),
			hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
		hours = (hours < 10) ? "0" + hours : hours;
		minutes = (minutes < 10) ? "0" + minutes : minutes;
		seconds = (seconds < 10) ? "0" + seconds : seconds;
		return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
	}

	const [autoClose, setAutoClose] = useState(false);
	const [useTyping, setUseTyping] = useState(false);
	const [insistAnswering, setInsistAnswering] = useState(true);
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [channel, setChannel] = useState('whatsapp');
	const [loading, setLoading] = useState(false)
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [timeToAutoClose, setTimeToAutoClose] = useState(dayjs('2022-04-07'));
	const [autoCloseTime, setTimeToAutoCloseMilliseconds] = useState(0);

	useEffect(() => {
		const fetchSession = async () => {

			if (whatsAppId) {
				try {
					const { data } = await api.get(`whatsapp/${whatsAppId}`);
					setWhatsApp(data);
					setChannel(data.channel);
					setAutoClose(data.autoClose);
					setUseTyping(data.useTyping);
					setInsistAnswering(data.insistAnswering);
					if (data?.autoCloseTime) {
						let date = new Date().toISOString().split('T')[0]
						let time = msToTime(Number(data.autoCloseTime));
						setTimeToAutoClose(new Date(`${date}T${time}`))
					}

					if (data?.queuesSequence) {
						const reorder = JSON.parse(data.queuesSequence)
						setSelectedQueueIds(reorder);
					} else {
						const whatsQueueIds = data.queues?.map(queue => queue.id);
						setSelectedQueueIds(whatsQueueIds);
					}

				} catch (err) {
					ToastError(err);
				}
				setLoading(false);
			} else {
				setLoading(false);
			}



		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, values);
			} else {
				await api.post("/whatsapp", values);
			}
			ToastSuccess(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			ToastError(err);
		}
	};



	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	const settingAutoClose = (time) => {

		var d = new Date(time);

		let hours = d.getHours();
		let minutes = d.getMinutes();
		let seconds = d.getSeconds();

		const toMilliseconds = (hours, minutes, seconds) => (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
		let convertToMilliseconds = toMilliseconds(hours, minutes, seconds);

		setTimeToAutoClose(time);
		setTimeToAutoCloseMilliseconds(convertToMilliseconds);
	}

	const changeAutoClose = (event) => {
		setAutoClose(event.target.checked);
	};

	const changeUseTyping = (event) => {
		setUseTyping(event.target.checked);
	};

	const changeInsistAnswering = (event) => {
		setInsistAnswering(event.target.checked);
	};

	const handleChangeChannel = e => setChannel(e.target.value);

	if (loading) {
		return (
			<Box>
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth="sm"
					fullWidth
					scroll="body"
					BackdropProps={{
						style: {
							backdropFilter: 'blur(3px)',
							backgroundColor: 'rgba(0, 0, 30, 0.4)',
						},
					}}
				>
					<DialogTitle>
						{whatsAppId
							? i18n.t("whatsappModal.title.edit")
							: i18n.t("whatsappModal.title.add")}
					</DialogTitle>
					<DialogContent dividers>
						<CircularProgress
							size={48}
							className={classes.buttonProgress}
						/>
					</DialogContent>
				</Dialog>
			</Box>
		)
	}

	return (
		<Box className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							if ((autoClose && values.autoCloseMessage === "" && !whatsAppId) || (autoClose && autoCloseTime === 0 && !whatsAppId)) {
								actions.setSubmitting(false);
								return ToastError('é necessário criar uma mensagem de conclusão');
							} else {
								let object = {
									"companyId": values.companyId,
									"name": values.name,
									"greetingMessage": values.greetingMessage,
									"isDefault": values.isDefault,
									"token": values.token,
									"queueIds": selectedQueueIds,
									"channel": channel,
									"autoCloseMessage": values.autoCloseMessage,
									"autoClose": autoClose,
									"insistAnswering": insistAnswering,
									"useTyping": useTyping,
									"autoCloseTime": autoCloseTime === 0 ? '' : autoCloseTime,
									"closedMessage": values.closedMessage,
									"queuesSequence": JSON.stringify(selectedQueueIds),
									"farewellMessage": values.farewellMessage,
								}
								handleSaveWhatsApp(object);
								actions.setSubmitting(false);
							}


						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<Field
								style={{ display: 'none' }}
								as={TextField}
								type="hidden"
								name="companyId"
							/>
							<DialogContent dividers>
								<Box className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										fullWidth
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										style={{ display: 'none' }}
										control={
											<Field
												as={Checkbox}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label={i18n.t("whatsappModal.form.default")}
									/>
									<FormControl fullWidth style={{ marginTop: '8px' }} disabled={!!whatsAppId}>
										<InputLabel id="demo-simple-select-label">{i18n.t("whatsappModal.channel.name")}</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={channel}
											label={i18n.t("whatsappModal.channel.name")}
											onChange={handleChangeChannel}
										>
											{channels.map(({ value, disabled, color, Icon, labelKey }) => (
												<MenuItem key={value} value={value} disabled={disabled}>
													<Box style={{ display: 'flex' }}>
														<Icon style={{ color }} />
														<p style={{ marginLeft: 5 }}>{i18n.t(labelKey)}</p>
													</Box>
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
								<Box style={{ display: 'flex' }}>
									<FormControlLabel
										control={<Checkbox
											checked={autoClose}
											onChange={changeAutoClose}
											inputProps={{ 'aria-label': 'controlled' }}
										/>}
										label={i18n.t("whatsappModal.autoClose")}
									/>
									<FormControlLabel
										control={<Checkbox
											checked={useTyping}
											onChange={changeUseTyping}
											inputProps={{ 'aria-label': 'controlled' }}
										/>}
										label={i18n.t("whatsappModal.useTyping")}
									/>
								</Box>
								<Box style={{ display: 'flex' }}>
									<FormControlLabel
										control={<Checkbox
											checked={insistAnswering}
											onChange={changeInsistAnswering}
											inputProps={{ 'aria-label': 'controlled' }}
										/>}
										label={i18n.t("whatsappModal.insistAnswering")}
									/>
								</Box>
								<Box style={{ display: autoClose ? 'flex' : 'none' }} className={classes.multFieldLine}>
									<Box style={{ marginTop: '8px' }}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<TimePicker
												ampm={false}
												openTo="hours"
												views={['hours', 'minutes', 'seconds']}
												inputFormat="HH:mm:ss"
												mask="__:__:__"
												label={i18n.t("whatsappModal.autoCloseTime")}
												value={timeToAutoClose}
												onChange={(newValue) => {
													settingAutoClose(newValue);
												}}
												renderInput={(params) => <TextField {...params} />}
											/>
										</LocalizationProvider>
									</Box>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.autoCloseMessage")}
										type="autoCloseMessage"
										multiline
										maxRows={4}
										fullWidth
										name="autoCloseMessage"
										variant="outlined"
										margin="dense"
									/>
								</Box>
								<Box style={{ display: channel === 'telegram' ? 'flex' : 'none' }}>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.token")}
										type="token"
										multiline
										maxRows={4}
										fullWidth
										name="token"
										variant="outlined"
										margin="dense"
									/>
								</Box>
								<Box>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										maxRows={4}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>

									<Field
										as={TextField}
										label={i18n.t("queueModal.form.farewellMessage")}
										type="farewellMessage"
										multiline
										maxRows={4}
										fullWidth
										name="farewellMessage"
										error={
											touched.farewellMessage && Boolean(errors.farewellMessage)
										}
										helperText={
											touched.farewellMessage && errors.farewellMessage
										}
										variant="outlined"
										margin="dense"
									/>

									<Field
										as={TextField}
										label={i18n.t("queueModal.form.closedMessage")}
										type="closedMessage"
										multiline
										maxRows={4}
										fullWidth
										name="closedMessage"
										error={
											touched.closedMessage && Boolean(errors.closedMessage)
										}
										helperText={
											touched.closedMessage && errors.closedMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</Box>

								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</Box>
	);
};

export default WhatsAppModal;