// @mui
import { Grid, IconButton, Stack } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
//
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function ChangeMode() {

    const { themeMode, onChangeMode } = useSettings();

    const changeTheme = (color) => {
        const obj = { target: { value: color === 'dark' ? 'light' : 'dark' } }
        onChangeMode(obj)
    }

    return (
        <Stack direction="row" alignItems="center" >
            <Grid dir="ltr" container>
                <IconButton sx={{ padding: '5px' }} onClick={() => changeTheme(themeMode)} aria-label="delete" size="large">
                    <Iconify icon={themeMode === 'dark' ? 'ph:sun-duotone' : 'ph:moon-duotone'} width={28} height={28} />
                </IconButton>
            </Grid>
        </Stack>
    );
}
