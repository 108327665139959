import React, { useContext, useEffect, useState } from "react";
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../../context/Auth/AuthContext";
// ----------------------------------------------------------------------


const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};


const ConfigUser = () => {

  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return ((
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          ) && whats.companyId == user.companyId);
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);


  const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'Menu',
      items: [
        { title: 'Dashboard', path: '/', icon: ICONS.dashboard },
        { title: 'Atendimento', path: '/tickets/', icon: ICONS.ecommerce },
        { title: 'Contatos', path: '/contacts', icon: ICONS.analytics },
      ],
    },
  ];


  if (user.profile === "admin") {
    navConfig.push({
      subheader: 'Administração',
      items: [
        {
          title: 'Conexão',
          path: '/connections',
          icon: ICONS.mail,
          info: (
            <Label variant="outlined" color="error">
              +32
            </Label>
          ),
        },
        { title: 'Usuários', path: '/users', icon: ICONS.chat },
        { title: 'Respostas rápidas', path: '/quickAnswers', icon: ICONS.calendar },
        {
          title: 'Empresas',
          path: '/company',
          icon: ICONS.kanban,
        },
      ],
    })
  }


  return navConfig;
}
export default ConfigUser;
