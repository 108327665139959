import React from "react";
import { isSameDay, parseISO, format } from "date-fns";
import Typography from "@mui/material/Typography";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Chip, Box } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import VideocamIcon from '@mui/icons-material/Videocam';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import AudioPlayerStyled from "../AudioPlayerStyled";


const STORAGE_CONVERSION = {
    BYTE: 1,
    KB: 1024,
    MB: 1024 ** 2,
    GB: 1024 ** 3,
    TB: 1024 ** 4
};

const ConverterBytes = (bytes) => {
    if (bytes < STORAGE_CONVERSION.KB) return `${bytes}B`;
    if (bytes < STORAGE_CONVERSION.MB) return `${(bytes / STORAGE_CONVERSION.KB).toFixed(2)}KB`;
    if (bytes < STORAGE_CONVERSION.GB) return `${(bytes / STORAGE_CONVERSION.MB).toFixed(2)}MB`;
    if (bytes < STORAGE_CONVERSION.TB) return `${(bytes / STORAGE_CONVERSION.GB).toFixed(2)}GB`;
    return `${(bytes / STORAGE_CONVERSION.TB).toFixed(2)}TB`;
};


const FileInfoModal = ({ open, onClose, data }) => {

    if (!data) return null;

    const file = {
        name: data.name,
        size: ConverterBytes(data.size),
        creationDate: data.createdTime,
        mimetype: data.mimeType,
        directLink: data.webContentLink,
        iframe: data.webViewLink
    };

    const getFileType = (mimetype) => {
        const type = mimetype.split('/')[0];
        const subtype = mimetype.split('/')[1];

        if (type === "audio") return { icon: <MusicNoteIcon />, label: "Audio" };
        if (type === "video") return { icon: <VideocamIcon />, label: "Video" };
        if (type === "image") return { icon: <ImageIcon />, label: "Image" };

        const knownDocuments = ["pdf", "ppt", "doc", "docx", "xls", "xlsx", "zip"];
        if (subtype === 'pdf') {
            return { icon: <DescriptionIcon />, label: "PDF" };
        }
        if (type === "application" && knownDocuments.includes(subtype)) {
            return { icon: <DescriptionIcon />, label: "Document" };
        }

        return { icon: <FolderIcon />, label: "File" };
    };

    const fileType = getFileType(file.mimetype);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="file-info-dialog"
            fullWidth
            maxWidth="sm"
            BackdropProps={{
                style: {
                    backdropFilter: 'blur(3px)',
                    backgroundColor: 'rgba(0, 0, 30, 0.4)',
                },
            }}
        >
            <DialogTitle id="file-info-dialog">File Information</DialogTitle>
            <DialogContent dividers>
                <Box display="flex" justifyContent="center" mb={3} alignItems="center" flexDirection="column">
                    {fileType.label === 'Audio' &&
                        <AudioPlayerStyled alt={file.name} src={file.directLink} />
                    }
                    {fileType.label === 'Image' &&
                        <img
                            src={file.directLink}
                            alt="File Thumbnail"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '480px',
                                width: 'auto',
                                height: 'auto',
                                display: 'block',
                                margin: 'auto',
                                borderRadius: '10px'
                            }}
                        />
                    }
                    {fileType.label === 'Video' &&
                        <video
                            src={file.directLink}
                            controls
                            style={{
                                maxWidth: '100%',
                                maxHeight: '480px',
                                width: 'auto',
                                height: 'auto',
                                display: 'block',
                                margin: 'auto'
                            }}
                        />
                    }
                    {fileType.label === 'PDF' &&
                        <iframe
                            src={file.iframe}
                            width="100%"
                            height="480px"
                            style={{
                                border: 'none',
                                display: 'block',
                                margin: 'auto'
                            }}
                        />
                    }
                </Box>
                <Grid container spacing={4} direction="column" alignItems="center">

                    {fileType.label !== 'Audio' && <>
                        <Grid item xs={12}>
                            <Typography variant="h4" color="primary" gutterBottom>{file.name}</Typography>
                        </Grid>
                    </>}

                    <Grid item container spacing={2} xs={12} md={10} direction="row">
                        <Grid item xs={4} align="center">
                            <Box mb={1}>
                                {fileType.icon}
                            </Box>
                            <Typography variant="body1" color="textSecondary">{fileType.label}</Typography>
                        </Grid>

                        <Grid item xs={4} align="center">
                            <Box mb={1}>
                                <FolderIcon />
                            </Box>
                            <Typography variant="body1" color="textSecondary">{file.size}</Typography>
                        </Grid>

                        <Grid item xs={4} align="center">
                            <Box mb={1}>
                                <FolderIcon />
                            </Box>
                            <Typography variant="body1" color="textSecondary">{format(parseISO(file.creationDate), "dd/MM/yyyy HH:mm")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>


            </DialogContent>
            <Box m={2}>
                <Button variant="contained" onClick={onClose} color="primary" fullWidth>
                    Fechar
                </Button>
            </Box>
        </Dialog>
    );
};

export default FileInfoModal;
