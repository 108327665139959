import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
} from "@mui/material";
import { i18n } from "../../translate/i18n";

const ConfirmationMultiCompaniesModal = ({ title, children, value, open, onClose, onConfirm }) => {


	let newValues = value;
	useEffect(() => {
		newValues = {
			...newValues,
			isMultiCompanies: true
		}
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>
				<Typography>{children}</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="error"
				>
					{i18n.t("messageOptionsMultiCompanies.cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						onClose(false);
						onConfirm(newValues);
					}}
					color="primary"
				>
					{i18n.t("messageOptionsMultiCompanies.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationMultiCompaniesModal;
