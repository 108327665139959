import { format, isSameDay, parseISO } from "date-fns";
import React, { useEffect, useReducer, useRef, useState } from "react";

import AccessTime from '@mui/icons-material/AccessTime';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Block from '@mui/icons-material/Block';
import CachedIcon from '@mui/icons-material/Cached';
import Done from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton
} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { green } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import ReactLoading from 'react-loading';
import { makeStyles } from 'tss-react/mui';
import GoogleDriveIcon from '../../assets/icons/google-drive.png';
import whatsBackgroundDark from "../../assets/wa-background-dark.png";
import whatsBackgroundLight from "../../assets/wa-background.png";
import GetFirstName from "../../helpers/GetFirstName";
import useSettings from '../../hooks/useSettings';
import socket from "../../hooks/useSocket";
import api from "../../services/api";
import ToastError from "../../toast/error/toastError";
import { i18n } from "../../translate/i18n";
import LightboxModal from '../LightboxModal';
import MarkdownWrapper from "../MarkdownWrapper";
import MessageOptionsMenu from "../MessageOptionsMenu";
import ModalImageCors from "../ModalImageCors";
import RenderIconFile from "../RenderIconFile";
import VcardArrayPreview from "../VcardArrayPreview";
import VcardPreview from "../VcardPreview";
import "./MessagesList.css";
import RenderAudioFile from "./RenderAudioFile";

function getUrlTicketPath() {
  var url = window.location.href;
  var partesDaURL = url.split('/');
  var codigo = partesDaURL[partesDaURL.length - 1];
  return codigo;
};

const useStyles = makeStyles()((theme) => {
  return {
    messagesListWrapper: {
      overflow: "hidden",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    messagesList: {
      backgroundSize: '370px',
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "20px 20px 20px 20px",
      overflowY: "scroll",
      '@media (max-width: 600px)': {
        paddingBottom: "90px",
      },
      ...theme.scrollbarStyles,
    },
    circleLoading: {
      position: "absolute",
      opacity: "70%",
      top: 0,
      left: "47%",
      marginTop: 12,
    },
    messageLeftGroup: {
      fontSize: '13px',
      marginLeft: 30,
      marginRight: 20,
      marginTop: 5,
      minWidth: 100,
      maxWidth: 510,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-start",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    messageLeft: {
      fontSize: '13px',
      marginRight: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 510,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-start",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    quotedContainerLeft: {
      margin: "-3px -80px 6px -6px",
      overflow: "hidden",
      backgroundColor: "#f0f0f0",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    fileContainerLeft: {
      overflow: "hidden",
      backgroundColor: "#f0f0f0",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    quotedMsg: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },
    filedMsg: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      display: "block",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
    },
    quotedSideColorLeft: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },
    filedSideColorLeft: {
      flex: "none",
      width: "4px",
      backgroundColor: "#6bcbef",
    },
    messageRight: {
      fontSize: '13px',
      marginLeft: 20,
      marginTop: 2,
      minWidth: 100,
      maxWidth: 510,
      height: "auto",
      display: "block",
      position: "relative",
      whiteSpace: "pre-wrap",
      alignSelf: "flex-end",
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 0,
    },
    quotedContainerRight: {
      margin: "-3px -80px 6px -6px",
      overflowY: "hidden",
      backgroundColor: "#cfe9ba",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    fileContainerRight: {
      overflowY: "hidden",
      backgroundColor: "#cfe9ba",
      borderRadius: "7.5px",
      display: "flex",
      position: "relative",
    },
    quotedMsgRight: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      whiteSpace: "pre-wrap",
    },
    fileMsgRight: {
      padding: 10,
      maxWidth: 300,
      height: "auto",
      whiteSpace: "pre-wrap",
    },
    quotedSideColorRight: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },
    fileSideColorRight: {
      flex: "none",
      width: "4px",
      backgroundColor: "#35cd96",
    },
    messageContactName: {
      display: "flex",
      color: "#6bcbef",
      fontWeight: 500,
    },
    textContentItem: {
      overflowWrap: "break-word",
      padding: "3px 80px 6px 6px",
    },
    textContentItemEdited: {
      overflowWrap: "break-word",
      padding: "6px 0px 0px 6px",
    },
    textContentItemVcard: {
      overflowWrap: "break-word",
      padding: "3px 6px 6px 6px",
    },
    textContentItemDeleted: {
      fontStyle: "italic",
      color: "rgba(0, 0, 0, 0.36)",
      overflowWrap: "break-word",
      padding: "3px 80px 6px 6px",
    },
    messageMedia: {
      objectFit: "cover",
      width: 250,
      height: 200,
      paddingBottom: '5px',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    timestamp: {
      fontSize: 11,
      position: "absolute",
      bottom: 0,
      right: 5,
      color: "#999",
    },
    timestampEdited: {
      fontSize: 11,
      bottom: 0,
      right: 5,
      color: "#999",
    },
    dailyTimestamp: {
      alignItems: "center",
      textAlign: "center",
      alignSelf: "center",
      width: "140px",
      margin: "10px",
      borderRadius: "10px",
    },
    dailyTimestampText: {
      borderRadius: '10px',
      padding: 3,
      alignSelf: "center",
      marginLeft: "0px",
    },
    ackIcons: {
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },
    deletedIcon: {
      fontSize: 18,
      verticalAlign: "middle",
      marginRight: 4,
    },
    ackDoneAllIcon: {
      color: green[500],
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },
    downloadMedia: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "inherit",
      padding: '0px 0px 15px 0px',
    },
    ticketNumber: {
      color: "#808888",
      padding: 8,
    },
    driveIcon: {
      display: 'none',
      width: '20px',
      position: 'absolute',
      marginTop: '-38px',
      right: '10px',
    }
  }
});

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state?.findIndex((m) => m?.id === message?.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {

    const { payload: newMessage } = action;

    const messageIndex = state?.findIndex((m) => m?.id === newMessage?.id);
    const messageIndexAwait = state?.findIndex((m) => m?.awaitSendUuid === newMessage?.awaitSendUuid && newMessage?.awaitSendUuid != null);

    if (newMessage?.contact?.isGroup) {
      newMessage.ack = 1;
    }

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else if (messageIndexAwait !== -1) {
      state[messageIndexAwait] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }


  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;

    const messageIndex = state.findIndex((m) => {
      return (m.id === messageToUpdate.id || (m.awaitSendUuid && m.awaitSendUuid === messageToUpdate.awaitSendUuid));
    });

    if (messageIndex !== -1) {
      if (state[messageIndex].isDeleted === false) {
        state[messageIndex] = messageToUpdate;
      }
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

function removeDuplicates(arr) {
  const seen = new Map();
  return arr.filter((item) => {
    const key = JSON.stringify(item);
    return seen.has(key) ? false : seen.set(key, true);
  });
}

function checkIfUuidExists(uuidToCheck, uuidArray) {
  return uuidArray.some(uuid => uuid === uuidToCheck);
}

const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const { classes } = useStyles();
  const { themeMode } = useSettings();
  const [messagesArray, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const lastMessageRef = useRef();
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const [contactUUIDS, setContactUUIDS] = useState([]);

  const urlTicketPath = getUrlTicketPath();

  // Filtrar e remover duplicatas
  const messagesList = removeDuplicates(
    messagesArray.filter((message) => {
      const isMatchingUuid = message.ticket.uuid === urlTicketPath;
      const uuidExistsInContact = checkIfUuidExists(message.ticket.uuid, contactUUIDS);
      return isMatchingUuid || uuidExistsInContact;
    })
  );

  const checkTheme = () => {
    var retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
    switch (themeMode) {
      case 'dark':
        retorno = { backgroundImage: `url("${whatsBackgroundDark}")` };
        break;
      case 'light':
        retorno = { backgroundImage: `url("${whatsBackgroundLight}")` };
        break;
    }
    return retorno
  }

  const isEdited = (message) => {
    if (message.isEdited) {
      if (message.user && message.user.name) {
        return (
          <Tooltip title={message.user.name}>
            <i>{i18n.t("messagesList.others.editedMessage")}&nbsp;{`(${GetFirstName(message.user.name)})`}&nbsp;&nbsp;</i>
          </Tooltip>
        );
      } else {
        return (
          <i>{i18n.t("messagesList.others.editedMessage")}&nbsp;&nbsp;</i>
        );
      }
    }
    return '';
  };


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [ticketId]);

  const [messagesMedia, setMessagesMedia] = useState([]);

  useEffect(() => {
    messagesList.forEach(msg => {
      const exist = messagesMedia.map(e => e?.id).indexOf(msg?.id);
      if (msg?.mediaType == 'imageMessage' || msg?.mediaType == 'image' && exist === -1) {
        setMessagesMedia(oldArray => [...oldArray, msg]);
      }
    });
  }, [messagesList]);


  useEffect(() => {
    setLoading(true);
    setDisableScroll(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        if (!ticketId) return; // Verificação de undefined usando ??
        if (typeof ticketId !== 'string' && typeof ticketId !== 'number') return; // Verificação de tipo
        setContactUUIDS([]);

        const { data } = await api.get(`/messages/${ticketId}`, {
          params: { pageNumber },
        });

        const { data: uuids } = await api.get(`/tickets_uuid/${ticket.contactId}`);
        setContactUUIDS(uuids)

        if (ticket?.id === ticketId) {
          dispatch({ type: 'LOAD_MESSAGES', payload: data.messages });
          setHasMore(data.hasMore);
        }

        if (pageNumber === 1) {
          scrollToBottom();
        }
      } catch (err) {
        ToastError(err);
      } finally {
        setLoading(false);
        setTimeout(() => setDisableScroll(false), 500); // Reativa o scroll após um curto atraso
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId, ticket]);


  useEffect(() => {

    const userCompany = ticket.companyId;

    if ((ticketId !== ticket?.id)) return;

    const handleConnect = () => {
      socket.emit("joinChatBox", `${ticket.id}`);
    };

    const handlePreMessage = (data) => {

      const uuidUrl = getUrlTicketPath();

      if (data?.uuid !== uuidUrl) return;

      const dateActual = new Date().toISOString();
      const message = {
        id: `await_${data?.messageUUID}`,
        ack: 0,
        body: data.body,
        fromMe: true,
        quotedMsg: data?.reqBody?.quotedMsg,
        timestamp: String(Date.now()),
        isDeleted: false,
        mediaName: null,
        mediaType: data?.mediaType ?? "extendedTextMessage",
        mediaUrl: data?.mediaUrl ?? null,
        participant: "",
        ticket: data.ticket,
        createdAt: dateActual,
        updatedAt: dateActual,
        awaitSendUuid: data?.messageUUID,
      };
      dispatch({ type: "ADD_MESSAGE", payload: message });
      scrollToBottom();
    };


    const handleAppMessage = (data) => {

      const uuidUrl = getUrlTicketPath();

      const { ticketId, ticket: { id: IdInTicket, uuid } = {}, action, message } = data || {};

      if (uuidUrl !== uuid) return;

      const isTicketIdValid = !!ticketId && ticketId === ticket?.id;

      const isIdInTicketValid = !!IdInTicket && IdInTicket === ticket?.id;

      if (isTicketIdValid || isIdInTicketValid) {
        if (action === "create") {
          dispatch({ type: "ADD_MESSAGE", payload: message });
          scrollToBottom();
        } else if (action === "update") {
          dispatch({ type: "UPDATE_MESSAGE", payload: message });
        };
      };

    };


    socket.on("connect", handleConnect);
    if (ticket?.id) {
      socket.on(`preMessage-${ticket.id}`, handlePreMessage)
    };

    socket.on(`appMessage-${userCompany}`, handleAppMessage);

    return () => {
      // Desconectar ouvintes socket (se necessário)
      // socket.off("connect");
      // socket.off(`preMessage-${ticket?.id}`);
      // socket.off(`appMessage-${userCompany}`);
    };

  }, [ticket]);


  const dateCalculate = {
    daysCount: (compare, now) => {
      const dataCompare = new Date(compare);
      const hoje = new Date(now);
      const umDia = 24 * 60 * 60 * 1000;
      const diferenca = Math.abs(hoje.getTime() - dataCompare.getTime());
      const dias = Math.floor(diferenca / umDia);
      return dias;
    },
    daysOfWeek: (data) => {
      const hoje = new Date();
      const umaSemana = 7 * 24 * 60 * 60 * 1000; // milissegundos em uma semana
      if (hoje.getTime() - data.getTime() > umaSemana) {
        return data.toLocaleDateString();
      } else {
        const diasDaSemana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
        const diaDaSemana = diasDaSemana[data.getDay()];
        return diaDaSemana;
      }
    },
    getDayOfMessage: (data) => {
      const compare = new Date(data);
      const diferencaEmDias = dateCalculate.daysCount(compare, new Date())
      if (diferencaEmDias == 0) return 'Hoje';
      if (diferencaEmDias == 1) return 'Ontem';
      if (diferencaEmDias > 1) return dateCalculate.daysOfWeek(compare);
    }
  }


  const scrollToBottom = () => {
    setTimeout(() => {
      var element = document.getElementById("messagesList");
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 1);
    // if (lastMessageRef.current) {
    //   lastMessageRef.current.scrollIntoView({});
    // }
  };

  const handleScroll = async (e) => {
    if (!hasMore || loading) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;


    if (scrollTop === 0) {

      const messagesList = document.getElementById("messagesList");
      const prevScrollTop = messagesList.scrollHeight - messagesList.clientHeight;
      messagesList.scrollTop = prevScrollTop > 0 ? prevScrollTop : 1;
    }

    if (scrollTop < 400) {
      setLoading(true);

      try {

        const scrollPositionBeforeLoad = scrollHeight - clientHeight - scrollTop;

        setPageNumber((prevPageNumber) => prevPageNumber + 1);

        const messagesList = document.getElementById("messagesList");
        messagesList.scrollTop = messagesList.scrollHeight - messagesList.clientHeight - scrollPositionBeforeLoad;

      } catch (error) {
        console.error("Erro ao carregar mais mensagens:", error);
      }
    }
  };

  const loadingMore = (e) => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleOpenLightbox = (blob, url, isDrive) => {
    if (isDrive) {
      const findUrl = messagesMedia.findIndex((message) => message.driveUrl === (url));
      setOpenLightbox(true);
      setSelectedImage(findUrl);
    } else {
      const findUrl = messagesMedia.findIndex((message) => message.mediaUrl.includes(url));
      setOpenLightbox(true);
      setSelectedImage(findUrl);
    }

  };

  const renderSvgType = (type) => {

    if (type === 'pdf') {
      return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
        </svg>
      </div>
    }
    else if (type === 'xlsx') {
      return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-filetype-xlsx" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z" />
        </svg>
      </div>
    }
    else if (type === 'docx') {
      return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-filetype-docx" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-6.839 9.688v-.522a1.54 1.54 0 0 0-.117-.641.861.861 0 0 0-.322-.387.862.862 0 0 0-.469-.129.868.868 0 0 0-.471.13.868.868 0 0 0-.32.386 1.54 1.54 0 0 0-.117.641v.522c0 .256.04.47.117.641a.868.868 0 0 0 .32.387.883.883 0 0 0 .471.126.877.877 0 0 0 .469-.126.861.861 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642Zm.803-.516v.513c0 .375-.068.7-.205.973a1.47 1.47 0 0 1-.589.627c-.254.144-.56.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.463 1.463 0 0 1-.589-.627 2.151 2.151 0 0 1-.205-.973v-.513c0-.379.069-.704.205-.975.137-.274.333-.483.59-.627.257-.147.564-.22.92-.22.357 0 .662.073.916.22.256.146.452.356.59.63.136.271.204.595.204.972ZM1 15.925v-3.999h1.459c.406 0 .741.078 1.005.235.264.156.46.382.589.68.13.296.196.655.196 1.074 0 .422-.065.784-.196 1.084-.131.301-.33.53-.595.689-.264.158-.597.237-.999.237H1Zm1.354-3.354H1.79v2.707h.563c.185 0 .346-.028.483-.082a.8.8 0 0 0 .334-.252c.088-.114.153-.254.196-.422a2.3 2.3 0 0 0 .068-.592c0-.3-.04-.552-.118-.753a.89.89 0 0 0-.354-.454c-.158-.102-.361-.152-.61-.152Zm6.756 1.116c0-.248.034-.46.103-.633a.868.868 0 0 1 .301-.398.814.814 0 0 1 .475-.138c.15 0 .283.032.398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.44 1.44 0 0 0-.49-.272 1.836 1.836 0 0 0-.606-.097c-.355 0-.66.074-.911.223-.25.148-.44.359-.571.633-.131.273-.197.6-.197.978v.498c0 .379.065.704.194.976.13.271.321.48.571.627.25.144.555.216.914.216.293 0 .555-.054.785-.164.23-.11.414-.26.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.699.699 0 0 1-.273.248.874.874 0 0 1-.401.088.845.845 0 0 1-.478-.131.834.834 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627v-.495Zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035l.823-1.439Z" />
        </svg>
      </div>
    }
    else if (type === 'zip') {
      return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-file-earmark-zip" viewBox="0 0 16 16">
          <path d="M5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.11 0l-.929-.62a1 1 0 0 1-.415-1.074L5 8.438V7.5zm2 0H6v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.929-.62-.4-1.598A1 1 0 0 1 7 8.438V7.5z" />
          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1h-2v1h-1v1h1v1h-1v1h1v1H6V5H5V4h1V3H5V2h1V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
        </svg>
      </div>
    } else {
      return <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-file-earmark-binary-fill" viewBox="0 0 16 16">
          <path d="M5.526 10.273c-.542 0-.832.563-.832 1.612 0 .088.003.173.006.252l1.559-1.143c-.126-.474-.375-.72-.733-.72zm-.732 2.508c.126.472.372.718.732.718.54 0 .83-.563.83-1.614 0-.085-.003-.17-.006-.25l-1.556 1.146z" />
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-2.45 8.385c0 1.415-.548 2.206-1.524 2.206C4.548 14.09 4 13.3 4 11.885c0-1.412.548-2.203 1.526-2.203.976 0 1.524.79 1.524 2.203zm3.805 1.52V14h-3v-.595h1.181V10.5h-.05l-1.136.747v-.688l1.19-.786h.69v3.633h1.125z" />
        </svg>
      </div>
    }
  }

  const getColorToChatQuoted = (fromMe, themeColor) => {
    let color = '';
    if (fromMe) {
      if (themeColor === 'dark') { color = '#025144' }
      else { color = '#cfe9ba' }
    } else {
      if (themeColor === 'dark') { color = '#1d282f' }
      else { color = '#f0f0f0' }
    }
    return `${color} !important`;
  }

  const getColorToChatFile = (fromMe, themeColor) => {
    let color = '';
    if (fromMe) {
      if (themeColor === 'dark') { color = '#fff' }
      else { color = '#212b36' }
    } else {
      if (themeColor === 'dark') { color = '#fff' }
      else { color = '#212b36' }
    }
    return `${color} !important`;
  }

  const renderFile = (message) => {
    const fileName = message.mediaName ? message.mediaName.split(".").pop() : message.mediaUrl.split("/").pop();
    const fileType = (fileName).split(".").pop();

    return (

      <Box component="div"
        sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode) }}
        className={clsx(classes.fileContainerLeft, {
          [classes.fileContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.fileSideColorLeft, {
            [classes.fileSideColorRight]: message?.fromMe,
          })}
        ></span>
        <Box component="div" className={classes.fileMsg} >
          <Box component="span">
            <div style={{ display: 'flex', width: '255px', padding: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{
                  <RenderIconFile type={fileType}
                    style={{
                      width: '45px',
                      height: '40px'
                    }}
                  />
                }</div>
                <div>
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '140px'
                    }}
                  >{message.body}</p>
                  <small>{fileType}</small>
                </div>
                <div>
                </div>
              </div>
              <div>
                <IconButton
                  sx={{ color: (theme) => getColorToChatFile(message.fromMe, theme.palette.mode) }}
                  variant="text"
                  target="_blank"
                  href={message?.isDrive ? message.driveUrl : message.mediaUrl}
                >
                  <DownloadForOfflineIcon />
                </IconButton >
              </div>
            </div>
          </Box>
        </Box>
      </Box >
    );
  };

  const checkMessageMedia = (message) => {
    if (message.mediaType === "locationMessage" || message.mediaType === 'liveLocationMessage') {
      const data = JSON.parse(message.mediaUrl.split("public/")[1])
      return <div>
        <iframe
          width="500"
          height="200"
          style={{ borderColor: '#ff000000' }}
          src={`https://maps.google.com/maps?q=${data.degreesLatitude},${data.degreesLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed `} />
      </div>;
    }
    if (message.mediaType == "image" || message.mediaType == "imageMessage") {
      return <div>
        <ModalImageCors dataMessage={message} isDrive={message?.isDrive} onOpenLightbox={handleOpenLightbox} />
        {message?.isDrive && <img className={classes.driveIcon} src={GoogleDriveIcon} />}
      </div>;
    }
    if (message.mediaType == "audio" || message.mediaType == "audioMessage") {
      return (
        <>
          <RenderAudioFile
            themeMode={themeMode}
            srcAudio={message?.isDrive ? message.driveUrl : message.mediaUrl}
            colors={themeMode === 'dark' ? '#202c33' : '#ffffff'}
            idDiv={Math.floor(Math.random() * 9999999999) + 1}
            avatarUser={message?.contact?.profilePicUrl}
          />
          {message?.isDrive && <img className={classes.driveIcon} src={GoogleDriveIcon} />}
        </>
      );
    }
    if (message.mediaType === "video" || message.mediaType === "videoMessage") {
      return (<>
        <video
          className={classes.messageMedia}
          src={message?.isDrive ? message.driveUrl : message.mediaUrl}
          controls
        />
        {message?.isDrive && <img className={classes.driveIcon} src={GoogleDriveIcon} />}
      </>
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <div>{renderFile(message)}</div>
          </div>
        </>
      );
    }
  };

  const renderMessageAck = (message) => {

    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <Box component="div" sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#fefefe', boxShadow: 2 }} className={classes.dailyTimestampText}>
            {/*dateCalculate.getDayOfMessage(format(parseISO(message?.createdAt), "yyyy-MM-dd"))*/}
            {(format(parseISO(message?.createdAt), "dd-MM-yyyy"))}
          </Box>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt || new Date());
      let previousMessageDay = parseISO(messagesList[index - 1]?.createdAt || new Date());

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <Box component="div" sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#fefefe', boxShadow: 2 }} className={classes.dailyTimestampText}>
              {/*dateCalculate.getDayOfMessage(format(parseISO(message?.createdAt), "yyyy-MM-dd"))*/}
              {(format(parseISO(message?.createdAt), "dd-MM-yyyy"))}
            </Box>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderNumberTicket = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageTicket = message.ticketId;
      let previousMessageTicket = messagesList[index - 1].ticketId;

      if (messageTicket !== previousMessageTicket) {
        return (
          <div key={`ticket-${message.id}`} className={classes.ticketNumber}>
            #ticket: {messageTicket}
            <hr />
          </div>
        );
      }
    }
  };

  const renderCallRejection = (message, index) => {

  };


  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = !!messagesList[index - 1]?.fromMe;
      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const getMessage = (id) => {
    var elmntToView = document.getElementById(id);
    if (elmntToView) {
      elmntToView.scrollIntoView({ behavior: "smooth" });
    }
  }


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 350,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


  const renderQuotedMessage = (message) => {
    return (
      <Box component="div"
        sx={{ backgroundColor: (theme) => getColorToChatQuoted(message.fromMe, theme.palette.mode) }}
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <Box component="div" className={classes.quotedMsg} onClick={e => getMessage(message.quotedMsgId)}>
          {!message.quotedMsg?.fromMe && (
            <Box component="span" className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </Box>
          )}
          <Box component="span"><MarkdownWrapper>{message.quotedMsg?.body}</MarkdownWrapper></Box>
        </Box>
      </Box>
    );
  };

  //const renderButtons = (data, divId) => {
  //  const buttonsArray = JSON.parse(data).message.buttonsMessage.buttons;
  //  return (<>
  //    <div style={{
  //      width: '490pxpx !important'
  //    }}>
  //      {buttonsArray.map((button) => <div>
  //        <Button style={{ width: '98%', margin: '5px 0px 0px 0px' }} disabled variant="contained">{button.buttonText.displayText}</Button>
  //      </div>)}
  //    </div>
  //  </>)
  //}

  const extractContact = (array) => {

    let contactNumber = "";
    let contactName = "";

    array.forEach(item => {
      const [key, value] = item.split(":");
      if (key.includes('TEL')) {
        contactNumber = value;
      }
      if (key === "FN") {
        contactName = value;
      }
    });

    return { contactName, contactNumber };
  }

  const renderContact = (message) => {
    let array = [];

    if (message.body.includes('VCARD')) {
      array = message.body.split("\n");
    } else if (message.dataJson) {
      const dataJson = JSON.parse(message.dataJson);
      array = dataJson?.message?.extendedTextMessage?.text.split("\n") || [];
    }

    let contactMessage;
    if (message.mediaType == "contactsArrayMessage") {
      const contactsArray = JSON.parse(message.body);
      contactMessage = <VcardArrayPreview contacts={contactsArray} />;
    } else {
      const { contactName, contactNumber } = extractContact(array);
      contactMessage = <VcardPreview contact={contactName} numbers={contactNumber} />;
    }
    return contactMessage;

  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (!message) return;
        let isContact = message?.mediaType == 'contactMessage' || message?.mediaType == 'contactsArrayMessage';
        if (!message?.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderMessageDivider(message, index)}
              {renderNumberTicket(message, index)}
              {renderDailyTimestamps(message, index)}
              <>
                {message.mediaType == "call_log" ?
                  <React.Fragment key={message.id}>
                    <Divider sx={{ padding: '15px 0px' }}>
                      <Chip label={message.body} size="small" />
                    </Divider>
                  </React.Fragment>
                  :
                  <>
                    {isGroup ? (
                      <>
                        <Box component="div" id={message.id} className={`${classes.messageLeftGroup} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
                          <div style={{ position: 'absolute', marginLeft: '-40px' }}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                      <Avatar sx={{ width: 80, height: 80 }} src={message.contact?.profilePicUrl} />
                                    </div>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                      <ListItem>
                                        <div>
                                          <ListItemText
                                            primary={message.contact?.name}
                                            secondary={
                                              <React.Fragment>
                                                <Typography
                                                  sx={{ display: 'inline' }}
                                                  component="span"
                                                  variant="body2"
                                                  color="text.primary"
                                                >
                                                  Criado em: &nbsp;
                                                </Typography>
                                                {format(parseISO(message.contact?.createdAt), "dd/MM/yyyy HH:mm")}
                                              </React.Fragment>
                                            }
                                          />
                                        </div>
                                      </ListItem>
                                      <Divider variant="inset" component="li" />
                                      <ListItem>
                                        <ListItemAvatar>
                                          <Avatar>
                                            <LocalPhoneIcon />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Número" secondary={message.contact?.number} />
                                      </ListItem>
                                      {message.contact?.email && <>
                                        <Divider variant="inset" component="li" />
                                        <ListItem>
                                          <ListItemAvatar>
                                            <Avatar>
                                              <EmailIcon />
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText primary="E-Mail" secondary={message.contact?.email} />
                                        </ListItem>
                                      </>}
                                    </List>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <Avatar
                                alt={message.contact?.name}
                                src={message.contact?.profilePicUrl}
                                sx={{ width: 30, height: 30 }}
                              />
                            </HtmlTooltip>
                          </div>
                          <div>
                            <IconButton
                              variant="contained"
                              size="small"
                              id="messageActionsButton"
                              disabled={message.isDeleted}
                              style={{
                                display: "flex",
                                position: "absolute",
                                top: -4,
                                right: 0,
                                color: "#999",
                                zIndex: 1,
                                opacity: "90%",
                              }}
                              onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                            >
                              <ArrowDropDownIcon />
                            </IconButton>
                            <div>
                              <span className={classes.messageContactName}>
                                {message.contact?.name}
                              </span>
                            </div>
                            {message.mediaUrl && checkMessageMedia(message)}
                            <div className={!isContact ? message.isEdited ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}>
                              {message.quotedMsg && renderQuotedMessage(message)}
                              {message.body.includes("VCARD") && renderContact(message)}
                              {!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" &&
                                <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>}
                              <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                                {isEdited(message)}{format(parseISO(message.createdAt), "HH:mm")}
                              </span>
                            </div>
                          </div>
                        </Box>
                        {/*message?.mediaType === "buttonsMessage" &&
                      <Box component="div" className={`${classes.messageLeftGroup} componentChat`} sx={{ backgroundColor: '#ffffff00', width: 490 }}>
                        {renderButtons(message?.dataJson, message.id)}
                      </Box>*/}
                      </>
                    ) : (
                      <>
                        <Box component="div" id={message.id} className={`${classes.messageLeft} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#202c33' : '#ffffff' }}>
                          <IconButton
                            variant="contained"
                            size="small"
                            id="messageActionsButton"
                            disabled={message.isDeleted}
                            style={{
                              display: "flex",
                              position: "absolute",
                              top: -4,
                              right: 0,
                              color: "#999",
                              zIndex: 1,
                              opacity: "90%",
                            }}
                            onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                          >
                            <ArrowDropDownIcon />
                          </IconButton>
                          {message.mediaUrl && checkMessageMedia(message)}
                          <div className={!isContact ? message.isEdited ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}>
                            {message.quotedMsg && renderQuotedMessage(message)}
                            {message.body.includes("VCARD") && renderContact(message)}
                            {!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" && <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>}

                            <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                              {isEdited(message)}{format(parseISO(message.createdAt), "HH:mm")}
                            </span>
                          </div>
                        </Box>
                        {/*message?.mediaType === "buttonsMessage" &&
                      <Box component="div" className={`${classes.messageLeft} componentChat`} sx={{ backgroundColor: '#ffffff00', width: 490 }}>
                        {renderButtons(message?.dataJson, message.id)}
                      </Box>*/}
                      </>)
                    }
                  </>}
              </>

            </React.Fragment >
          );
        } else {
          return (
            <React.Fragment key={message.id} >
              {renderMessageDivider(message, index)}
              {renderDailyTimestamps(message, index)}
              {message.mediaType === "notepad" ?
                <>
                  <Box component="div" id={message.id} className={`${classes.messageRight} componentChat`} sx={{ fontStyle: 'italic', backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#e5e5e5' : '#feffd3' }}>
                    <div
                      className={!isContact ? message.isEdited ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}
                    >
                      <Box component="div" sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#3c3c3c' : '#212b36' }}>
                        <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>
                      </Box>
                      <span className={message.isEdited ? classes.timestampEdited : classes.timestamp}>
                        {isEdited(message)}{format(parseISO(message.createdAt), "HH:mm")}
                        <NoteAltIcon style={{ margin: '-5px 5px', width: 20 }} />
                      </span>
                    </div>
                  </Box>
                </>
                :
                <>
                  <Box component="div" id={message.id} className={`${classes.messageRight} componentChat`} sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#005c4b' : '#d9fdd3' }}>
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      style={{
                        display: "flex",
                        position: "absolute",
                        top: -4,
                        right: 0,
                        color: "#999",
                        zIndex: 1,
                        opacity: "90%",
                      }}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                    {message.mediaUrl && checkMessageMedia(message)}
                    <div
                      className={!isContact ? message.isEdited || message?.isSendInApi ? classes.textContentItemEdited : classes.textContentItem : classes.textContentItemVcard}
                    >
                      {message.isDeleted && (
                        <Block
                          color="disabled"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      )}
                      {message.quotedMsg && renderQuotedMessage(message)}
                      {
                        (message?.body?.includes("VCARD") || Array.isArray(message?.dataJson) && message?.dataJson?.includes('VCARD')) && renderContact(message)
                      }
                      {!message?.mediaUrl?.includes(message.body) && !message.body?.includes("VCARD") && message.mediaType !== "audio" && message.mediaType !== "audioMessage" && !message.mediaUrl && <p><MarkdownWrapper>{message.body}</MarkdownWrapper></p>}
                      <span className={message.isEdited || message?.isSendInApi ? classes.timestampEdited : classes.timestamp}>
                        {isEdited(message)}
                        {message?.isSendInApi && 'Via API - '}
                        {format(parseISO(message.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                  </Box>
                </>
              }
            </React.Fragment >
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (
    <>
      <LightboxModal
        id="messageMediaModal"
        images={messagesMedia}
        mainSrc={messagesMedia[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
      <div className={classes.messagesListWrapper}>
        <MessageOptionsMenu
          message={selectedMessage}
          anchorEl={anchorEl}
          menuOpen={messageOptionsMenuOpen}
          handleClose={handleCloseMessageOptionsMenu}
        />
        <div
          id="messagesList"
          className={classes.messagesList}
          style={checkTheme()}
        // onScroll={handleScroll}
        >
          {messagesList.length > 0 && hasMore && !loading ? <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={() => { loadingMore() }}
                sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#212b36' : '#ffffff' }}
                color="secondary">
                <CachedIcon />
              </IconButton>
            </Box>
          </> : ''}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                as={ReactLoading}
                sx={{ fill: (theme) => `${theme.palette.primary.main} !important` }}
                type={'spin'}
                style={{
                  height: '40px',
                  width: '40px'
                }} />
            </Box>
          )}
          {messagesList.length > 0 ? renderMessages() : []}
        </div>
      </div>
    </>
  );
};

export default MessagesList;
