import React, { useContext, useEffect, useReducer, useState } from "react";

import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal/";
import ContactModal from "../../components/ContactModal";
import NewTicketModal from "../../components/NewTicketModal/index.js";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
// @mui
import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    Container,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useSettings from '../../hooks/useSettings';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import FacebookIcon from '@mui/icons-material/Facebook';
import ForumIcon from '@mui/icons-material/Forum';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// sections
import FormatarTelefone from "../../helpers/FormatarTelefone";
import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";
import socket from "../../hooks/useSocket";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'number', label: 'WhatsApp', alignRight: false },
  { id: 'role', label: 'Email', alignRight: false },
  { id: 'Actions', label: 'Ações', alignRight: false },
];


// ----------------------------------------------------------------------

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const renderChannel = (channel) => {
  let icon = '';

  switch (channel) {
    case 'whatsapp': icon = <WhatsAppIcon style={{ color: '#0dc242' }} />; break;
    case 'telegram': icon = <TelegramIcon style={{ color: '#3390ec' }} />; break;
    case 'instagram': icon = <InstagramIcon style={{ color: '#fe0079' }} />; break;
    case 'messenger': icon = <FacebookIcon style={{ color: '#0573e7' }} />; break;
    case 'webchat': icon = <ForumIcon style={{ color: '#0573e7' }} />; break;
  }

  return icon;
}

const filterContacts = (contacts, isFilterBlacklist) => {
  if (isFilterBlacklist) {
    return contacts.filter(contact => contact.isBlocked)
  }
  return contacts
}

const Contacts = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({})
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const [contacts, dispatch] = useReducer(reducer, []);

  const [isFilterBlacklist, setIsFilterBlacklist] = useState(false);
  const filteredContacts = filterContacts(contacts, isFilterBlacklist);

  // ----------------------------------------------------------------------
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;

  const filteredUsers = applySortFilter(contacts, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, limit: rowsPerPage, pageNumber: page + 1 },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setCount(data.count)
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          ToastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, rowsPerPage, page]);

  useEffect(() => {
    
socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        if (!(user.companyId === data.contact.companyId)) return;
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.off("contact");
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };


  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleBlockContact = async (contactId, isBlocked, name, number) => {
    try {
      await api.put(`/contacts/${contactId}`, {
        isBlocked,
        name,
        number,
      })
    } catch (err) {
      ToastError(err);
    }
  }

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      ToastSuccess(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      ToastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      ToastError(err);
    }
  };

  const firstWordInCapsLock = text => {
    if (!text) return "";
    return text[0].toUpperCase() + text.substring(1)
  };

  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <NewTicketModal
          modalOpen={newTicketModalOpen}
          initialContact={contactTicket}
          onClose={(ticket) => {
            handleCloseOrOpenTicket(ticket);
          }}
        />
        <ContactModal
          open={contactModalOpen}
          onClose={handleCloseContactModal}
          aria-labelledby="form-dialog-title"
          contactId={selectedContactId}
        />
        <ConfirmationModal
          title={
            deletingContact
              ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
              }?`
              : `${i18n.t("contacts.confirmationModal.importTitlte")}`
          }
          open={confirmOpen}
          onClose={setConfirmOpen}
          onConfirm={(e) =>
            deletingContact
              ? handleDeleteContact(deletingContact.id)
              : handleimportContact()
          }
        >
          {deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
            : `${i18n.t("contacts.confirmationModal.importMessage")}`}
        </ConfirmationModal>
        <Page title={i18n.t("mainDrawer.listItems.contacts")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("mainDrawer.listItems.contacts")}
              action={
                <>
                  <Can
                    role={user.profile}
                    perform="contacts-page:importContact"
                    yes={() => (
                      <Button
                        variant="contained"
                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                        onClick={(e) => setConfirmOpen(true)}
                      >
                        {i18n.t("contacts.buttons.import")}
                      </Button>
                    )}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={handleOpenContactModal}
                  >
                    {i18n.t("contacts.buttons.add")}
                  </Button>
                </>
              }
            />

            <Card>
              <Box component="div" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
                <TextField
                  type="search"
                  placeholder={i18n.t("userType.translate.searchContacts")}
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={!isFilterBlacklist ? <Iconify icon={'eva:options-2-fill'} /> : <Iconify icon={'eva:close-fill'} />}
                  onClick={() => setIsFilterBlacklist(prevState => !prevState)}
                >
                  {!isFilterBlacklist ? i18n.t("contacts.buttons.filterBlocked") : i18n.t("contacts.buttons.removeFilter")}
                </Button>
              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">{i18n.t("connections.table.name")}</TableCell>
                        <TableCell align="center">{i18n.t("userType.translate.nameWhatsapp")}</TableCell>
                        <TableCell align="left">{i18n.t("userType.translate.number")}</TableCell>
                        <TableCell align="left">{i18n.t("userType.translate.email")}</TableCell>
                        <TableCell align="center">{i18n.t("userType.translate.action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredContacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, name, number, email, profilePicUrl, telegramId, isBlocked, whatsappName } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                              <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={<Tooltip title={telegramId ? firstWordInCapsLock('telegram') : firstWordInCapsLock('whatsapp')} placement="right">{telegramId ? renderChannel('telegram') : renderChannel('whatsapp')}</Tooltip>}
                              >
                                <Avatar alt={name} src={profilePicUrl} sx={{ marginRight: '5px' }} />
                              </Badge>

                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">{whatsappName}</TableCell>
                            <TableCell align="left">{number.length > 13 ? 'Grupo' : FormatarTelefone(number)}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setContactTicket(row)
                                  setNewTicketModalOpen(true)
                                }}
                              >
                                <WhatsAppIcon />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => hadleEditContact(id)}
                              >
                                <EditIcon />
                              </IconButton>
                              <Tooltip title={i18n.t("contacts.buttons.block")}>
                                <IconButton
                                  size="small"
                                  onClick={() => handleBlockContact(id, !isBlocked, name, number)}
                                  color={!!isBlocked ? 'error' : 'default'}
                                  title={i18n.t("contacts.buttons.block")}
                                >
                                  <BlockIcon />
                                </IconButton>
                              </Tooltip>
                              <Can
                                role={user.profile}
                                perform="contacts-page:deleteContact"
                                yes={() => (
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      setConfirmOpen(true);
                                      setDeletingContact(row);
                                    }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {loading && page < 1 && (
                      <TableBody>
                        <TableRowSkeleton avatar columns={2} />
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")}: ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};


export default Contacts;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

