// import { useEffect } from 'react';
// @mui
// import { Card, Container } from '@mui/material';
// import { getConversations, getContacts } from '../../redux/slices/chat';
// hooks
// import useSettings from '../../hooks/useSettings';
// components
// import Page from '../../components/Page';
// import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import { ChatSidebar, ChatWindow } from '../../sections/@dashboard/chat';

// ----------------------------------------------------------------------

export default function Chat() {



    return null;

    {/*
  const { themeStretch } = useSettings();

    const renderData = () => {
        const conversa = getConversations();
        const contatos = getContacts();
        console.info({ conversa, contatos });
    };

    useEffect(() => {
        renderData()
    }, [])

    return (
        <Page title="Chat">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <HeaderBreadcrumbs
                    heading="Chat"
                    links={[]}
                />
                <Card sx={{ height: '72vh', display: 'flex' }}>
                    <ChatSidebar />
                    <ChatWindow />
                </Card>
            </Container>
        </Page>
    );
*/}
}
