import React from "react";
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { makeStyles } from 'tss-react/mui';
import useSettings from '../../hooks/useSettings';
import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";
import Page from '../../components/Page';
import { i18n } from "../../translate/i18n";
import Hidden from "@mui/material/Hidden";
import { Container, Grid, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { ReactComponent as UniChatLight } from "../../assets/icons/UniChatLight.svg";
import { ReactComponent as UniChatDark } from "../../assets/icons/UniChatDark.svg";


const useStyles = makeStyles()((theme) => {
  return {
    contactsWrapper: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflowY: "hidden",
    },
    contactsWrapperSmall: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      overflowY: "hidden",
      '@media (max-width: 600px)': {
        display: "none",
      },
    },
    messagessWrapper: {
      display: "flex",
      height: "100%",
      '@media (max-width: 900px)': {
        display: "none",
      },
      '@media (max-width: 600px)': {
        display: "flex",
      },
      '@media (min-width: 900px)': {
        maxWidth: '100%',
        width: '100%',
        borderRadius: 5
      },
      '@media (min-width: 960px)': {
        maxWidth: '100%',
        width: '100%',
        borderRadius: 5
      },
      flexDirection: "column",
    },
    mobileLayout: {
      '@media (max-width: 1200px)': {
        padding: "0px !important",
      },
    },
    welcomeMsg: {
      flexDirection: 'column',
      display: "flex",
      width: '100%',
      justifyContent: "center",
      alignItems: "center",
      height: 'calc(100vh - 80px)',
      textAlign: "center",
      borderRadius: 0,
    },
    ticketsManager: {},
    ticketsManagerClosed: {
      '@media (max-width: 600px)': {
        display: "none",
      },
    },
  }
});

const Chat = () => {

  const { classes } = useStyles();

  const { ticketId } = useParams();

  const theme = useTheme();
  
  return (
    <div style={{ marginTop: '10px' }}>
      <Page title={i18n.t("mainDrawer.listItems.tickets")}>
        <Container spacing={3} maxWidth={false} className={classes.mobileLayout}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              style={{}}
              className={
                ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
              }
            >
              <TicketsManager />
            </Grid>
            <Grid item xs={12} md={8} className={classes.messagessWrapper}>
              {ticketId ? (
                <>
                  <Ticket />
                </>
              ) : (
                <Hidden only={["sm", "xs"]}>
                  <Paper className={classes.welcomeMsg}>
                    <div >
                      {
                        theme.palette.mode === "light" ?
                          <UniChatLight style={{ width: '100%', width: '400px', height: '400px' }} />
                          :
                          <UniChatDark style={{ width: '100%', width: '400px', height: '400px' }} />
                      }
                    </div>
                    <div>
                      <span style={{
                        fontFamily: 'inherit',
                        fontSize: '20px'
                      }}>{i18n.t("chat.noTicketMessage")}</span>
                    </div>
                  </Paper>
                </Hidden>
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </div>
  );
};

export default Chat;
