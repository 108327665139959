import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Typography,
	TextField,
	FormControl,
	Box,
	Divider,
	IconButton,
	Tooltip
} from "@mui/material";
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import LinkIcon from '@mui/icons-material/Link';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { toast } from "react-toastify";
import axios from "axios";
import { i18n } from "../../translate/i18n";
import ToastError from "../../toast/error/toastError";

const CustomTextField = styled(TextField)(({ theme }) => ({
	"& .MuiInputBase-root": {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.background.paper,
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:hover': {
			boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
		},
		'&.Mui-focused': {
			backgroundColor: theme.palette.background.paper,
			boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
		}
	},
	width: '75%'
}));

const SendLocationModal = ({ title, open, onClose, onConfirm }) => {
	const [value, setValue] = useState("");
	const [valueLink, setValueLink] = useState("");
	const [loading, setLoading] = useState(false);
	const [position, setPosition] = useState({});

	const getAddress = async () => {
		if (value.length < 10) {
			return ToastError('Informações faltantes para a busca de endereço.');
		}

		try {
			setLoading(true);
			const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDfFjk6M0sRZd67OvS3B0zoK8Ca4zF0F2U&address=${encodeURI(value)}`);
			const data = response.data;
			setPosition(data.results[0].geometry.location);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const getAddressLink = () => {
		const match = valueLink.match(/@(-?\d+\.\d+),(-?\d+\.\d+)/);
		const lat = match[1];
		const lng = match[2];
		setPosition({ lat, lng });
		setLoading(false);
	};

	const sendLocationMessage = () => {
		if (!position.lat || !position.lng) {
			return ToastError('Defina um endereço para ser enviado.');
		}
		onClose(false);
		onConfirm(position);
	};

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle id="confirm-dialog">
				<LocationOnIcon color="primary" style={{ marginRight: '8px' }} />
				{title}
			</DialogTitle>
			<Box sx={{ boxShadow: 1, margin: '15px' }}>
				<Divider />
				<FormControl variant="standard" dividers>
					<Box display="flex" alignItems="center" justifyContent="space-between" py={1} width={445}>
						<CustomTextField
							variant="outlined"
							label="Buscar endereço no Google Maps"
							onChange={e => setValue(e.target.value)}
							size="small"
							startAdornment={<SearchIcon />}
						/>
						<Tooltip title="Buscar endereço">
							<IconButton onClick={getAddress} color="primary">
								<SearchIcon />
							</IconButton>
						</Tooltip>
					</Box>
					<Typography component="p" textAlign="center" variant="overline">OU</Typography>
					<Box display="flex" alignItems="center" justifyContent="space-between" py={1} width={445}>
						<CustomTextField
							variant="outlined"
							label="Link de localização no Google Maps"
							onChange={e => setValueLink(e.target.value)}
							size="small"
							startAdornment={<LinkIcon />}
						/>
						<Tooltip title="Verificar link">
							<IconButton onClick={getAddressLink} color="primary">
								<LinkIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</FormControl>
				<Divider />
				<DialogContent dividers>
					{loading ? (
						<Box textAlign="center">
							<Typography component="p">Buscando Informações, aguarde...</Typography>
							<LinearProgress />
						</Box>
					) : (
						<iframe
							width="400"
							height="200"
							style={{ borderColor: '#ff000000', borderRadius: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
							src={`https://maps.google.com/maps?q=${position.lat},${position.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed `}
						/>
					)}
				</DialogContent>
			</Box>
			<DialogActions>
				<Button variant="contained" onClick={() => onClose(false)} color="error">
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<Button variant="contained" onClick={sendLocationMessage} color="primary" startIcon={<LocationOnIcon />}>
					Enviar Localização
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SendLocationModal;
