
import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography } from '@mui/material';
// hooks
import useSound from "use-sound";
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../config';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import { IconButtonAnimate } from '../../components/animate';
import { AuthContext } from "../../context/Auth/AuthContext";

import AccountPopover from './AccountPopover';

import NotificationsPopOver from "../../components/NotificationsPopOver";
import NewsNotifications from "../../components/NewsNotifications";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

import alertSound from "../../assets/InternalChat.mp3";

import InternalChat from '../../components/InternalChat';

import MarkdownWrapper from "../../components/MarkdownWrapper";

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { Backdrop, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import toastError from "../../errors/toastError";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import ChangeMode from "./ChangeMode";
import ToastError from "../../toast/error/toastError";

// ----------------------------------------------------------------------
function temaDefinido(theme) {
  if (theme === "#fff") {
    document.body.style.backgroundColor = "#f4f4f480";
  } else {
    document.body.style.backgroundColor = "#161C24";
  }
}
const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({

  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT - 25,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
    ...temaDefinido(theme.palette.background.default)
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const { user } = useContext(AuthContext);
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const smUp = useResponsive('up', 'sm');
  const [loadingChangeCompany, setLoadingChangeCompany] = useState(false);
  const [company, setCompany] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    if (!user?.id) return;
    const loadingCompany = async () => {
      try {
        const { data } = await api.get(`/company/${user.companyId}`);
        setCompany(data);
      } catch (err) {
        console.info(err)
      }
    };
    loadingCompany();
  }, [user]);

  useEffect(() => {
    if (!user?.id) return;
    setLoadingCompanies(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get(`/multi-companies/${user.id}`);
          setCompanies(data)
          setTimeout(() => setLoadingCompanies(false), 1000);
        } catch (err) {
          console.info(err)
          setLoadingCompanies(false);
          ToastError(err);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [user]);

  const changeCompanyUser = (companyId) => {
    setLoadingChangeCompany(true)
    let getBody = document.body
    getBody.style.setProperty('overflow', 'hidden');
    const fetchUsers = async () => {
      try {
        const { data } = await api.post(`/multi-companies/${user.id}`, { companyId });
        localStorage.setItem("token", JSON.stringify(data.token));
        api.defaults.headers.Authorization = `Bearer ${data.token}`
        getBody.style.setProperty('overflow', 'unset');
        setTimeout(() => setLoadingChangeCompany(false), 1000);
        window.location.reload();
      } catch (err) {
        getBody.style.setProperty('overflow', 'unset');
        setLoadingChangeCompany(false);
        ToastError(err);
      }
    };
    fetchUsers();
  }

  const isDesktop = useResponsive('up', 'lg');
  const [play] = useSound(alertSound);
  const soundAlertRef = useRef();
  soundAlertRef.current = play;

  const renderTrialTime = (date) => {
    const currentDate = new Date();
    const targetDate = new Date(date);
    const timeDifferenceInMilliseconds = targetDate - currentDate;
    const timeDifferenceInDays = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

    console.info(timeDifferenceInDays);

    if (timeDifferenceInDays < 1) {
      return "*Último dia de teste*";
    } else {
      const remainingDays = Math.ceil(timeDifferenceInDays);
      const string = remainingDays > 1 ? "Dias" : "Dia";
      return `Avaliação gratuita *${remainingDays} ${string}* restantes`;
    }
  };

  const openWhatsApp = () => {
    const whatsappLink = "https://api.whatsapp.com/send?phone=556391000057&text=Quero%20uma%20assinatura%20do%20*UniChat*!";
    window.open(whatsappLink, "_blank");
  };

  const renderName = (company, user) => {
    return `${company.name} ${+company?.id === +user?.companyId ? '(Selecionada)' : ''}`
  }

  return (
    <>
      <Backdrop
        sx={{ backgroundColor: '#fff0', color: '#fff', zIndex: 9999999 }}
        open={loadingChangeCompany}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
          <CircularProgress color="inherit" />
          <p> {`${i18n.t("multiCompanies.changeCompany")}`}</p>
        </div>
      </Backdrop>
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
        <Toolbar
          sx={{
            minHeight: '100% !important',
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
          {!isDesktop && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}
          {company?.name
            ?
            <Typography variant="subtitle2" noWrap sx={{ color: (theme) => theme.palette.mode == 'dark' ? '#fff' : '#000' }}>
              {company.name}
            </Typography>
            :
            null}
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {company?.isTrial && (
              <Alert
                sx={{ padding: '0px 16px' }}
                severity="warning"
                action={
                  <Button color="inherit" onClick={() => openWhatsApp()} size="small">
                    Contratar Plano
                  </Button>
                }
              >
                <MarkdownWrapper>{renderTrialTime(company.endTrial)}</MarkdownWrapper>
              </Alert>
            )}
            <ChangeMode />
            {user.profile !== 'adminSuper' && <>
              <div style={{ display: 'flex' }} >
                {companies.length > 0 && <div>
                  <ButtonWithSpinner
                    style={{ margin: '10px' }}
                    loading={loadingCompanies}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    variant="text"
                    aria-expanded={open ? 'true' : undefined} F
                    onClick={e => handleClick(e)}
                  >
                    {`${i18n.t("multiCompanies.company")}`}
                  </ButtonWithSpinner>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {companies.map((company, index) => (
                      <MenuItem onClick={e => changeCompanyUser(company.id)}>{`${renderName(company, user)}`}</MenuItem>
                    ))}
                  </Menu>
                </div>}
                {user.id && <NotificationsPopOver />}
              </div>
              {/*smUp && <InternalChat />*/}
            </>}
            {/*user.id && <NewsNotifications />*/}
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>
    </>
  );
}
