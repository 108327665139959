import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Avatar, Divider, Typography, Stack } from '@mui/material';
// utils
import cssStyles from '../../../../utils/cssStyles';
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components/Image';
import SocialsButton from '../../../../components/SocialsButton';
import SvgIconStyle from '../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
  top: 0,
  zIndex: 8,
  content: "''",
  width: '100%',
  height: '100%',
  position: 'absolute',
}));

// ----------------------------------------------------------------------

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function UserCard({ user }) {

  const { name, email, position, media, totalTickets, totalTicketsPresence } = user;
  
  return (
    <Card sx={{ textAlign: 'center' }}>
      <Box sx={{ position: 'relative' }}>

        <Avatar
          alt={name}
          src={media}
          sx={{
            width: '55%',
            height: '100%',
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
          }}
        />
        <Image sx={{ backgroundColor: +totalTickets > 0 ? totalTicketsPresence > 0 ? '#fff253f7' : '#ff5353' : '#53ff5b' }} ratio="16/9" />
      </Box>
      <Typography variant="subtitle1" sx={{ mt: 6 }}>
        {name}
      </Typography>
      <Divider sx={{ mt: 2, borderStyle: 'dashed' }} />
      <Box sx={{ py: 1, display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
        <div>
          <Typography variant="caption" component="div" sx={{ fontSize: '15px', mb: 0.75, color: 'text.disabled' }}>Atendendo</Typography>
          <Typography variant="subtitle1">{fShortenNumber(totalTickets)}</Typography>
          {totalTicketsPresence > 0 && <><Typography variant="caption" component="div" sx={{ fontSize: '15px', mb: 0.75, color: 'text.disabled' }}>Presencial</Typography>
            <Typography variant="subtitle1">{fShortenNumber(totalTicketsPresence)}</Typography>
          </>}
        </div>
      </Box>
    </Card>
  );
}
