import React, { useState, useContext, useEffect } from "react";
import api from "../../services/api";
// @mui
import { alpha, styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import UserModal from "../../components/UserModal";

import avatarPng from "../../assets/Profile-Avatar.png";
import Slider from '@mui/material/Slider';
import ListSubheader from "@mui/material/ListSubheader";
// ----------------------------------------------------------------------

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AudiotrackTwoToneIcon from '@mui/icons-material/AudiotrackTwoTone';

import AvatarPresence from "../../components/AvatarPresence";
import ChangeNotificationsModal from "../../components/ChangeNotificationsModal";


export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [soundModalOpen, setSoundModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, handleUpdateUser, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user, status } = useContext(AuthContext);

  const [value, setValue] = useState(localStorage.getItem("volume") ? Number(localStorage.getItem("volume")) : 50);

  useEffect(() => {
    localStorage.setItem("globalNotification", user.globalNotification);
    localStorage.setItem("userNotification", user.userNotification);
  }, [user]);

  const handleChange = (event, newValue) => {
    localStorage.setItem("volume", newValue);
    setValue(newValue);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickLogout = () => {
    handleClose();
    handleLogout();
  };

  const changeNotifications = async ({ selectedAudioGlobal, selectedAudioMy }) => {
    try {
      await api.put(`/users/${user.id}`, {
        globalNotification: selectedAudioGlobal,
        userNotification: selectedAudioMy
      });
      handleUpdateUser({
        ...user,
        globalNotification: selectedAudioGlobal,
        userNotification: selectedAudioMy
      });

      localStorage.setItem("globalNotification", selectedAudioGlobal);
      localStorage.setItem("userNotification", selectedAudioMy);

    } catch (err) {
      console.error(err)
    } finally {
      setSoundModalOpen(false)
    }
  };


  const handleOpenUserModal = () => {
    handleClose();
    setUserModalOpen(true);
  };

  const handleOpenNotificationsModal = () => {
    handleClose();
    setSoundModalOpen(true);
  };

  return (
    <>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <ChangeNotificationsModal
        open={soundModalOpen}
        onClose={setSoundModalOpen}
        globalNotification={user.globalNotification}
        userNotification={user.userNotification}
        onConfirm={(e) => changeNotifications(e)}
      >
        {i18n.t("chatbot.confirmationModal.deleteMessage")}
      </ChangeNotificationsModal>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <AvatarPresence
          id="Status"
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          status={status}
        >
          <Avatar alt={user.name} src={user.driveUrl ?? user.media} />
        </AvatarPresence>
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }} onClick={handleOpenNotificationsModal}>
          <p>Sons</p> <AudiotrackTwoToneIcon />
        </MenuItem>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Slider aria-label="Volume" value={value} onChange={handleChange} />
            {<>
              {value === 0 ?
                <VolumeOffIcon onClick={e => handleChange(e, 100)} /> :
                value > 0 && value < 50 ?
                  <VolumeDown /> :
                  value > 50 && value < 100 ?
                    <VolumeUp /> :
                    <VolumeUp sx={{ color: 'red' }}
                      onClick={e => handleChange(e, 0)}
                    />}
            </>}
          </Stack>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }} onClick={handleOpenUserModal}>
          <p>{i18n.t("mainDrawer.appBar.user.profile")}</p> <PersonSearchIcon />
        </MenuItem>
        <MenuItem sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }} onClick={handleClickLogout}>
          <p>{i18n.t("mainDrawer.appBar.user.logout")}</p>  <ExitToAppIcon />
        </MenuItem>
      </MenuPopover>
    </>
  );
}
