import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import { MenuItem, Chip, Box } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ToastError from "../../toast/error/toastError";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const TagsSelect = ({ selectedTagsIds, onChange }) => {
	const [tags, setTags] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/tags");
				setTags(data);
			} catch (err) {
				ToastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		const {
			target: { value },
		} = e;
		onChange(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<div>
			<FormControl style={{ margin: 0 }} fullWidth sx={{ width: '100%' }} margin="dense" size="small" variant="outlined">
				<InputLabel id="tags-selection-input-label">Tags</InputLabel>
				<Select
					labelId="tags-selection-input-label"
					id="tags-selection-checkbox"
					multiple
					value={selectedTagsIds}
					onChange={handleChange}
					input={<OutlinedInput label="Tags" />}
					renderValue={selected => (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selected?.length > 0 &&
								selected.map(id => {
									const tag = tags.find(q => q.id === id);
									return tag ? (
										<Chip
											sx={{
												borderRadius: '3px',
												height: '20px',
												margin: '0px 3px',
												"& .MuiChip-label": {
													paddingLeft: '5px',
													paddingRight: '5px'
												}
											}}
											key={id}
											style={{ backgroundColor: tag.color }}
											variant="outlined"
											label={tag.name}

										/>
									) : null;
								})}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					{tags.map((tag, i) => (
						<MenuItem key={tag.id} value={tag.id}>
							<Checkbox checked={selectedTagsIds.indexOf(tag.id) > -1} />
							<Chip
								sx={{
									borderRadius: '3px',
									height: '20px',
									margin: '0px 3px',
									"& .MuiChip-label": {
										paddingLeft: '5px',
										paddingRight: '5px'
									}
								}}
								key={i}
								style={{ backgroundColor: tag.color }}
								variant="outlined"
								label={tag.name}

							/>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TagsSelect;
