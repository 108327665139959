import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from 'tss-react/mui';

import {
	FormControl,
	InputAdornment,
	CircularProgress,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	TextField,
	Button,
	IconButton
} from "@mui/material";

import { green } from "@mui/material/colors";

import { i18n } from "../../translate/i18n";
import ConfirmationMultiCompaniesModal from "../ConfirmationMultiCompaniesModal";
import api from "../../services/api";
import ColorPicker from "../ColorPicker";

import Colorize from '@mui/icons-material/Colorize';
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";
import ToastSuccess from "../../toast/success/toastSuccess";
import ToastError from "../../toast/error/toastError";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},
		btnWrapper: {
			position: "relative",
		},
		buttonProgress: {
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		},
		colorAdorment: {
			width: 20,
			height: 20,
		},
	}
});

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(400, "Muito Longo(a)!")
		.required("Obrigatório"),
	color: Yup.string().min(3, "Muito Curto(a)!").max(10, "Muito Longo(a)!").required(),
});

const TagModal = ({ open, onClose, tagId }) => {

	const { classes } = useStyles();
	const greetingRef = useRef();
	const { user: loggedInUser } = useContext(AuthContext);

	const initialState = { companyId: loggedInUser.companyId, name: "", color: "" };

	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [tag, setTags] = useState(initialState);

	useEffect(() => {
		const fetchUser = async () => {
			if (!tagId) return;
			try {
				const { data } = await api.get(`/tags/${tagId}`);
				setTags(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				ToastError(err);
			}
		};
		fetchUser();
	}, [tagId, open]);

	const handleClose = () => {
		onClose();
		setTags(initialState);
	};

	const handleSaveCompany = async values => {

		let TagsData = { ...values };

		try {
			if (tagId) {
				await api.put(`/tags/${tagId}`, TagsData);
			} else {
				await api.post("/tags", TagsData);
			}
			ToastSuccess('Tag cadastrada com sucesso');
			handleClose();
		} catch (err) {
			console.error(err)
			ToastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<ConfirmationMultiCompaniesModal
				title={i18n.t("messageOptionsMultiCompanies.confirmationModal.title")}
				value={tag}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleSaveCompany}
			>
				{i18n.t("messageOptionsMultiCompanies.confirmationModal.message")}
			</ConfirmationMultiCompaniesModal>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="form-dialog-title">
					{tagId
						? `${i18n.t("tagsList.editTag")}`
						: `${i18n.t("tagsList.addTag")}`}
				</DialogTitle>
				<Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveCompany(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers className={classes.teste}>
								<div className={classes.multFieldLine}>
									<Field
										style={{ display: 'none' }}
										as={TextField}
										type="hidden"
										name="tagId"
									/>
									<Field
										as={TextField}
										label={i18n.t("tagsList.name")}
										type="text"
										name="name"
										inputRef={greetingRef}
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										required
										autoFocus
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.color")}
										name="color"
										id="color"
										fullWidth
										inputRef={greetingRef}
										onFocus={() => {
											greetingRef.current.focus();
										}}
										onClick={() => setColorPickerModalOpen(true)}
										error={touched.color && Boolean(errors.color)}
										helperText={touched.color && errors.color}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<div
														style={{ backgroundColor: tag.color }}
														className={classes.colorAdorment}
													></div>
												</InputAdornment>
											),
											endAdornment: (
												<IconButton
													size="small"
													color="default"
													onClick={() => setColorPickerModalOpen(true)}
												>
													<Colorize />
												</IconButton>
											),
										}}
										variant="outlined"
										margin="dense"
									/>
									<ColorPicker
										style={{ padding: '80px' }}
										id="modal_colorpicker"
										open={colorPickerModalOpen}
										handleClose={() => setColorPickerModalOpen(false)}
										onChange={color => {
											tag.color = color;
											setTags(() => {
												return { ...tag, color };
											});
										}}
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("tagsList.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{tagId
										? `${i18n.t("tagsList.edit")}`
										: `${i18n.t("tagsList.add")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div >
	);
};

export default TagModal;
