import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, TextField } from '@mui/material';
// components
import { BaseOptionChart } from '../../components/chart';

// ----------------------------------------------------------------------

export default function Chart({ chartData }) {
	const [chartTickets, setChartTickets] = useState([]);
	const [colors, setColors] = useState([]);


	useEffect(() => {
		if (chartData) {
			let queueData = [];
			let queueColor = [];

			chartData.forEach(queue => {
				const hours = Object.keys(queue)
					.filter(key => key.startsWith('hour_'))
					.map(key => parseInt(queue[key]));

				// Criar o novo objeto no formato desejado
				const transformedObject = {
					name: queue.queue_name || 'Sem Setor',
					color: queue.queue_color || '#f3f3f3',
					data: hours
				};

				queueColor.push(queue.queue_color);
				queueData.push(transformedObject);
			});

			// Atualizar os estados fora do loop
			setChartTickets(queueData);
			setColors(queueColor);
		}
	}, [chartData]);

	const chartOptions = merge(BaseOptionChart(), {
		colors: colors,
		xaxis: {
			categories: [
				'00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
				'08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
				'16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
			],
		},
	});

	return (
		<Card>
			<CardHeader
				title="Gráfico de atendimento"
				subheader=""
			/>
			<Box sx={{ mt: 3, mx: 3 }} dir="ltr">
				<ReactApexChart type="line" series={chartTickets} options={chartOptions} height={364} />
			</Box>
		</Card>
	);
}
