class MultiTabDetector {
    constructor() {
        this.tabGUID = this.getTabGUID();
        this.registerTabGUID();
        this.isMultiple = false;
    }

    getTabGUID() {
        if (typeof Storage !== "undefined") {
            if (!sessionStorage.tabGUID) {
                sessionStorage.tabGUID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            }
            return sessionStorage.tabGUID;
        } else {
            console.error("Local storage not available");
            return null;
        }
    }

    registerTabGUID() {
        if (typeof Storage !== "undefined") {
            localStorage.setItem('tabGUID', this.tabGUID);
            window.addEventListener("storage", this.storageHandler.bind(this), false);
        } else {
            console.error("Local storage not available");
        }
    }

    storageHandler(e) {
        if (e.key === 'tabGUID') {
            const newIsMultiple = !localStorage.getItem('tabGUID') || localStorage.getItem('tabGUID') !== this.tabGUID;
            if (newIsMultiple !== this.isMultiple) {
                this.isMultiple = newIsMultiple;
                this.onTabChangeCallback(this.isMultiple);
            }
        }
    }

    isMultipleTabs(callback) {
        this.onTabChangeCallback = callback;
        callback(!localStorage.getItem('tabGUID') || localStorage.getItem('tabGUID') !== this.tabGUID);
    }
}

export default MultiTabDetector;
