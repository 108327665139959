import React, { useContext, useEffect, useReducer, useState } from "react";

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { format, parseISO } from "date-fns";
import { makeStyles } from 'tss-react/mui';
import ConfirmationModal from "../../components/ConfirmationModal";
import MassMessagingModal from "../../components/MassMessagingModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
// routes

// hooks
import useSettings from '../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';

import ToastError from "../../toast/error/toastError";
import ToastSuccess from "../../toast/success/toastSuccess";

import socket from "../../hooks/useSocket";


const reducer = (state, action) => {

  if (action.type === "LOAD_MASS_MESSAGING") {
    const massMessagings = action.payload;
    const newMassMessagings = [];

    massMessagings.forEach((massMessaging) => {
      const massMessagingIndex = state.findIndex((q) => q.id === massMessaging.id);
      if (massMessagingIndex !== -1) {
        state[massMessagingIndex] = massMessaging;
      } else {
        newMassMessagings.push(massMessaging);
      }
    });
    return [...state, ...newMassMessagings];
  }

  if (action.type === "UPDATE_MASS_MESSAGING") {
    const massMessaging = action.payload;

    const massMessagingIndex = state.findIndex((q) => q.id === massMessaging.id);

    if (massMessagingIndex !== -1) {
      state[massMessagingIndex] = massMessaging;
      return [...state];
    } else {
      return [massMessaging, ...state];
    }
  }

  if (action.type === "DELETE_MASS_MESSAGING") {
    const massMessagingId = action.payload;

    const massMessagingIndex = state.findIndex((q) => q.id === massMessagingId);
    if (massMessagingIndex !== -1) {
      state.splice(massMessagingIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles()((theme) => {
  return {
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
  }
});


function CircularProgressWithLabel(sent, total) {
  var percentage = (sent * 100) / total;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={percentage} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(percentage)}%`}</Typography>
      </Box>
    </Box >
  );
}

const MassMessaging = () => {
  const { classes } = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [massMessagings, dispatch] = useReducer(reducer, []);
  const [selectedMassMessagings, setSelectedMassMessagings] = useState(null);
  const [massMessagingsModalOpen, setMassMessagingsModalOpen] = useState(false);
  const [deletingMassMessagings, setDeletingMassMessagings] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [status, setStatus] = useState('all');



  const { user } = useContext(AuthContext);
  const companyId = user.companyId;

  // ----------------------------------------------------------------------
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - massMessagings.length) : 0;
  const filteredUsers = applySortFilter(massMessagings, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);
  // ----------------------------------------------------------------------


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, status]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMassMessagings = async () => {
        try {
          const { data } = await api.get("/massMessaging/", {
            params: { searchParam, pageNumber, status },
          });
          dispatch({ type: "LOAD_MASS_MESSAGING", payload: data });
          setLoading(false);
        } catch (err) {
          ToastError(err);
        }
      };
      fetchMassMessagings();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, status]);

  useEffect(() => {
    

    
socket.on("massMessaging", (data) => {
      if (data.action === "update" || data.action === "create" || data.action === 'updateStatus') {
        dispatch({ type: "UPDATE_MASS_MESSAGING", payload: data.massMessaging });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_MASS_MESSAGING",
          payload: +data.massMessagingId,
        });
      }
    });

    return () => {
      socket.off("massMessaging");
    };
  }, []);

  const changeStatus = async (id, status) => {
    try {
      await api.post("/massMessagingStatus/", { id, status });
    } catch (err) {
      ToastError(err);
    }
  }

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenMassMessagingsModal = () => {
    setSelectedMassMessagings(null);
    setMassMessagingsModalOpen(true);
  };


  const handleCloseMassMessagingsModal = () => {
    setSelectedMassMessagings(null);
    setMassMessagingsModalOpen(false);
  };

  const handleEditMassMessagings = (massMessaging) => {
    setSelectedMassMessagings(massMessaging);
    setMassMessagingsModalOpen(true);
  };

  const handleDeleteMassMessagings = async (massMessagingId) => {
    try {
      await api.delete(`/massMessaging/${massMessagingId}`);
      ToastSuccess(i18n.t("massMessagings.toasts.deleted"));
    } catch (err) {
      ToastError(err);
    }
    setDeletingMassMessagings(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const renderProgressStatus = (progress) => {
    var titleTooltip = `${Number(progress.sent)} / ${Number(progress.notSent)}`
    return (<div title={String(titleTooltip)}> {CircularProgressWithLabel(Number(progress.sent), Number(progress.notSent))} </div>);
  }
  const renderPauseButton = (id, status, messagesPaused) => {
    if (status === 'finished' || status === 'waiting') return;
    return (<Tooltip title={i18n.t(`massMessagings.buttons.${!messagesPaused ? 'pause' : 'play'}`)}>
      <IconButton
        size="small"
        onClick={() => changeStatus(id, messagesPaused ? 'started' : 'paused')}
      >
        {!messagesPaused ? <><PauseIcon /></> : <><PlayArrowIcon /></>}
      </IconButton>
    </Tooltip>)
  }
  return (
    <>
      <div style={{ marginTop: '65px' }}>
        <ConfirmationModal
          title={
            deletingMassMessagings &&
            `${i18n.t("massMessagings.confirmationModal.deleteTitle")} ${deletingMassMessagings.shortcut
            }?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteMassMessagings(deletingMassMessagings.id)}
        >
          {i18n.t("massMessagings.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <MassMessagingModal
          open={massMessagingsModalOpen}
          onClose={handleCloseMassMessagingsModal}
          aria-labelledby="form-dialog-title"
          massMessagingId={selectedMassMessagings && selectedMassMessagings.id}
        ></MassMessagingModal>
        <Page title={i18n.t("mainDrawer.listItems.massMessaging")}>
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={i18n.t("mainDrawer.listItems.massMessaging")}
              action={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={handleOpenMassMessagingsModal}
                  >
                    {i18n.t("massMessaging.buttons.add")}
                  </Button>
                </>
              }
            />
            <Card>
              <div style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <TextField
                  style={{ padding: '20px' }}
                  placeholder={i18n.t("massMessagings.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{
                  minWidth: '125px',
                  maxwidth: '150px'
                }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      label="Status"
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value="all">{i18n.t("massMessagings.status.all")}</MenuItem>
                      <MenuItem value="started">{i18n.t("massMessagings.status.started")}</MenuItem>
                      <MenuItem value="paused">{i18n.t("massMessagings.status.paused")}</MenuItem>
                      <MenuItem value="finished">{i18n.t("massMessagings.status.finished")}</MenuItem>
                      <MenuItem value="waiting">{`${i18n.t("massMessagings.status.waiting")}/${i18n.t("massMessagings.status.waitingTime")}`}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.shortcut")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.message")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.date")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.interval")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.period")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.status")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.progress")}
                        </TableCell>
                        <TableCell align="center">
                          {i18n.t("massMessagings.table.actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {massMessagings.length !== 0 && massMessagings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                        const { id, shortcut, message, startIn, endIn, startInterval, endIninterval, period, mediaName, status, messagesPaused, progress } = row;
                        const isItemSelected = selected.indexOf(shortcut) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="center">{shortcut}</TableCell>
                            <TableCell align="center">{
                              message && mediaName ? <>{message} + {mediaName}</>
                                : message ? <>{message}</>
                                  : <>{mediaName}</>
                            }</TableCell>
                            <TableCell align="center">{
                              <>
                                {format(parseISO(startIn), "dd/MM/yyyy")}
                                &nbsp;-&nbsp;
                                {format(parseISO(endIn), "dd/MM/yyyy")}
                              </>
                            }</TableCell>
                            <TableCell align="center">{
                              <>
                                {i18n.t("massMessagings.period.from")}
                                &nbsp;
                                {startInterval}
                                &nbsp;
                                {i18n.t("massMessagings.period.of")}
                                &nbsp;
                                {endIninterval}
                              </>
                            }</TableCell>
                            <TableCell align="center">{
                              period === 'daytime'
                                ? i18n.t("massMessagings.period.daytime")
                                : i18n.t("massMessagings.period.nocturnal")
                            }</TableCell>
                            <TableCell align="center">{i18n.t(`massMessagings.status.${status === undefined ? 'waitingTime' : status}`)}</TableCell>
                            <TableCell align="center">{renderProgressStatus(progress)}</TableCell>
                            <TableCell align="center">
                              {renderPauseButton(id, status, messagesPaused)}
                              {/*<Tooltip title={i18n.t("massMessagings.buttons.edit")}>
                                <IconButton
                                  size="small"
                                  onClick={() => handleEditMassMessagings(row)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>*/}

                              <Tooltip title={i18n.t("massMessagings.buttons.delete")}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setConfirmModalOpen(true);
                                    setDeletingMassMessagings(row);
                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Tooltip>

                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      {loading && <TableRowSkeleton columns={7} />}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination style={{ marginTop: 15 }}
                labelDisplayedRows={({ from, to, count }) => `${i18n.t("userType.translate.result")} ${count} - ${i18n.t("userType.translate.page")} ${from} ${i18n.t("userType.translate.of")} ${to}`}
                labelRowsPerPage={i18n.t("userType.translate.resultsPerPage")}
                rowsPerPageOptions={[5, 20, 40, 60, 80, 100]}
                component="div"
                count={massMessagings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      </div>

    </>
  );
};

export default MassMessaging;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

