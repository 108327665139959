export function getRegionFromDDD(ddd) {
    const brazilRegions = {
        11: { state: "SP", city: "São Paulo e região metropolitana" },
        12: { state: "SP", city: "São José dos Campos e Vale do Paraíba" },
        13: { state: "SP", city: "Baixada Santista" },
        14: { state: "SP", city: "Bauru, Marília e região" },
        15: { state: "SP", city: "Sorocaba e região" },
        16: { state: "SP", city: "Ribeirão Preto e região" },
        17: { state: "SP", city: "São José do Rio Preto e região" },
        18: { state: "SP", city: "Presidente Prudente e região" },
        19: { state: "SP", city: "Campinas e região" },

        21: { state: "RJ", city: "Rio de Janeiro e região metropolitana" },
        22: { state: "RJ", city: "Norte Fluminense" },
        24: { state: "RJ", city: "Sul Fluminense" },

        27: { state: "ES", city: "Região Metropolitana de Vitória" },
        28: { state: "ES", city: "Sul do Espírito Santo" },

        31: { state: "MG", city: "Belo Horizonte e região metropolitana" },
        32: { state: "MG", city: "Zona da Mata" },
        33: { state: "MG", city: "Vale do Rio Doce e Jequitinhonha" },
        34: { state: "MG", city: "Triângulo Mineiro" },
        35: { state: "MG", city: "Sul/Sudoeste de Minas" },
        37: { state: "MG", city: "Oeste de Minas" },
        38: { state: "MG", city: "Norte de Minas" },

        41: { state: "PR", city: "Curitiba e região metropolitana" },
        42: { state: "PR", city: "Ponta Grossa e região" },
        43: { state: "PR", city: "Londrina e região" },
        44: { state: "PR", city: "Maringá e região" },
        45: { state: "PR", city: "Foz do Iguaçu e região" },
        46: { state: "PR", city: "Pato Branco e região" },

        47: { state: "SC", city: "Joinville, Blumenau e região" },
        48: { state: "SC", city: "Florianópolis e região metropolitana" },
        49: { state: "SC", city: "Chapecó, Lages e região" },

        51: { state: "RS", city: "Porto Alegre e região metropolitana" },
        53: { state: "RS", city: "Pelotas e região" },
        54: { state: "RS", city: "Caxias do Sul e região" },
        55: { state: "RS", city: "Santa Maria e região" },

        61: { state: "DF", city: "Brasília e região" },
        62: { state: "GO", city: "Goiânia, Anápolis e região" },
        63: { state: "TO", city: "Tocantins" },

        64: { state: "GO", city: "Sul de Goiás" },
        65: { state: "MT", city: "Cuiabá e região" },
        66: { state: "MT", city: "Rondonópolis e região" },

        67: { state: "MS", city: "Mato Grosso do Sul" },
        68: { state: "AC", city: "Acre" },
        69: { state: "RO", city: "Rondônia" },

        71: { state: "BA", city: "Salvador e região metropolitana" },
        73: { state: "BA", city: "Ilhéus, Itabuna e região" },
        74: { state: "BA", city: "Juazeiro e região" },
        75: { state: "BA", city: "Feira de Santana e região" },
        77: { state: "BA", city: "Vitória da Conquista e região" },
        79: { state: "SE", city: "Sergipe" },

        81: { state: "PE", city: "Recife e região metropolitana" },
        82: { state: "AL", city: "Alagoas" },
        83: { state: "PB", city: "Paraíba" },
        84: { state: "RN", city: "Rio Grande do Norte" },
        85: { state: "CE", city: "Fortaleza e região metropolitana" },
        86: { state: "PI", city: "Teresina e região" },
        87: { state: "PE", city: "Petrolina e região" },
        88: { state: "CE", city: "Juazeiro do Norte e região" },
        89: { state: "PI", city: "Picos e região" },

        91: { state: "PA", city: "Belém e região metropolitana" },
        92: { state: "AM", city: "Manaus e região" },
        93: { state: "PA", city: "Santarém e região" },
        94: { state: "PA", city: "Marabá e região" },
        95: { state: "RR", city: "Roraima" },
        96: { state: "AP", city: "Amapá" },
        97: { state: "AM", city: "Coari e região" },
        98: { state: "MA", city: "São Luís e região metropolitana" },
        99: { state: "MA", city: "Imperatriz e região" }
    };

    if (brazilRegions[ddd]) {
        return {
            country: "Brasil",
            state: brazilRegions[ddd].state,
            city: brazilRegions[ddd].city
        };
    } else {
        return null;
    }
}

export function identifyLocationNumber(number) {

    if (!number) return;

    if (typeof number !== "string") {
        number = number.toString();
    }

    if (!number.startsWith('55')) {
        return null
    }

    const ddd = number.slice(2, 4);
    return getRegionFromDDD(ddd);
}

export function formatBrazilianNumber(number) {

    if (number) {
        const numStr = number.toString();

        const localNumber = numStr.substring(2);
        if (localNumber.length === 10) {
            return `+55 (${localNumber.substring(0, 2)}) ${localNumber.substring(2, 6)}-${localNumber.substring(6)}`;
        } else if (localNumber.length === 11) {
            return `+55 (${localNumber.substring(0, 2)}) ${localNumber.substring(2, 7)}-${localNumber.substring(7)}`;
        } else {
            return 'Grupo do Whatsapp'
        }
    } else {
        return 'Grupo do Whatsapp'
    }

}