import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
} from "@mui/material";
import { VolumeUp } from "@mui/icons-material";
import { Check } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";

import sound from "../../assets/sound.mp3";
import alertBetacut from "../../assets/alert-betacut.mp3";
import gtaSound from "../../assets/gta-sound.mp3";
import bell from "../../assets/bell.mp3";
import livechat from "../../assets/livechat.mp3";
import phoneRing from "../../assets/phone-ring.mp3";

const audioFiles = [
	{ id: 'sound', name: "Padrão", url: sound },
	{ id: 'alertBetacut', name: "Alerta", url: alertBetacut },
	{ id: 'gtaSound', name: "GTA", url: gtaSound },
	{ id: 'bell', name: "Sino", url: bell },
	{ id: 'livechat', name: "Chat", url: livechat },
	{ id: 'phoneRing', name: "Telefone", url: phoneRing },
];

const ChangeNotificationsModal = ({ open, onClose, onConfirm, globalNotification, userNotification }) => {
	const [selectedAudioGlobal, setSelectedAudioGlobal] = useState(globalNotification);
	const [selectedAudioMy, setSelectedAudioMy] = useState(userNotification);

	useEffect(() => {

		setSelectedAudioGlobal(globalNotification);
		setSelectedAudioMy(userNotification);

	}, [open, globalNotification, userNotification]);


	const handleAudioChangeGlobal = (audio) => {
		setSelectedAudioGlobal(audio.id);
	};

	const handleAudioChangeMy = (audio) => {
		setSelectedAudioMy(audio.id);
	};

	const handlePlayAudio = (audioUrl) => {
		const audio = new Audio(audioUrl);
		audio.play();
	};

	const handleConfirm = () => {
		onConfirm({ selectedAudioGlobal, selectedAudioMy });
		setSelectedAudioGlobal(null); // Reset selected audio after confirming
		setSelectedAudioMy(null);
	};

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
			BackdropProps={{
				style: {
					backdropFilter: 'blur(3px)',
					backgroundColor: 'rgba(0, 0, 30, 0.4)',
				},
			}}
		>
			<DialogTitle>
				<Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
					<Typography variant="h5">Sons de Notificações</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box sx={{ width: '400px' }}>
					<Paper elevation={0} sx={{ margin: '5px', backgroundColor: (theme) => theme.palette.mode === "dark" ? '#171d26' : '#FFFFFF' }}>
						<Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
							<Typography variant="h5">Atribuidos a mim</Typography>
						</Box>
						<Divider />
						<List>
							{audioFiles.map((audio, index) => (
								<ListItem
									key={index}
									button
									onClick={() => handleAudioChangeMy(audio)}
									selected={audio.id === selectedAudioMy}
								>
									<ListItemText primary={audio.name} />
									{audio.id === selectedAudioMy && (
										<ListItemIcon>
											<Check color="primary" />
										</ListItemIcon>
									)}
									<ListItemIcon>
										<VolumeUp onClick={() => handlePlayAudio(audio.url)} />
									</ListItemIcon>
								</ListItem>
							))}
						</List>
					</Paper>
					<Paper elevation={0} sx={{ margin: '5px', backgroundColor: (theme) => theme.palette.mode === "dark" ? '#171d26' : '#FFFFFF' }}>
						<Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
							<Typography variant="h5">Sem Atendimento</Typography>
						</Box>
						<Divider />
						<List>
							{audioFiles.map((audio, index) => (
								<ListItem
									key={index}
									button
									onClick={() => handleAudioChangeGlobal(audio)}
									selected={audio.id === selectedAudioGlobal}
								>
									<ListItemText primary={audio.name} />
									{audio.id === selectedAudioGlobal && (
										<ListItemIcon>
											<Check color="primary" />
										</ListItemIcon>
									)}
									<ListItemIcon>
										<VolumeUp onClick={() => handlePlayAudio(audio.url)} />
									</ListItemIcon>
								</ListItem>
							))}
						</List>
					</Paper>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose(false)} color="primary">
					Cancelar
				</Button>
				<Button onClick={handleConfirm} color="primary" disabled={!selectedAudioGlobal && !selectedAudioMy}>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ChangeNotificationsModal;
