import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from 'tss-react/mui';
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import { i18n } from "../../translate/i18n";
import ConfirmationMultiCompaniesModal from "../ConfirmationMultiCompaniesModal";
import EditCompanyUsersModal from "../EditCompanyUsersModal"
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ToastError from "../../toast/error/toastError";


const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		multFieldLine: {
			display: "flex",
			"& > *:not(:last-child)": {
				marginRight: theme.spacing(1),
			},
		},
		btnWrapper: {
			position: "relative",
		},
		buttonProgress: {
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
		}
	}
});


const CompanyModal = ({ open, onClose, companyId }) => {

	const { classes } = useStyles();

	const [users, setUsers] = useState([]);

	const [openEditUserModal, setOpenEditUserModal] = useState(false);
	const [userIdToEdit, setUserIdToEdit] = useState(null);

	useEffect(() => {
		const fetchUser = async () => {
			if (!companyId) return;
			try {
				const { data } = await api.get(`/company-users/${companyId}`);
				setUsers(data)
			} catch (err) {
				ToastError(err);
			}
		};

		fetchUser();
	}, [companyId, open]);

	const handleClose = () => {
		onClose();
	};

	const handleEditCompanyUsers = (id) => {
		setUserIdToEdit(id);
		setOpenEditUserModal(true);
	};

	const handleCloseCompanyUsersModal = () => {
		setUserIdToEdit(null);
		setOpenEditUserModal(false);
	};

	return (
		<div className={classes.root}>
			<EditCompanyUsersModal
				open={openEditUserModal}
				onClose={handleCloseCompanyUsersModal}
				aria-labelledby="form-dialog-title"
				userId={userIdToEdit}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="body"
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="form-dialog-title">{'Editar usuário da empresa'}</DialogTitle>
				<Box sx={{ flexGrow: 1, maxWidth: '100%' }}>
					<Grid item xs={12} md={12}>
						<List sx={{ maxHeight: '500px', overflow: 'auto', padding: '20px' }}>
							{users.map((user) => (
								<ListItem
									secondaryAction={
										<IconButton edge="end" onClick={e => handleEditCompanyUsers(user.id)} aria-label="delete">
											<EditIcon />
										</IconButton>
									}
								>
									<ListItemAvatar>
										<Avatar src={user?.media} alt={user?.name} />
									</ListItemAvatar>
									<ListItemText
										primary={`${user?.name} ( ${user?.profile} )`}
										secondary={user?.email}
									/>
								</ListItem>
							))}
						</List>
					</Grid>
				</Box>
			</Dialog>
		</div>
	);
};

export default CompanyModal;
