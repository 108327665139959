const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      recovery: {
        title: "Recuperar Conta",
        formTitle: "Recuperar senha UniChat",
        details: "Insira seu email para receber o código de acesso.",
        details2: "Preencha os dois campos pasa alterar a senha.",
        passwordDetails: "insira sua nova senha para prosseguir.",
        emailSend: "Você receberá um email com o código de recuperação.",
        form: {
          email: "Email",
          password: "Senha",
          code: "Código de recuperação",
          rePassword: "Repetir senha",
        },
        buttons: {
          submit: "Recuperar",
          newCode: "Gerar novo código",
          next: "Continuar para recuperação",
          change: "Trocar senha",
        },
      },
      login: {
        title: "Login",
        formTitle: "Entrar no UniChat",
        welcome: "Seja bem vindo!",
        details: "Insira seus dados abaixo.",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
          recovery: "Esqueceu sua senha?",
          login: "Fazer login",
          notUser: "Não tem conta?",
          demo: "Peça um teste Demo!",
        },
      },
      weekDays: {
        title: "Dias",
        sunday: "Domingo",
        monday: "Segunda",
        tuesday: "Terça",
        wednesday: "Quarta",
        thursday: "Quinta",
        friday: "Sexta",
        saturday: "Sabado",
        avoidHoliday: "Apenas dia útil",
      },

      salutation: {
        goodmorning: "Bom dia",
        goodafternoon: "Boa tarde",
        goodnight: "Boa noite",
        gooddawn: "Boa madrugada"
      },
      companyList: {
        cancel: "Cancelar",
        edit: "Editar",
        add: "Cadastrar",
        emailDefault: "Email Padrão",
        maxWhatsapp: "N° Whatsapps",
        maxUsers: "N° Usuarios",
        cnpj: "CNPJ",
        company: "Empresa",
        editCompany: "Editar Empresa",
        addCompany: "Cadastrar Empresa"
      },
      tagsList: {
        cancel: "Cancelar",
        edit: "Editar",
        add: "Cadastrar",
        name: "Nome",
        color: "Cor",
        editTag: "Editar Tag",
        addTag: "Cadastrar Tag"
      },
      userType: {
        translate: {
          nameWhatsapp: "Nome no Whatsapp",
          infoReload: "A página ira recarregar sempre que editar o próprio usuário.",
          user: "Atendente",
          supervisor: "Supervisor",
          admin: "Administrador",
          superUser: "Super Usuário",
          sendPhoto: "Enviar foto",
          company: "Empresa",
          empty: "Vazio",
          searchUsers: "Buscar Usuários",
          searchContacts: "Buscar Contatos",
          name: "Nome",
          number: "Número",
          email: "Email",
          type: "Tipo",
          action: "Ações",
          result: "Resultados",
          page: "De",
          of: "até",
          notifications: 'Notificações',
          resultsPerPage: "Resultados por Página",
          selectQueue: "Selecione um setor",
          send: "Enviar",
          errorPermissionNotifications: "Você não tem permissões de notificações!",
          newMessageUser: "Nova mensagem de",
          modifyDefinitionsBot: "Utilizar setores em listas?",
          changeSuccess: "Você alterou para ",
          listSuccess: "listas",
          defaultSuccess: "Padrão",
          openWppWeb: "Abrir no Whatsapp Web",
          openCall: "Ligar para o numero",
          config: "Configuraçoes",
          theme: "Tema",
          colors: "Cores",
          notNotifications: "Notificações do navegador desativadas!",
          notUsers: "Adicione usuários para ter acesso ao chat interno.",
          sayHello: "Digite uma mensagem para iniciar a conversa!",
          you: "Você",
          notQuickAnswers: "Sem mensagens rápidas.",
          hello: "Olá",
          needHelp: "Precisa de Ajuda?",
          finish: "Finalizar",
          finishTicket: "Finalizar Ticket",
          wasResolved: "Foi Resolvido?",
          reason: "Motivo do contato",
          yes: "Sim",
          no: "Não",
          closed: "Concluido (Sem nota)",
          photo: "Foto",
          video: "Video",
          audio: "Áudio",
          file: 'Arquivo'
        }
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        tickets: {
          ticketsRealTime: "Relatório - ",
          ticketsOpen: "Atendendo",
          ticketsPending: "Aguardando",
          ticketsClosed: "Fechados",
          ticketsTotal: "Total",
        },
        charts: {
          perDay: {
            title: "Tickets",
          },
        },
      },
      companies: {
        pages: {
          companies: 'Empresas',
          overview: {
            name: 'Visão geral',
            companies: 'Empresas',
            users: 'Usuários',
            connections: 'Conexões',
            ram: {
              usage: 'Uso de RAM',
              usageTotal: 'Utilizada'
            },
            system: {
              systemSpec: 'Especificações do servidor',
              storage: 'Armazenamento',
              so: 'Sistema Operacional'
            }
          },
          report: 'Relatório',
        },
        buttons: {
          add: 'Adicionar empresa',
        },
        deleteInfo: 'Ao deletar empresa, você perderá todos os dados cadastrados e historico desta empresa e seus usuários.',
        list: {
          companies: 'Empresas',
          overview: 'Visão geral',
          systemReport: 'Relatorio de uso'
        },
        table: {
          name: 'Nome',
          cnpj: 'Cnpj',
          connections: 'N° Conexões',
          users: 'N° Usuários',
          status: 'Status',
          action: 'Ações'
        },
        confirmations: {
          delete: 'Deseja realmente deletar a empresa'
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão excluída com sucesso!",
          copied: "Token copiado!"
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar Conexão",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          channel: 'Canal',
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          autoClose: "Encerramento automático",
          autoCloseTimeOut: "Tempo limite",
          autoCloseMessage: "Mensagem ao encerrar",
          actions: "Ações",
          session: "Sessão",
        },
        channel: {
          name: 'Canal de integração',
          whatsapp: 'Whatsapp',
          telegram: 'Telegram',
          instagram: 'Instagram',
          messenger: 'Messenger',
          webchat: 'WebChat',
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar Conexão",
          edit: "Editar Conexão",
        },
        autoClose: 'Finalizar ticket por falta de interação',
        useTyping: 'Usar "Digitando" em mensagem',
        insistAnswering: 'Reenviar opção de setores',
        autoCloseTime: 'Tempo limite',
        autoCloseMessage: 'Mensagem ao encerrar tempo limite',
        channel: {
          name: 'Canal de integração',
          whatsapp: 'Whatsapp',
          telegram: 'Telegram',
          instagram: 'Instagram',
          messenger: 'Messenger',
          webchat: 'WebChat',
        },
        form: {
          name: "Nome",
          default: "Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Conexão salva com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos (Beta)",
          add: "Adicionar Contato",
          block: "Bloquear/Desbloquear Contato",
          filterBlocked: "Filtrar Bloqueados",
          removeFilter: "Remover Filtro",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          contract: "Número Contrato",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      ChatbotModal: {
        title: {
          add: "Criar chatbot",
          edit: "Editar nome",
        },
        form: {
          name: "Nome",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Chatbot criado com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar setor",
          edit: "Editar setor",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          closedMessage: "Mensagem de ausência",
          farewellMessage: "Mensagem de despedida",
          messageAccepting: "Mensagem ao aceitar",
          description: "Descrição",
          token: "Token",
          tabs: {
            information: "Informações",
            schedules: "Horários",
          },
          schedules: {
            monday: "Segunda-Feira",
            tuesday: "Terça-Feira",
            wednesday: "Quarta-Feira",
            thursday: "Quinta-Feira",
            friday: "Sexta-Feira",
            saturday: "Sabado",
            sunday: "Domingo",
            interval: "Intervalo",
            useInterval: "Usar Intervalo",
          }
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          options: "Marcadores",
        },
        markups: {
          queue: "Setor",
          userName: "Nome do usuário",
        },
        chatbot: {
          use: 'Utilizar chatbot para este setor',
          select: 'Selecione um chatbot para prosseguir'
        }
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          oldPassword: 'Senha antiga',
          password: "Senha",
          newPassword: "Nova senha",
          confirmPassword: "Confirmar senha",
          changePassword: 'Alterar senha',
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione uma conversa para iniciar um atendimento.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Setores",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
          contactBody: "Contato:",
        },
        tabs: {
          open: {
            title: "Caixa de entrada",
            open: "Atendendo",
            pending: "Aguardando",
          },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar por Nome ou Número",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabelSelect: "Selecione um setor primeiro *",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para setor",
        fieldQueuePlaceholder: "Selecione um setor",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        newNumber: "Novo número de telefone",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        initAdd: "Chamar contato",
        searchContact: "Buscar salvo",
        newContact: "Conversa direta",
        info: {
          searchContactInfo: "Busque contato salvo na sua base de dados.",
          newContactInfo: "Inicie contato diretamente com o numero sem adicioná-lo.",
        },
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atendimento",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Setores",
          administration: "Painel Administrativo",
          admin: "Painel",
          users: "Usuários",
          settings: "Configurações",
          company: "Empresas",
          folder: "Arquivos",
          report: "Relatório geral",
          keywords: "Palavras chave",
          massMessaging: "Disparo em massa",
          messagesAPI: "API",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      messagesAPI: {
        title: 'API',
        textMessage: {
          number: 'Número',
          body: 'Mensagem',
          token: 'Token cadastrado',
        },
        mediaMessage: {
          number: 'Número',
          body: 'Nome do arquivo',
          media: 'Arquivo',
          token: 'Token cadastrado',
        }
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        type: {
          chatbot: 'ChatBot',
          list: 'Listagem comum'
        },
        title: "Setores",
        table: {
          name: "Nome",
          color: "Cor",
          type: "Tipo",
          greeting: "Mensagem de saudação",
          messageAccepting: "Mensagem ao aceitar",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar setor",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets desse setor continuarão existindo, mas não terão mais nenhum setor atribuída.",
        },
      },
      tags: {
        title: "Tags",
        table: {
          name: "Nome",
          color: "Cor",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar tag",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os atendimentos com esta tag voltarão a ficar sem tags.",
        },
      },
      files: {
        title: "Arquivos",
        buttons: {
          selectAll: "Selecionar todos",
          removeSelectAll: "Remover seleção",
          clear: "Limpar arquivos",
          delete: "Excluir selecionados",
          deleteAll: "Excluir tudo",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os arquivos excluidos não podem ser recuperados.",
        },
      },
      queueSelect: {
        inputLabel: "Setores",
      },
      massMessaging: {
        title: "Disparo em massa",
        table: {
          shortcut: "Titulo do disparo",
          message: "Mensagem a ser enviada",
          actions: "Ações",
          interval: "Intervalo entre os disparos (Segundos)",
          intervalMessages: "Mensagens por disparo"
        },
        actions: {
          startIn: 'Data Inicial',
          endIn: 'Data Final',
          intervalStart: 'Das',
          intervalEnd: 'Ás',
        },
        contactsArray: "Contatos (Sistema)",
        contacts: "Contatos (Arquivo)",
        file: "Arquivo",
        edit: "Editar Disparo",
        add: "Criar Disparo",
        buttons: {
          add: "Criar Disparo",
          example: "Arquivo para contatos",
        },
        total: "Total: ",
        success: "Validos: ",
        error: "Com Erro: ",
        days: "Estimativa: ",
        duplicated: "Duplicados: ",
        selectAll: "Selecionar Todos"
      },
      massMessagings: {
        table: {
          shortcut: "Nome",
          message: "Mensagem",
          date: "Data de inicio e fim",
          interval: "Intervalo",
          period: "Periodo",
          daysOfWeek: "Dias da Semana",
          status: "Status",
          progress: "Progresso",
          actions: "Ações",
        },
        wpp: {
          name: 'Conexão de disparo',
          connected: 'Conectado',
          disconnect: 'Desconectado'
        },
        buttons: {
          play: 'Iniciar',
          pause: 'Pausar',
          edit: 'Editar',
          delete: 'Deletar',
        },
        period: {
          from: "Das",
          of: "às",
          daytime: "Diurno",
          nocturnal: "Noturno"
        },
        status: {
          all: 'Todos',
          waiting: "Aguardando",
          started: "Iniciado",
          finished: "Finalizado",
          waitingTime: "Aguardando horário",
          paused: "Pausado",
        },
        toasts: {
          deleted: "Disparo excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir o disparo de mensagens: ",
          deleteMessage: "Esta ação não pode ser revertida e você perderá o progresso do disparo caso já tenha sido iniciado.",
        },
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      keywords: {
        title: "Palavras chaves",
        placeholder: 'Digite e pressione "ENTER"'
      },
      chatbot: {
        title: "Chatbot",
        table: {
          name: "nome",
          updatedAt: "Ultima atualização",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar chatbot",
        },
        toasts: {
          deleted: "Chatbot excluído com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir o chatbot: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        editFlow: {
          loading: 'Carregando fluxo...',
          save: 'Salvando fluxo...',
          type: {
            saveResponse: 'Salvar resposta',
            conditional: 'Condicional',
            finished: 'Fim'
          },
          step: {
            start: 'Inicio do fluxo.',
            save: 'Salvar resposta.',
            conditional: 'Defina uma pergunta ou continuações.',
            endToQueue: 'Finaliza o fluxo transferindo para o setor.',
            end: 'Finaliza o fluxo encerrando o atendimento.'
          },
          options: {
            edit: 'Editar card.',
            delete: 'Deletar card.',
          },
          buttons: {
            save: "Salvar fluxo",
            cancel: "Cancelar edição",
            addFinish: "Adicionar finalização",
            addConditional: "Adicionar condicional",
          },
        },
        menu: {
          options: {
            end: 'Finalização',
            conditional: 'Condicional',
            finalizeService: 'Finalizar ticket',
            saveResponse: 'Salvar resposta',
            transferTo: 'Transferir para',
            responseAfterCapture: 'Resposta após captura',
            endFlow: 'Finalizar fluxo',
            message: 'Mensagem',
            title: 'Título',

          },
          buttons: {
            save: 'Salvar',
            cancel: 'Cancelar'
          }
        }
      },
      reportTickets: {
        title: "Relatório de Tickets",
        table: {
          name: "Nome do contato",
          number: "Número do contato",
          createdOn: "Data de contato",
          updatedOn: "Última atualização",
          status: "Status atual",
          userAtt: "Usuário atribuido",
          notUserAtt: "Sem usuário atribuido",
          actions: "Ações",
        },
        buttons: {
          export: "Exportar todos em Excel",
          filter: "Filtrar",
          viewTicketMsg: "Visualizar conversa deste ticket",
        },
        status: {
          open: 'Aberto',
          pending: 'Pendente',
          closed: 'Fechado',
          all: 'Todos'
        },
        excel: {
          error: 'Não há tickets para gerar um relatório.',
          archive: 'Relatório',
          name: 'Nome',
          number: 'Número',
          createdAt: 'Data do contato',
          updatedAt: 'Data da última interação',
          status: 'Status',
          protocol: 'Protocolo',
          assignedUser: 'Usuário atribuído',
          queue: 'Setor',
          rating: 'Avaliação do atendimento',
          notRating: 'Concluido sem avaliação'
        },
        searchPlaceholder: "Pesquisar...",
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para o setor.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          typeQueue: {
            name: "Listagem de Setores",
            options: {
              default: "Padrão",
              list: "Lista",
              buttons: "Botões",
            },
            optionsInfo: {
              default: "Menu padrão",
              list: "Menu em lista",
              buttons: "Menu em botões",
            }
          },
          answerInGroups: {
            name: "Responder em grupos",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          serviceRating: {
            name: "Notas de atendimento",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
            obs: "Ao ativar, será enviada uma mensagem para o cliente classificar a nota do serviço prestado."
          },
          attendantViewNoQueue: {
            name: "Exibir atendimentos sem fila",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          messageRating: {
            name: "Notas de atendimento (Mensagem)",
            obs: `Esta mensagem será exibida após concluir o atendimento. A função dela é pedir a nota de atendimento pelo usuário.`
          },
          ticketTime: {
            name: "Tempo minimo para criar novo ticket",
            obs: `Este campo serve para impedir que um cliente que entrou em contato rescentemente gere um novo ticket ao enviar uma mensagem de despedida (A mensagem será adicionada ao ticket anterior e ele voltará para ATENDENDO).`,
            time: "Tempo em horas"
          },
          periodTime: {
            title: 'Horários dos turnos',
            start: 'Inicio',
            end: 'Fim'
          },
          keywords: {
            name: "Usar palavras chave após finalização",
            obs: "Este campo serve para identificar palavras após a finalização do ticket, evitando a abertura de novos chamados e respondendo automaticamente os clientes.",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          ticketsOrder: {
            name: "Ordenação dos Tickets",
            options: {
              default: "Novas mensagens primeiro (Padrão)",
              desc: "Tickets mais novos primeiro",
              asc: "Tickets mais antigos primeiro",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            delete: "Excluir atendimento",
            transfer: "Trasferir atendimento",
            presence: "Tornar presencial",
            notpresence: "Tornar não presencial",
            resolve: "Resolver",
            archiveMsg: "Arquivar",
            markupResolved: 'Resolver sem despedida',
            removeArchiveMsg: "Mover para Atendendo",
            reopen: "Reabrir",
            accept: "Aceitar",
            archive: "Arquivar",
            moveArchive: "Resolver Novamente",
          },
          buttonsInfo: {
            return: "Retorne o atendimento para a fila de espera.",
            delete: "Exclua o atendimento permanentemente, removendo todos os seus registros associados do banco de dados.",
            transfer: "Transfira o atendimento para outro atendente ou para um setor diferente.",
            presence: "Torne o atendimento presencial, indicando uma possível visita técnica ou reunião.",
            notpresence: "Indique que o atendimento não é presencial, ou seja, é virtual ou por telefone.",
            resolve: "Indique que o problema ou consulta do cliente foi tratado e resolvido com sucesso.",
            archiveMsg: "Arquive a mensagem, removendo-a da visualização principal mas mantendo-a no histórico.",
            markupResolved: "Finaliza o atendimento sem pedir nota ou enviar mensagem de despedida.",
            removeArchiveMsg: "Mova o atendimento do arquivo de volta para o status 'Em Atendimento'.",
            reopen: "Reabra um atendimento anteriormente fechado ou resolvido.",
            accept: "Aceite um atendimento que estava aguardando na fila.",
            archive: "Arquive o atendimento, removendo-o da visualização principal, mas mantendo-o no histórico para futura referência.",
            moveArchive: "Marque o atendimento como resolvido novamente, após tê-lo reaberto anteriormente."
          },
        },
        others: {
          editedMessage: "Editada"
        }
      },
      messagesInput: {
        placeholderOpen: "Mensagem ou / para respostas",
        placeholderOpenModile: "Mensagem ou / respostas",
        placeholderNotepad: "Escreva sua nota...",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
          block: "Bloquear Contato",
          unblock: "Desbloquear Contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        createUnidesk: "Criar Chamado",
        confirmationModal: {
          title: "Deletar atendimento #",
          titleFrom: "do contato ",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        confirmationModalForce: {
          titleUser: "Você pode ter sido bloqueado por este contato",
          titleGroup: "Você pode ter sido banido ou o grupo foi excluido",
          message:
            "Deseja encerrar o atendimento mesmo assim?",
        },
        buttonsForce: {
          delete: "Finalizar",
          cancel: "Cancelar",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      vcard: {
        title: 'Enviar contatos para o chat',
        searchContacts: 'Pesquisar contatos',
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          send: 'Enviar',
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        edit: "Editar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      messageOptionsMultiCompanies: {
        confirm: "Sim",
        cancel: "Cancelar",
        confirmationModal: {
          title: "Este email já esta sendo utilizado por outro usuário",
          message: "Deseja criar um usuário multi-empresas?",
        },
      },
      multiCompanies: {
        company: "Mudar empresa",
        changeCompany: "Trocando de empresa, aguarde..."
      },
      internalChat: {
        selectChat: "Selecione um usuário  para conversar.",
        emojiPicker: "Emoji",
        messageType: "Mensagem",
        searchUser: "Buscar usuário"
      },

      backendErrors: {
        ERR_USER_DISABLED:
          "Este usuário não tem acesso ao sistema.",
        ERR_COMPANY_DISABLED:
          "A empresa esta bloqueada pelo sistema, entre em contato com o suporte para mais informações.",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos uma conexão padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhuma conexão padrão encontrada. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta conexão não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_WAPP_MAX_REACHED:
          "Você atingiu o número máximos de conexões permitidas em seu plano. Entre em contato com o suporte para saber mais",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um ticket aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre novamente.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_USER_MAX_REACHED:
          "Você atingiu o número máximos de usuários permitidos em seu plano. Entre em contato com o suporte para saber mais.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_SCHEDULE_FOUND: "Nenhum horário com este ID",
        ERR_NO_TICKET_FOUND: "Nenhum ticket encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_TICKETS_IN_USER_FOUND: "Este usuário tem atendimentos vinculados e não pode ser excluido.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar ticket no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de um setor.",
        ERR_CONN_TOKEN_EXIST:
          "Já existe uma conexão com este token.",
        ERR_CONN_NAME_EXIST:
          "Já existe uma conexão com este nome.",
        ERR_SENDING_WAPP_MSG_GROUP_FORBIDDEN:
          "Erro ao enviar mensagem para o grupo",
        ERR_SENDING_WAPP_MSG_USER_FORBIDDEN:
          "Erro ao enviar mensagem para o contato",
      },
    },
  },
};

export { messages };
