import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";

import { makeStyles } from 'tss-react/mui';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";

import { AuthContext } from "../../context/Auth/AuthContext";
import ToastSuccess from "../../toast/success/toastSuccess";
import ToastError from "../../toast/error/toastError";
import { Grid } from "@mui/material";

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginRight: theme.spacing(1),
			flex: 1,
		},

		extraAttr: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},

		btnWrapper: {
			position: "relative",
		},

		buttonProgress: {

			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
	}
});

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito Curto(a)!")
		.max(50, "Muito Longo(a)!")
		.required("Obrigatório"),
	number: Yup.string().min(8, "Muito Curto(a)!").max(50, "Muito Longo(a)!"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
	const { classes } = useStyles();
	const isMounted = useRef(true);

	const { user: loggedInUser } = useContext(AuthContext);

	const initialState = {
		companyId: loggedInUser.companyId,
		name: "",
		number: "",
		contract: "",
		email: "",
	};

	const [contact, setContact] = useState(initialState);

	useEffect(() => {
		return () => isMounted.current = false;
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact(data);
				}
			} catch (err) {
				ToastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
				handleClose();
			} else {
				const { data } = await api.post("/contacts", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			};

			ToastSuccess(i18n.t("contactModal.success"));

		} catch (err) {
			ToastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="body"
				maxWidth="sm"
				fullWidth
				BackdropProps={{
					style: {
						backdropFilter: 'blur(3px)',
						backgroundColor: 'rgba(0, 0, 30, 0.4)',
					},
				}}
			>
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<Field
								style={{ display: 'none' }}
								as={TextField}
								type="hidden"
								name="companyId"
							/>
							<DialogContent dividers>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Field
											size="small"
											as={TextField}
											label={i18n.t("contactModal.form.name")}
											name="name"
											fullWidth
											autoFocus
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											size="small"
											as={TextField}
											label={i18n.t("contactModal.form.number")}
											name="number"
											fullWidth
											autoFocus
											error={touched.number && Boolean(errors.number)}
											helperText={touched.number && errors.number}
											variant="outlined"
											margin="dense"
										/>
									</Grid>
									<Grid item xs={12} sm={+loggedInUser?.companyId == 2 ? 8 : 12}>
										<Field
											size="small"
											as={TextField}
											label={i18n.t("contactModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											placeholder="Email address"
											fullWidth
											margin="dense"
											variant="outlined"
										/>
									</Grid>
									{+loggedInUser?.companyId == 2 && (
										<Grid item xs={12} sm={4}>
											<Field
												size="small"
												as={TextField}
												label={i18n.t("contactModal.form.contract")}
												name="contract"
												placeholder="Número do contrato"
												fullWidth
												margin="dense"
												variant="outlined"
											/>
										</Grid>
									)}

								</Grid>
								<Typography
									style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.extraInfo")}
								</Typography>

								<FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Field
															size="small"
															as={TextField}
															label={i18n.t("contactModal.form.extraName")}
															name={`extraInfo[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<Field
															size="small"
															as={TextField}
															label={i18n.t("contactModal.form.extraValue")}
															name={`extraInfo[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											<div className={classes.extraAttr}>
												<Button
													style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="primary"
													onClick={() => push({ name: "", value: "" })}
												>
													{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
